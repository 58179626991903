import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Wallet.css";
import { connect } from "react-redux";
import {
  fetchMoreUserWalletDetailsStart,
  fetchUserWalletDetailsStart,
} from "../store/actions/WalletAction";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import NoDataFound from "../Helper/NoDataFound";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const WalletIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchUserWalletDetailsStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreWalletPayments = () => {
    props.dispatch(
      fetchMoreUserWalletDetailsStart({
        skip: props.userWalletDetails.data.user_wallet_payments.length,
        take: 12,
      })
    );
  };

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Transacction Id Copied"));
  };

  return (
    <>
      <div className="dashboard-sec">
        <DomainSearchSec />
        <div className="dashboard-body-sec">
          <div className="main-wrapper-box">
            <div className="mobile-sidebar-sec">
              <DashboardSidebar />
            </div>
            <div className="main-wrapper-right">
              <div className="title-sec">
                <h2>Wallet</h2>
                <p>
                  Good to See you here
                  <Image
                    className="wave-hand-icon"
                    src={
                      window.location.origin +
                      "/images/dashboard/waving-hand.png"
                    }
                  />
                </p>
              </div>
              <div className="wallet-balance-box">
                <div className="account-balance-details">
                  <h4>
                    {!props.userWalletDetails.loading &&
                    props.userWalletDetails.data.user_wallet
                      ? props.userWalletDetails.data.user_wallet
                          .remaining_formatted
                      : "0.00 USD"}
                  </h4>
                  <p>Current wallet balance</p>
                </div>
                <div className="wallet-balance-btn">
                  <Link to="/add-money" className="wallet-btn">
                    <Image
                      src={window.location.origin + "/images/wallet/wallet.png"}
                    />
                    Add Money
                  </Link>
                  {/* <Link to="/withdraw-money" className="wallet-btn">
                    <Image
                      src={
                        window.location.origin + "/images/wallet/withdraw.png"
                      }
                    />
                    Withdraw
                  </Link> */}
                </div>
              </div>
              {/* {props.userWalletDetails.loading ? (
                'Loading'
              ) :
                props.userWalletDetails.data.user_wallet_payments &&
                  props.userWalletDetails.data.user_wallet_payments.length > 0 ?
                  props.userWalletDetails.data.user_wallet_payments.map((wallet_payment) => {
                    <> */}
              <div className="wallet-transaction-sec">
                <div className="title-sec">
                  <h2>Wallet History</h2>
                </div>
                <div className="your-active-auction-box">
                  <div className="your-active-auction-table-sec">
                    {props.userWalletDetails.loading ? (
                      <CommonCenterLoader />
                    ) : props.userWalletDetails.data.user_wallet_payments &&
                      props.userWalletDetails.data.user_wallet_payments.length >
                        0 ? (
                      // <div className="custom-table-list">
                      //   <div className="custom-table-header">
                      //     <div className="custom-table-header-card">
                      //       <h5>Date</h5>
                      //       <h5>Trasaction ID</h5>
                      //       <h5>Amount</h5>
                      //       {/* <h5>Deposit</h5> */}
                      //       <h5>Status</h5>
                      //     </div>
                      //   </div>
                      //   <InfiniteScroll
                      //     dataLength={props.userWalletDetails.data.user_wallet_payments.length}
                      //     next={fetchMoreWalletPayments}
                      //     hasMore={props.userWalletDetails.data.user_wallet_payments.length < props.userWalletDetails.data.total}
                      //     loader={<CommonCenterLoader />}
                      //   >
                      //     <div className="custom-table-body">
                      //       {props.userWalletDetails.data.user_wallet_payments.map((payment, i) =>
                      //         <div className="custom-table-card" key={i}>
                      //           <p>{payment.paid_date_formatted}</p>
                      //           <p>
                      //             {payment.payment_id ?
                      //               <OverlayTrigger placement="top" overlay={<Tooltip>{payment.payment_id}</Tooltip>}>
                      //                 <div className="transaction-id-sec" >
                      //                   <CopyToClipboard text={payment.payment_id}
                      //                     onCopy={() => onCopy()}>
                      //                     <span>{payment.payment_id}</span>
                      //                   </CopyToClipboard>
                      //                 </div>
                      //               </OverlayTrigger>
                      //               : "-"}
                      //           </p>
                      //           <p>
                      //             {payment.amount_type === "add" ? "+ " : "- "}
                      //             {payment.requested_amount_formatted}
                      //           </p>
                      //           {/* <p>-</p> */}
                      //           <p className="transaction-success">
                      //             {/* <Image
                      //               className="time-icon"
                      //               src={
                      //                 window.location.origin +
                      //                 "/images/wallet/success.svg"
                      //               }
                      //             /> */}
                      //             {payment.message}
                      //           </p>
                      //         </div>
                      //       )}
                      //     </div>
                      //   </InfiniteScroll>
                      // </div>

                      <div className="new-table-sec wallet-history-table-header">
                        <InfiniteScroll
                          dataLength={
                            props.userWalletDetails.data.user_wallet_payments
                              .length
                          }
                          next={fetchMoreWalletPayments}
                          hasMore={
                            props.userWalletDetails.data.user_wallet_payments
                              .length < props.userWalletDetails.data.total
                          }
                          loader={<CommonCenterLoader />}
                        >
                          <Table
                            striped
                            bordered
                            hover
                            size="sm"
                            responsive="md"
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Trasaction ID</th>
                                <th>Amount</th>
                                <th>Status</th>
                                {/* <th></th> */}
                              </tr>
                            </thead>

                            <tbody className="custom-table-content">
                              {props.userWalletDetails.data.user_wallet_payments.map(
                                (payment, i) => (
                                  <tr key={i}>
                                    <td>{payment.paid_date_formatted}</td>
                                    <td>
                                      {payment.payment_id ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {payment.payment_id}
                                            </Tooltip>
                                          }
                                        >
                                          <div className="transaction-id-sec">
                                            <CopyToClipboard
                                              text={payment.payment_id}
                                              onCopy={() => onCopy()}
                                            >
                                              <span>{payment.payment_id}</span>
                                            </CopyToClipboard>
                                          </div>
                                        </OverlayTrigger>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {payment.amount_type === "add"
                                        ? "+ "
                                        : "- "}
                                      {payment.requested_amount_formatted}
                                    </td>
                                    {/* <p>-</p> */}
                                    <td className="transaction-success">
                                      {/* <Image
                                    className="time-icon"
                                    src={
                                      window.location.origin +
                                      "/images/wallet/success.svg"
                                    }
                                  /> */}
                                      {payment.message}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      </div>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
              {/* </>
                  }) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  userWalletDetails: state.wallet.userWalletDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
