import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Button,
  FormControl,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Marketplace.css";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import { propTypes } from "react-bootstrap/esm/Image";
import {
  fetchDomainMarketPlacesStart,
  fetchMoreDomainMarketPlacesStart,
} from "../store/actions/DomainAction";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";
import MarketPlaceRow from "./MarketPlaceRow";
import configuration from "react-global-configuration";

const MarketPlaceIndex = (props) => {
  const [filter, setFilter] = useState({
    search_key: "",
    amount: "",
    sort_by: "",
  });
  const [activeFilter, setActiveFilter] = useState(filter);

  useEffect(() => {
    props.dispatch(
      fetchDomainMarketPlacesStart({
        skip: 0,
        take: 12,
        ...activeFilter,
      })
    );
  }, [activeFilter]);

  const fetchMoreMarketPlaces = () => {
    props.dispatch(
      fetchMoreDomainMarketPlacesStart({
        skip: props.domainMarketplaceList.data.domain_market_places.length,
        take: 12,
        ...activeFilter,
      })
    );
  };

  return (
    <>
      <div className="marketplace-sec">
        <DomainSearchSec />
        <Container>
          <Row className="justify-content-md-center mt-5">
            <Col md={11}>
              <div className="title-sec">
                <h2>Marketplace</h2>
              </div>
              <div className="marketplace-header-sec">
                <Form>
                  <div className="marketplace-filter-box">
                    <div className="marketplace-filter-search-card">
                      <InputGroup className="mb-0">
                        <InputGroup.Text>
                          <Image
                            className="marketplace-filter-icon"
                            src={
                              window.location.origin +
                              "/images/marketplace/search-icon.svg"
                            }
                          />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search For Your Personal TLD"
                          type="text"
                          value={filter.search_key}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              search_key: e.target.value,
                            })
                          }
                        />
                        {filter.search_key !== "" ? (
                          <InputGroup.Text
                            onClick={() =>
                              setFilter({
                                ...filter,
                                search_key: "",
                              })
                            }
                          >
                            <Image
                              className="marketplace-filter-icon"
                              src={
                                window.location.origin +
                                "/images/marketplace/close-filter.svg"
                              }
                            />
                          </InputGroup.Text>
                        ) : null}
                      </InputGroup>
                    </div>
                    <div className="marketplace-filter-price-card">
                      <InputGroup className="mb-0">
                        <InputGroup.Text>
                          <Image
                            className="marketplace-filter-dollar-icon"
                            src={
                              window.location.origin +
                              "/images/marketplace/dollar.png"
                            }
                          />
                        </InputGroup.Text>
                        <FormControl
                          placeholder={`Price - ${configuration.get(
                            "configData.currency_code"
                          )}`}
                          type="number"
                          value={filter.amount}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              amount: e.target.value,
                            })
                          }
                        />
                        {filter.amount !== "" ? (
                          <InputGroup.Text
                            onClick={() =>
                              setFilter({
                                ...filter,
                                amount: "",
                              })
                            }
                          >
                            <Image
                              className="marketplace-filter-icon"
                              src={
                                window.location.origin +
                                "/images/marketplace/close-filter.svg"
                              }
                            />
                          </InputGroup.Text>
                        ) : null}
                      </InputGroup>
                    </div>
                    <div className="marketplace-filter-sort-card">
                      <InputGroup className="mb-0">
                        <InputGroup.Text>
                          <Image
                            className="marketplace-filter-dollar-icon"
                            src={
                              window.location.origin +
                              "/images/marketplace/sort-icon.png"
                            }
                          />
                        </InputGroup.Text>
                        <Form.Select
                          aria-label="Default select example"
                          value={filter.sort_by}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              sort_by: e.target.value,
                            })
                          }
                        >
                          <option value="">Default Order</option>
                          <option value="ALPHABETICAL">Alphabetical</option>
                          <option value="PRICE_LOWEST">Price:Lowest</option>
                          <option value="PRICE_HIGHEST">Price:Highest</option>
                          <option value="LIST_LATEST">Listed:Newest</option>
                          <option value="LIST_OLDEST">Listed:Oldest</option>
                        </Form.Select>
                        {filter.sort_by !== "" ? (
                          <InputGroup.Text
                            onClick={() =>
                              setFilter({
                                ...filter,
                                sort_by: "",
                              })
                            }
                          >
                            <Image
                              className="marketplace-filter-icon"
                              src={
                                window.location.origin +
                                "/images/marketplace/close-filter.svg"
                              }
                            />
                          </InputGroup.Text>
                        ) : null}
                      </InputGroup>
                    </div>
                    <div className="marketplace-search-btn-card">
                      <Button
                        className="search-btn"
                        onClick={() => setActiveFilter(filter)}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="your-bids-tab-sec your-marketplace-tab-sec mb-5">
                <div className="your-domain-sec">
                  {props.domainMarketplaceList.loading ? (
                    <CommonCenterLoader />
                  ) : props.domainMarketplaceList.data.domain_market_places &&
                    props.domainMarketplaceList.data.domain_market_places
                      .length > 0 ? (
                    // <div className="custom-table-list">
                    //   <div className="custom-table-header">
                    //     <div className="custom-table-header-card">
                    //       <h5>Domain</h5>
                    //       <h5>Price</h5>
                    //     </div>
                    //   </div>
                    //   <InfiniteScroll
                    //     dataLength={props.domainMarketplaceList.data.domain_market_places.length}
                    //     next={fetchMoreMarketPlaces}
                    //     hasMore={props.domainMarketplaceList.data.domain_market_places.length <
                    //       props.domainMarketplaceList.data.total}
                    //     loader={<CommonCenterLoader />}
                    //   >
                    //     <div className="custom-table-body">
                    //       {props.domainMarketplaceList.data.domain_market_places.map((domain, i) =>
                    //         <MarketPlaceRow
                    //           key={i}
                    //           domain={domain} />
                    //       )}
                    //     </div>
                    //   </InfiniteScroll>
                    // </div>

                    <div className="new-table-sec table-list-border">
                      <InfiniteScroll
                        dataLength={
                          props.domainMarketplaceList.data.domain_market_places
                            .length
                        }
                        next={fetchMoreMarketPlaces}
                        hasMore={
                          props.domainMarketplaceList.data.domain_market_places
                            .length < props.domainMarketplaceList.data.total
                        }
                        loader={<CommonCenterLoader />}
                      >
                        <Table striped bordered hover size="sm" responsive="md">
                          <thead>
                            <tr>
                              <th>Domain</th>
                              <th>Price</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody className="custom-table-content">
                            {props.domainMarketplaceList.data.domain_market_places.map(
                              (domain, i) => (
                                <MarketPlaceRow key={i} domain={domain} />
                              )
                            )}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  domainMarketplaceList: state.domain.domainMarketplaceList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MarketPlaceIndex);
