import React, { useEffect } from "react";
import api from "../Environment";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    api.postMethod("logout").then((response) => {
      if (response.data.success) {
        console.log("success");
      } else {
      }
    });
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("user_picture");
    localStorage.removeItem("user_cover");
    localStorage.removeItem("name");
    localStorage.removeItem("username");
    localStorage.removeItem("user_unique_id");
    localStorage.removeItem("email");
    // window.location.assign("/");
    navigate("/");
  }, []);
  return "";
};

export default Logout;
