import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setDefaultUserBillingAccountsStart,
  fetchUserBillingAccountsListStart,
  deleteUserBillingAccountsStart,
} from '../store/actions/WalletAction';

const SingleAccount = (props) => {
  const { selectedAccount, setSelectedAccount, account } = props;
  const [skipRender, setSkipRender] = useState(true);

  const makeDefaultAccount = e => {
    props.dispatch(setDefaultUserBillingAccountsStart({
      user_billing_account_id: account.user_billing_account_id,
    }));
    e.stopPropagation();
  };

  const removeAccount = e => {
    props.dispatch(deleteUserBillingAccountsStart({
      user_billing_account_id: account.user_billing_account_id,
    }));
    e.stopPropagation();
  }

  useEffect(() => {
    if (!skipRender && !props.defaultUserBillingAccounts.loading && Object.keys(props.defaultUserBillingAccounts.data).length > 0) {
      props.dispatch(fetchUserBillingAccountsListStart());
    }
    setSkipRender(false);
  }, [props.defaultUserBillingAccounts]);


  return (
    <div className="select-card">
      <input type="radio" value="Order one" checked={selectedAccount === account.user_billing_account_id} />
      <label onClick={() => setSelectedAccount(account.user_billing_account_id)}>
        <div className="select-item">
          <div className="select-item-info">
            <h6>{account.first_name}{" "}{account.last_name}</h6>
            <p>Acc No - {[...Array(account.account_number.length - 4)].map(() => "x")}
              {account.account_number.substr(account.account_number.length - 4)}
            </p>
            {account.is_default === 1 ?
              <p className="text-success">Default</p>
              : <p className="text-warning" onClick={makeDefaultAccount}>
                {props.defaultUserBillingAccounts.loadingButtonContent &&
                  props.defaultUserBillingAccounts.data.user_billing_account_id === account.user_billing_account_id ?
                  props.defaultUserBillingAccounts.loadingButtonContent
                  : "Make as default"
                }
              </p>}
            <span onClick={removeAccount} className="text-danger card-delete">
              {props.deleteUserBillingAccounts.loadingButtonContent &&
                props.deleteUserBillingAccounts.data.user_billing_account_id === account.user_billing_account_id ?
                props.deleteUserBillingAccounts.loadingButtonContent
                : <img src={window.location.origin + "/images/delete.svg"} width="25" height="25" />
              }
            </span>
          </div>
        </div>
      </label>
    </div>
  );
}

const mapStateToPros = (state) => ({
  defaultUserBillingAccounts: state.wallet.defaultUserBillingAccounts,
  deleteUserBillingAccounts: state.wallet.deleteUserBillingAccounts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleAccount);