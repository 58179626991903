import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Dropdown,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import "./DomainManager.css";
import { connect } from "react-redux";
import { fetchDomainsForOwnerStart, fetchMoreDomainsForOwnerStart } from "../store/actions/DomainAction";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../Helper/NoDataFound";
import SoldDomain from "./SoldDomain";
import YourDomain from "./YourDomain";

const DomainManagerIndex = (props) => {
  const [activeTab, setActiveTab] = useState("your-domains");

  return (
    <>
      <div className="domain-manager-sec">
        <div className="dashboard-sec">
          <DomainSearchSec />
          <div className="dashboard-body-sec">
            <div className="main-wrapper-box">
              <div className="mobile-sidebar-sec">
                <DashboardSidebar />
              </div>
              <div className="main-wrapper-right ">
                <div className="title-sec">
                  <h2>Domain Manager</h2>
                  <p>
                    Good to See you here
                    <Image
                      className="wave-hand-icon"
                      src={
                        window.location.origin +
                        "/images/dashboard/waving-hand.png"
                      }
                    />
                  </p>
                </div>
                {/* <div className="domain-sort-sec">
                  <p>Sort By</p>
                  <Image
                    className="wave-hand-icon"
                    src={window.location.origin + "/images/filter.svg"}
                  />
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Newest
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <div className="your-bids-tab-sec">
                  <Tab.Container
                    id="left-tabs-example"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("your-domains")}
                              className={activeTab === "your-domains" ? "active" : ""}
                            >Your domains</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("for-sale")}
                              className={activeTab === "for-sale" ? "active" : ""}
                            >For sale</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("sold")}
                              className={activeTab === "sold" ? "active" : ""}
                            >Sold</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        {activeTab === "sold" ?
                          <SoldDomain />
                          : <YourDomain
                            activeTab={activeTab}
                          />
                        }
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  domains: state.domain.domainsForOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(DomainManagerIndex);
