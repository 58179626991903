import React, { useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import configuration from "react-global-configuration";

const ReviewYourBidModal = (props) => {

  const [totalAmount,setTotalAmount] = useState(Number(props.bidAmount) + Number(configuration.get("configData.mining_fee")));

  return (
    <>
      <Modal
        className="modal-dialog-center add-card-modal"
        size="md"
        centered
        show={props.reviewYourBid}
        onHide={props.closeReviewYourBidModal}
      >
        <Modal.Body>
          <h4>Review Your Purchase</h4>
          <Button className="modal-close" onClick={() => props.closeReviewYourBidModal()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/images/dashboard/modal-close.svg"
              }
            />
          </Button>
          <div className="review-your-bid-receipt-sec">
            <div className="review-your-bid-receipt-header">
              <h5>RECEIPT #1 - BID</h5>
              <h5>{new Date().toLocaleDateString()}</h5>
            </div>
            <div className="review-your-bid-receipt-body">
              <div className="review-your-bid-receipt-item">
                <h6>AMOUNT</h6>
                <small>-</small>
                <p>{props.bidAmount} USD</p>
              </div>
              {/* <div className="review-your-bid-receipt-item">
                        <h6>ADDED BLIND</h6>
                        <small>-</small>
                        <p>10.00 USD</p>
                    </div> */}
              <div className="review-your-bid-receipt-item">
                <h6>SERVICE FEE</h6>
                <small>-</small>
                <p>{configuration.get("configData.mining_fee")} USD</p>
              </div>
            </div>
            <div className="review-your-bid-receipt-footer">
              <div className="review-your-bid-receipt-item">
                <h6>Total</h6>
                <small>-</small>
                <p>{totalAmount } USD</p>
              </div>
            </div>
            <div className="add-card-btn-sec">
              <p>Guaranteed safe & secure checkout</p>
              <Button className="add-money-btn" 
                disabled={props.createBid.buttonDisable}
                onClick={() => props.placeBid()} 
                >
                {props.createBid.loadingButtonContent == null 
                  ? "Place Bid" : 
                  props.createBid.loadingButtonContent}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReviewYourBidModal;