import React from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addUserBillingAccountsStart } from '../store/actions/WalletAction';
import { connect } from 'react-redux';

const AddNewBillingAccountModal = (props) => {

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string()
      .required("route number is required"),
    account_number: Yup.string()
      .required("account number is required")
      .test('len', 'Must be greater than 5 numbers', val => val.length >= 6),
    first_name: Yup.string()
      .required("first name is required"),
    last_name: Yup.string()
      .required("last name is required"),
  });

  const handleSubmit = (values) => {
    props.dispatch(addUserBillingAccountsStart(values));
  };

  return (
    <>
      <Modal
        className="modal-dialog-center add-card-modal"
        size="md"
        centered
        show={props.addNewBillingAccount}
        onHide={props.closeAddNewBillingAccountModal}
      >
        <Modal.Body>
          <h4>Add New Billing Account</h4>
          <Button className="modal-close" onClick={() => props.closeAddNewBillingAccountModal()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/images/dashboard/modal-close.svg"
              }
            />
          </Button>
          <Formik
            initialValues={{
              route_number: "",
              account_number: "",
              first_name: "",
              last_name: "",
              bank_type: "savings",
              business_name: "",
            }}
            validationSchema={addbillingSchema}
            onSubmit={(values) => handleSubmit(values)}>

            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <FORM className="add-card-form">
                <Form.Group className="mb-3">
                  <Form.Label>Route Number *</Form.Label>
                  <Field
                    type="text"
                    placeholder="Enter the route number"
                    name="route_number"
                    className="form-control"
                  />
                  <ErrorMessage component={"div"} name="route_number" className='text-danger text-right' />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Account Number *</Form.Label>
                  <Field
                    type="text"
                    placeholder="Enter your account number"
                    name="account_number"
                    className="form-control"
                  />
                  <ErrorMessage component={"div"} name="account_number" className='text-danger text-right' />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>First Name *</Form.Label>
                  <Field
                    type="text"
                    placeholder="Enter your first name"
                    name="first_name"
                    className="form-control"
                  />
                  <ErrorMessage component={"div"} name="first_name" className='text-danger text-right' />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name *</Form.Label>
                  <Field
                    type="text"
                    placeholder="Enter your last name"
                    name="last_name"
                    className="form-control"
                  />
                  <ErrorMessage component={"div"} name="last_name" className='text-danger text-right' />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bank Type *</Form.Label>
                  <Field
                    as="select"
                    placeholder="select the bank type"
                    name="bank_type"
                    className="form-control"
                  >
                    <option value="savings">Savings</option>
                    <option value="checking">Checking</option>
                  </Field>
                  <ErrorMessage component={"div"} name="bank_type" className='text-danger text-right' />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Business Name</Form.Label>
                  <Field
                    type="text"
                    placeholder="enter your business name"
                    name="business_name"
                    className="form-control"
                  />
                  <ErrorMessage component={"div"} name="business_name" className='text-danger text-right' />
                </Form.Group>
                <div className="add-card-btn-sec">
                  <Button
                    type="submit"
                    className="add-money-btn"
                    disabled={props.addUserBillingAccounts.buttonDisable}
                  >
                    {props.addUserBillingAccounts.loadingButtonContent ?
                      props.addUserBillingAccounts.loadingButtonContent
                      : "Add Account"
                    }
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  addUserBillingAccounts: state.wallet.addUserBillingAccounts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddNewBillingAccountModal);