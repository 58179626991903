import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { updateUserDetailsStart } from "../store/actions/UserAction";


const EditProfileModal = (props) => {
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    name: Yup.string().required("Required"),
  });

  const updateProfile = values => {
    props.dispatch(updateUserDetailsStart(values));
  }

  useEffect(() => {
    if (!skipRender && !props.profileInputData.loading && Object.keys(props.profileInputData.data).length > 0) {
      props.closeEditProfile();
    }
    setSkipRender(false);
  }, [props.profileInputData])

  return (
    <>
      <Modal
        className="modal-dialog-center add-card-modal"
        size="md"
        centered
        show={props.editProfile}
        onHide={props.closeEditProfile}
      >
        <Modal.Body>
          <h4>Edit</h4>
          <Button className="modal-close" onClick={() => props.closeEditProfile()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/images/dashboard/modal-close.svg"
              }
            />
          </Button>
          <Formik
            initialValues={{
              name: props.profile.data.name,
              email: props.profile.data.email,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => updateProfile(values)}
          >
            {({ errors, touched }) => (
              <FORM className="add-card-form">
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder="Enter Your Name"
                    name="name"
                  />
                  {errors.name && touched.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Field
                    className="form-control"
                    type="email"
                    placeholder="Enter Your Email Address"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </Form.Group>
                <div className="add-card-btn-sec">
                  <Button
                    type="submit"
                    className="add-money-btn"
                    disabled={props.profileInputData.buttonDisable}>
                    {props.profileInputData.loadingButtonContent ?
                      props.profileInputData.loadingButtonContent
                      : "Submit"
                    }
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(EditProfileModal);