import React, { useState } from "react";
import { Container, Row, Col, Image} from "react-bootstrap";


const HowDoesItsWork = (props) => {

  return (
    <>
      <div className="how-does-it-work-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="section-title-sec">
                  <h6>WORKING</h6>
                  <h2>
                    How Does It Work
                  </h2>
                  <Image
                      className="title-border-botom"
                      src={
                          window.location.origin + "/images/title-border-bottom.svg"
                      }
                  />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={4} lg={4}>
                <div className="how-does-it-work-card">
                  <div className="how-does-it-work-icon-sec">
                    <Image
                        className="how-does-it-work-icon"
                        src={
                            window.location.origin + "/images/how-its-working-icon.png"
                        }
                    />
                  </div>
                  <div className="border-bottom-purple"></div>
                  <div className="how-does-it-work-info">
                    <h4>NFT based ownership</h4>
                    <p>Buy and sell on any NFT Marketplace you want, liquidate your inventory instantly</p>
                  </div>
                </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
                <div className="how-does-it-work-card">
                  <div className="how-does-it-work-icon-sec">
                    <Image
                        className="how-does-it-work-icon"
                        src={
                            window.location.origin + "/images/how-its-working-icon.png"
                        }
                    />
                  </div>
                  <div className="border-bottom-purple"></div>
                  <div className="how-does-it-work-info">
                    <h4>Un-Hackable like Bitcoin</h4>
                    <p>Your TLD registry is on a blockchain which means it's unhackable and perpetually safe</p>
                  </div>
                </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
                <div className="how-does-it-work-card">
                  <div className="how-does-it-work-icon-sec">
                    <Image
                        className="how-does-it-work-icon"
                        src={
                            window.location.origin + "/images/how-its-working-icon.png"
                        }
                    />
                  </div>
                  <div className="border-bottom-purple"></div>
                  <div className="how-does-it-work-info">
                    <h4>Centralised Censorship Free</h4>
                    <p>No government or company can deny access to your TLD or Your domain buyers</p>
                  </div>
                </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
                <div className="how-does-it-work-card">
                  <div className="how-does-it-work-icon-sec">
                    <Image
                        className="how-does-it-work-icon"
                        src={
                            window.location.origin + "/images/how-its-working-icon.png"
                        }
                    />
                  </div>
                  <div className="border-bottom-purple"></div>
                  <div className="how-does-it-work-info">
                    <h4>Each .Crypto domain sells for $10k</h4>
                    <p>Unstoppable Domains moved in this space early and was able to sell each of his domains for 10K</p>
                  </div>
                </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
                <div className="how-does-it-work-card">
                  <div className="how-does-it-work-icon-sec">
                    <Image
                        className="how-does-it-work-icon"
                        src={
                            window.location.origin + "/images/how-its-working-icon.png"
                        }
                    />
                  </div>
                  <div className="border-bottom-purple"></div>
                  <div className="how-does-it-work-info">
                    <h4>Highest Sold .com domain</h4>
                    <p>Someone had the acumen to buy Voice.com for $10 and sold it for $30 Million. Become the next business case study</p>
                  </div>
                </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
                <div className="how-does-it-work-card">
                  <div className="how-does-it-work-icon-sec">
                    <Image
                        className="how-does-it-work-icon"
                        src={
                            window.location.origin + "/images/how-its-working-icon.png"
                        }
                    />
                  </div>
                  <div className="border-bottom-purple"></div>
                  <div className="how-does-it-work-info">
                    <h4>Become a Web3 Business</h4>
                    <p>Do you see Venture Capitalists funding anything other than Fintech these days? Become a Web3 business to become darling of VCs</p>
                  </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowDoesItsWork;
