import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
} from "react-bootstrap";

const DashboardSidebar = (props) => {
  const location = useLocation();

  return (
    <>
      <div className="main-wrapper-left mobile-left-sec">
        <div className="sidebar-list">
          <ul className="list-unstyled">
            <li className={location.pathname === "/dashboard" ? "active" : ""}>
              <Link to="/dashboard">
                <Image
                  className="sidebar-icon"
                  src={
                    window.location.origin + "/images/dashboard/dashboard.svg"
                  }
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/domain-manager" ? "active" : ""
              }
            >
              <Link to="/domain-manager">
                <Image
                  className="sidebar-icon"
                  src={
                    window.location.origin +
                    "/images/dashboard/domain-manager.svg"
                  }
                />
                <span>Domain Manager</span>
              </Link>
            </li>
            <li className={location.pathname === "/your-bids" ? "active" : ""}>
              <Link to="/your-bids">
                <Image
                  className="sidebar-icon"
                  src={
                    window.location.origin + "/images/dashboard/your-bid.png"
                  }
                />
                <span>Your Bids</span>
              </Link>
            </li>
            <li className={location.pathname === "/watchlist" ? "active" : ""}>
              <Link to="/watchlist">
                <Image
                  className="sidebar-icon"
                  src={
                    window.location.origin + "/images/dashboard/wishlist-icon.svg"
                  }
                />
                <span>Watchlist</span>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/wallet" ||
                  location.pathname === "/add-money"
                  ? "active"
                  : ""
              }
            >
              <Link to="/wallet">
                <Image
                  className="sidebar-icon"
                  src={window.location.origin + "/images/dashboard/wallet.svg"}
                />
                <span>Wallet</span>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/wallet-withdraw" ||
                  location.pathname === "/withdraw-money"
                  ? "active"
                  : ""
              }
            >
              <Link to="/wallet-withdraw">
                <Image
                  className="sidebar-icon"
                  src={window.location.origin + "/images/dashboard/withdraw.svg"}
                />
                <span>Withdraw</span>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/transaction-history" ? "active" : ""
              }
            >
              <Link to="/transaction-history">
                <Image
                  className="sidebar-icon"
                  src={
                    window.location.origin + "/images/dashboard/transaction.png"
                  }
                />
                <span>Transaction History</span>
              </Link>
            </li>
            <li className={location.pathname === "/settings" ? "active" : ""}>
              <Link to="/settings">
                <Image
                  className="sidebar-icon"
                  src={
                    window.location.origin + "/images/dashboard/settings.svg"
                  }
                />
                <span>Settings</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="mobile-responsive-tab">
        <div className="main-wrapper-left mobile-left-wrapper ">
          <div className="sidebar-list">
            <ul className="list-unstyled">
              <li
                className={
                  location.pathname === "/mobile-dashboard" ? "active" : ""
                }
              >
                <Link to="/mobile-dashboard">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin + "/images/dashboard/dashboard.svg"
                    }
                  />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/domain-manager" ? "active" : ""
                }
              >
                <Link to="/domain-manager">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin +
                      "/images/dashboard/domain-manager.svg"
                    }
                  />
                  <span>Domain Manager</span>
                </Link>
              </li>
              <li
                className={location.pathname === "/your-bids" ? "active" : ""}
              >
                <Link to="/your-bids">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin + "/images/dashboard/your-bid.png"
                    }
                  />
                  <span>Your Bids</span>
                </Link>
              </li>
              <li className={location.pathname === "/watchlist" ? "active" : ""}>
                <Link to="/watchlist">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin + "/images/dashboard/wishlist-icon.svg"
                    }
                  />
                  <span>Watchlist</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/wallet" ||
                    location.pathname === "/add-money"
                    ? "active"
                    : ""
                }
              >
                <Link to="/wallet">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin + "/images/dashboard/wallet.svg"
                    }
                  />
                  <span>Wallet</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/wallet-withdraw" ||
                    location.pathname === "/withdraw-money"
                    ? "active"
                    : ""
                }
              >
                <Link to="/wallet-withdraw">
                  <Image
                    className="sidebar-icon"
                    src={window.location.origin + "/images/dashboard/withdraw.svg"}
                  />
                  <span>Withdraw</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/transaction-history" ? "active" : ""
                }
              >
                <Link to="/transaction-history">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin +
                      "/images/dashboard/transaction.png"
                    }
                  />
                  <span>Transaction History</span>
                </Link>
              </li>
              <li className={location.pathname === "/settings" ? "active" : ""}>
                <Link to="/settings">
                  <Image
                    className="sidebar-icon"
                    src={
                      window.location.origin + "/images/dashboard/settings.svg"
                    }
                  />
                  <span>Settings</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSidebar;
