import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button } from "react-bootstrap";
import {Link} from "react-router-dom";
import BannerSection from "./BannerSection";
import DiscoverPerfectDomain from "./DiscoverPerfectDomain";
import HowDoesItsWork from "./HowDoesItsWork";
import OurCustomers from "./OurCustomers";
import OurSalesReport from "./OurSalesReport";
import TopMarketplace from "./TopMarketplace";


const LandingPageIndex = (props) => {

  return (
    <>
      <BannerSection/>
      <HowDoesItsWork/>
      <OurSalesReport/>
      <DiscoverPerfectDomain/>
      <OurCustomers/>
      {/* <TopMarketplace/> */}
    </>
  );
};

export default LandingPageIndex;
