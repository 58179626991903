import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import DomainSaga from './DomainSaga';
import BidSaga from './BidSaga';
import WalletSaga from './WalletSaga';
import PageSaga from './PageSaga';

export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(DomainSaga),
    fork(BidSaga),
    fork(WalletSaga),
    fork(PageSaga),
  ]);
}
