import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  usernameValidationStart,
  userRegisterStart,
} from "../store/actions/UserAction";
import configuration from "react-global-configuration";

const RegisterIndex = (props) => {
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [lastUsername, setLastUsername] = useState("");

  useEffect(() => {
    if (
      !skipRender &&
      !props.register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      setTimeout(() => {
        if (props.register.data.is_email_verified === 0) {
          navigate("/register-verification");
        } else {
          navigate("/dashboard");
        }
      }, 1000);
    }
    setSkipRender(false);
  }, [props.register]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const register = (values) => {
    props.dispatch(userRegisterStart(values));
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // const validateUsername = (value, props) => {
  //   return sleep(2000).then(() => {
  //     console.log("Entered");
  //     let errors = '';
  //     if (value !== "ajay") {
  //       console.log("Caught error");
  //       errors = 'Nice try';
  //       return errors;
  //     }
  //     // ...
  //     else {
  //       return "";
  //     }
  //   });
  // }

  const validateUsername = (value, properties) => {
    if (value) {
      if (value.length < 3) {
        return "Username should be atleast 3 char";
      } else if (value !== lastUsername) {
        let formatted = value.replaceAll(/\s+/g, "");
        formatted = formatted.replaceAll(
          /[&\/\\#,+()$~%.'":*?!^|_;@<>{}]/g,
          ""
        );
        if (formatted !== value) {
          return "A-Z, 0-9, - are allowed. No space";
        } else {
          setLastUsername(value);
          props.dispatch(
            usernameValidationStart({
              username: value,
            })
          );
          return "";
        }
      }
    } else {
      return "Required";
    }
  };

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <div className="auth-left-sec">
            <div className="auth-card mt-4 mb-4">
              <div className="login-logo text-center mb-4">
                <Link to="/">
                  <Image src={window.location.origin + "/images/logo.png"} />
                </Link>
              </div>
              <h2>Create An Account</h2>
              <p>
                Already Have an Account? <Link to="/login">Click Here</Link>
              </p>
              <div className="auth-form">
                <Formik
                  initialValues={{
                    name: "",
                    username: "",
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => register(values)}
                >
                  {({ errors, touched }) => (
                    <FORM>
                      <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Enter Your Name..."
                        />
                        <ErrorMessage
                          component={"div"}
                          name="name"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>UserName</Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="username"
                          placeholder="Enter a Username..."
                          validate={validateUsername}
                        />
                        {errors.username ? (
                          <div className="text-danger">{errors.username}</div>
                        ) : props.usernameValidation.loadingButtonContent ? (
                          <img
                            src={
                              window.location.origin +
                              "/images/username-loading.gif"
                            }
                            width="15"
                            height="15"
                          />
                        ) : props.usernameValidation.data.success === true ? (
                          <div className="text-success">
                            {props.usernameValidation.data.message}
                          </div>
                        ) : (
                          <div className="text-danger">
                            {props.usernameValidation.error}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Email Address</Form.Label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter Your Email Address..."
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter Your Password"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger"
                        />
                      </Form.Group>
                      <div className="auth-btn-sec">
                        <Button
                          type="submit"
                          className="default-btn"
                          disabled={
                            props.register.buttonDisable ||
                            !props.usernameValidation.data.success ||
                            errors.username
                          }
                        >
                          {props.register.loadingButtonContent
                            ? props.register.loadingButtonContent
                            : "Register"}
                        </Button>
                      </div>
                      <div className="terms-agree-sec">
                        <p>
                          By creating an account, you agree to{" "}
                          {configuration.get("configData.site_name")}'s <br />
                          <Link to="/page/terms" target="_blank">
                            Terms & Conditions
                          </Link>{" "}
                          and{" "}
                          <Link to="/page/privacy" target="_blank">
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="auth-right-sec">
            <div className="auth-img-sec">
              <Image
                className="auth-img"
                src={window.location.origin + "/images/login-img.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.users.register,
  usernameValidation: state.users.usernameValidation,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterIndex);
