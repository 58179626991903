import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";


const DiscoverPerfectDomain = (props) => {

    return (
        <>
            <div className="discover-perfect-domain-sec sm-padding">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} xl={6} lg={6} className="resp-mrng-btm-md">
                            <div className="discover-perfect-domain-img-sec">
                                <Image
                                    className="discover-perfect-domain-img"
                                    src={
                                        window.location.origin + "/images/perfect-domain.svg"
                                    }
                                />
                            </div>
                        </Col>
                        <Col md={12} xl={6} lg={6}>
                            <div className="discover-perfect-domain-info-sec">
                                <div className="discover-perfect-domain-info">
                                    <h6>Find the right domain</h6>
                                    <h2>
                                        Discover your perfect domain
                                    </h2>
                                    <Image
                                        className="title-border-botom"
                                        src={
                                            window.location.origin + "/images/title-border-bottom.svg"
                                        }
                                    />
                                    <p>Find the right name to help people find you. Use our search and suggestion tools. Pick from hundreds of domain name endings.</p>
                                </div>
                                <Link to="#" className="custom-black-btn get-started-btn">
                                    Get Started
                                    <Image
                                        className="right-arrow"
                                        src={
                                            window.location.origin + "/images/right-arrow.svg"
                                        }
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default DiscoverPerfectDomain;
