import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";
import { fetchUserWalletAddressStart } from "../store/actions/WalletAction";
import QRCode from "react-qr-code";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";


const WalletAddressModal = (props) => {

  useEffect(() => {
    props.dispatch(fetchUserWalletAddressStart());
  }, []);

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Wallet Address Copied"));
  }

  return (
    <>
      <Modal
        className="modal-dialog-center add-card-modal"
        size="md"
        centered
        show={props.walletAddressModal}
        onHide={props.closeWalletAdressModal}
      >
        <Modal.Body>
          <h4 className="text-center">Wallet Address</h4>
          <Button className="modal-close" onClick={() => props.closeWalletAdressModal()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/images/dashboard/modal-close.svg"
              }
            />
          </Button>
          {props.walletAddress.loading ?
            <CommonCenterLoader />
            : Object.keys(props.walletAddress.data).length > 0 ?
              <div className="wallet-address text-center mt-5 mb-5">
                <InputGroup className="mb-5">
                  <FormControl
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={props.walletAddress.data.address}
                    disabled={true}
                  />
                  <CopyToClipboard text={props.walletAddress.data.address}
                    onCopy={() => onCopy()}>
                    <InputGroup.Text id="basic-addon2" className="copy-container">
                      <span><Image
                        className="copy-icon"
                        src={
                          window.location.origin + "/images/copy.svg"
                        }
                      /></span>
                    </InputGroup.Text>
                  </CopyToClipboard>
                </InputGroup>
                {/* <div className="mb-5 ">{props.walletAddress.data.address}</div> */}
                <QRCode value={props.walletAddress.data.address} size={180} />
              </div>
              : <NoDataFound />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  walletAddress: state.wallet.walletAddress,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(WalletAddressModal);