import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Static.css";
import { useParams } from "react-router";
import { fetchSinglePageStart } from "../store/actions/PageAction";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import DomainSearchSec from "../DashBoard/DomainSearchSec";

const StaticIndex = (props) => {

  const { static_page_unique_id } = useParams();

  useEffect(() => {
    if (static_page_unique_id) {
      props.dispatch(
        fetchSinglePageStart({ static_page_unique_id: static_page_unique_id })
      );
    }
    window.scrollTo(0, 0);
  }, [static_page_unique_id]);

  return (
    <>
      <div className="about-us-sec">
        <DomainSearchSec />
        {props.pageData.loading ?
          <CommonCenterLoader />
          : Object.keys(props.pageData.data).length > 0 ? (
            <>
              <div className="about-us-banner-sec">
                <Image
                  className="about-us-banner-img"
                  src={
                    window.location.origin +
                    "/images/about/about-banner-bg.png"
                  }
                />
                <h2>{props.pageData.data.title}</h2>
              </div>
              <Container>
                <div className="about-us-box">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.pageData.data.description,
                    }}
                  ></p>
                </div>
              </Container>
            </>
          ) : <NoDataFound />}

      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticIndex);