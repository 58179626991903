import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";


const BannerSection = (props) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const onSearch = (e) => {
    e.preventDefault();
    let formatted = search.replaceAll(/[&\/\\#,+()$~%.'":*?!^|;@<>{}]/g, '');
    formatted = formatted.replaceAll(" ", "");
    if (formatted.length > 0)
      navigate(`/domains/${formatted}`);
    else
    props.dispatch(getErrorNotificationMessage("Empty or Invalid Search query"));
  }

  return (
    <>
      <div className="hero-banner-sec">
        <Container>
          <Row className="align-items-center">
            <Col md={12}>
              <div className="hero-banner-content">
                <div className="hero-banner-header-info-sec">
                  <div className="hero-banner-header-info">
                    <h1>Become the next .COM</h1>
                    <div className="hero-banner-action-sec">
                      <p>TLDs are top-level domain names that can churn out hundreds of Millions of Domain Names for people, businesses, organisations, Sports teams etc all of the domain buyers have to pay You the TLD owner to register their domain names.</p>
                
                    </div>
                  </div>
                  <div className="hero-banner-search-sec">
                    <Form className="search-form" onSubmit={onSearch}>
                      <Form.Control
                        type="text"
                        placeholder="Search For Your Personal TLD"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                      />
                      <Button className="search-btn" type="submit">
                        Search
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(BannerSection);
