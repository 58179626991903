import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  viewDoaminStart,
  fetchBidsListStart,
  createBidStart,
  fetchMoreBidsListStart,
} from "../store/actions/BidAction";
import { connect } from "react-redux";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/ToastNotification";
import ReviewYourBidModal from "./ReviewYourBidModal";
import configuration from "react-global-configuration";
import { updateWatchlistStart } from "../store/actions/DomainAction";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";

const SingleSearchIndex = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [searchDomain, setSearchDomain] = useState("");
  const [bidAmount, setBidAmount] = useState("0");

  const [skipRender, setSkipRender] = useState(true);
  const [minAmount, setMinAmount] = useState(
    configuration.get("configData.min_bid_amount")
      ? configuration.get("configData.min_bid_amount")
      : 10
  );

  useEffect(() => {
    if (params.search) {
      let search = params.search.replaceAll(/\s+/g, "");
      search = search.replaceAll(/[&\/\\#,+()$~%.'":*?!^|;@<>{}]/g, "");
      if (search !== params.search) {
        navigate(`/domains/${search}`);
      }
      let lowerCaseSearch = search.toLowerCase();
      if (lowerCaseSearch !== search) {
        navigate(`/domains/${lowerCaseSearch}`);
      }
      setSearchDomain(search);
      props.dispatch(
        viewDoaminStart({
          domain: search,
          skip: 0,
          take: 20,
        })
      );
    } else {
      navigate("/");
    }
  }, [params.search]);

  const updateBidStatus = () => {
    props.dispatch(updateWatchlistStart({ domain: params.search }));
  };

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Transacction Hash Copied"));
  };

  const [page, setPage] = useState(10);

  const handlePageClick = () => {};

  const [reviewYourBid, setReviewYourBid] = useState(false);

  const validateBid = () => {
    if (bidAmount >= minAmount) {
      setReviewYourBid(true);
    } else {
      props.dispatch(
        getErrorNotificationMessage(`The amount must be at least ${minAmount}`)
      );
    }
  };

  const closeReviewYourBidModal = () => {
    setReviewYourBid(false);
  };

  const placeBid = () => {
    props.dispatch(
      createBidStart({
        domain: params.search,
        amount: Number(bidAmount),
        mining_fee: Number(configuration.get("configData.mining_fee")),
      })
    );
  };

  useEffect(() => {
    if (
      !props.createBid.loading &&
      Object.keys(props.createBid.data).length > 0 &&
      props.createBid.data.success &&
      !skipRender
    ) {
      closeReviewYourBidModal();
      setBidAmount("0");
      props.dispatch(
        fetchBidsListStart({ domain: params.search, skip: 0, take: 20 })
      );
    }
    setSkipRender(false);
  }, [props.createBid]);

  const fetchMoreBids = () => {
    props.dispatch(
      fetchMoreBidsListStart({
        domain: params.search,
        skip: props.bidsList.data.bids.length,
        take: 20,
      })
    );
  };

  const redirect = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="single-search-sec">
        <DomainSearchSec />
        <Container>
          {
            props.domainView.loading ? (
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="single-search-name-sec">
                    <h2>{searchDomain}/</h2>
                    <Button className="add-wish-list-btn" disabled={true}>
                      <Image
                        className="add-wish-list-icon"
                        src={
                          window.location.origin +
                          "/images/single-search/wishlist-icon.svg"
                        }
                      />
                      <span>Add to Watchlist</span>
                    </Button>
                  </div>

                  <div className="single-search-purchase-info">
                    <div className="single-search-purchase-box">
                      <div className="single-search-purchase-card">
                        <h3>---</h3>
                        <p>Total Bids</p>
                      </div>
                      <div className="single-search-purchase-card">
                        <h3>---</h3>
                        <p>Est. time left to buy</p>
                      </div>
                      <div className="single-search-purchase-card">
                        <h3>---</h3>
                        <p>Total Watchlist</p>
                      </div>
                    </div>
                  </div>
                  <CommonCenterLoader />
                </Col>
              </Row>
            ) : (
              // props.domainView.data.domain ?
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="single-search-name-sec">
                    <h2>{searchDomain}/</h2>
                    {props.domainView.data.domain_status !== "RESERVED" ? (
                      localStorage.getItem("userId") &&
                      localStorage.getItem("accessToken") ? (
                        <Button
                          className="add-wish-list-btn"
                          onClick={() => updateBidStatus()}
                          disabled={props.updateWatchlist.buttonDisable}
                        >
                          {props.updateWatchlist.loadingButtonContent ? (
                            props.updateWatchlist.loadingButtonContent
                          ) : (
                            <>
                              {props.domainView.data.domain &&
                              props.domainView.data.domain.is_watchlisted ===
                                1 ? (
                                <>
                                  <Image
                                    className="add-wish-list-icon"
                                    src={
                                      window.location.origin +
                                      "/images/single-search/wishlist-icon.svg"
                                    }
                                  />
                                  <span>Remove from Watchlist</span>
                                </>
                              ) : (
                                <>
                                  <Image
                                    className="add-wish-list-icon"
                                    src={
                                      window.location.origin +
                                      "/images/single-search/wishlist-icon.svg"
                                    }
                                  />
                                  <span>Add to Watchlist</span>
                                </>
                              )}
                            </>
                          )}
                        </Button>
                      ) : (
                        <Button
                          className="add-wish-list-btn"
                          onClick={() => redirect()}
                          disabled={props.updateWatchlist.buttonDisable}
                        >
                          <Image
                            className="add-wish-list-icon"
                            src={
                              window.location.origin +
                              "/images/single-search/wishlist-icon.svg"
                            }
                          />
                          <span>Add to Wishlist</span>
                        </Button>
                      )
                    ) : null}
                  </div>
                  <div className="single-search-purchase-info">
                    <div className="single-search-purchase-box">
                      <div className="single-search-purchase-card">
                        <h3>
                          {props.domainView.data.domain_status === "RESERVED"
                            ? "---"
                            : props.bidsList.data.total}
                        </h3>
                        <p>Total Bids</p>
                      </div>
                      {/* <div className="single-search-purchase-card">
												<h3>283</h3>
												<p>Blocks left to bid</p>
											</div> */}
                      <div className="single-search-purchase-card">
                        <h3>
                          {props.domainView.data.domain_status !== "RESERVED" &&
                          props.domainView.data.domain &&
                          props.domainView.data.domain.auction_status === 1
                            ? props.domainView.data.domain.auction_end_formatted
                            : "---"}
                        </h3>
                        <p>Est. time left to buy</p>
                      </div>
                      <div className="single-search-purchase-card">
                        {/* total watchlist */}
                        <h3>
                          {props.domainView.data.domain_status === "RESERVED"
                            ? "---"
                            : props.domainView.data.total_watchlist}
                        </h3>
                        <p>Total Watchlist</p>
                      </div>
                    </div>
                  </div>

                  {props.domainView.data.domain_status !== "RESERVED" ? (
                    <>
                      {props.domainView.data.domain_status !== "TRANSFER" &&
                      props.domainView.data.domain_status !== "CLOSED" ? (
                        <div className="single-search-form-sec">
                          <div className="single-search-form-box">
                            <div className="single-search-status-card">
                              <h4>Status</h4>
                              <div className="single-search-status-container">
                                <ul className="single-search-status-tl">
                                  <li
                                    className={`tl-item ${
                                      (props.domainView.data.domain &&
                                        Object.keys(
                                          props.domainView.data.domain
                                        ).length === 0) ||
                                      (props.domainView.data.domain_status !==
                                        "REVEAL" &&
                                        props.domainView.data.domain_status !==
                                          "REVOKED" &&
                                        props.domainView.data.domain &&
                                        props.domainView.data.domain
                                          .auction_status === 0)
                                        ? "completed"
                                        : ""
                                    }`}
                                  >
                                    <div className="single-search-status-item-title">
                                      Initiated
                                    </div>
                                  </li>
                                  {/* <li className="tl-item">
															<div className="single-search-status-item-title">Released</div>
														</li> */}
                                  <li
                                    className={`tl-item ${
                                      props.domainView.data.domain &&
                                      props.domainView.data.domain_status !==
                                        "REVEAL" &&
                                      props.domainView.data.domain_status !==
                                        "REVOKED"
                                        ? props.domainView.data.domain
                                            .auction_status === 0
                                          ? "inactive"
                                          : props.domainView.data.domain
                                              .auction_status === 1
                                          ? "completed"
                                          : ""
                                        : ""
                                    }`}
                                  >
                                    <div className="single-search-status-item-title">
                                      Processing
                                    </div>
                                  </li>
                                  {/* <li className="tl-item">
															<div className="single-search-status-item-title">In reveal</div>
														</li> */}
                                  <li
                                    className={`tl-item ${
                                      props.domainView.data.domain &&
                                      props.domainView.data.domain_status !==
                                        "REVEAL" &&
                                      props.domainView.data.domain_status !==
                                        "REVOKED"
                                        ? props.domainView.data.domain
                                            .auction_status === 0
                                          ? "inactive"
                                          : props.domainView.data.domain
                                              .auction_status === 1
                                          ? "inactive"
                                          : props.domainView.data.domain
                                              .auction_status === 2
                                          ? "completed"
                                          : ""
                                        : props.domainView.data
                                            .domain_status !== "REVEAL" &&
                                          props.domainView.data
                                            .domain_status !== "REVOKED"
                                        ? ""
                                        : "completed"
                                    }`}
                                  >
                                    <div className="single-search-status-item-title">
                                      Completed
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {props.domainView.data.domain_status !== "REVEAL" &&
                            props.domainView.data.domain_status !==
                              "REVOKED" ? (
                              <div className="single-search-form-box-1">
                                {props.domainView.data.domain &&
                                props.domainView.data.domain.auction_status !==
                                  2 ? (
                                  <div className="single-search-form-card">
                                    <h4>
                                      Highest bid:{" "}
                                      {props.domainView.data.highest_bid}
                                    </h4>
                                    <p>
                                      <Image
                                        className="red-dots-icon"
                                        src={
                                          window.location.origin +
                                          "/images/single-search/red-dots.svg"
                                        }
                                      />
                                      <span>
                                        Available balance :{" "}
                                        {props.domainView.data.wallet_balance}
                                      </span>
                                    </p>
                                    <div className="your-bid-form">
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          type="number"
                                          placeholder="Your Bid"
                                          value={bidAmount}
                                          onChange={(e) =>
                                            setBidAmount(e.target.value)
                                          }
                                        />
                                        <InputGroup.Text id="basic-addon2">
                                          {configuration.get(
                                            "configData.currency_code"
                                          )}
                                        </InputGroup.Text>
                                      </InputGroup>
                                      {/* <InputGroup className="mb-3">
																<Form.Control
																	placeholder="Your Blind (optional)"
																/>
																<InputGroup.Text id="basic-addon2">HNS</InputGroup.Text>
															</InputGroup> */}
                                      {/* <h5>Your total bid: {props.domainView.data.user_total_bid}</h5> */}
                                    </div>
                                    <div className="review-btn-sec">
                                      {localStorage.getItem("userId") &&
                                      localStorage.getItem("accessToken") ? (
                                        <Button
                                          className="view-all-btn"
                                          onClick={() => validateBid()}
                                          disabled={
                                            bidAmount <= 0 ? true : false
                                          }
                                        >
                                          Review & Place
                                        </Button>
                                      ) : (
                                        <Button
                                          className="view-all-btn"
                                          onClick={() => redirect()}
                                          disabled={
                                            bidAmount <= 0 ? true : false
                                          }
                                        >
                                          Review & Place
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="single-search-form-card">
                                    <h4>Auction Over</h4>
                                    <p>
                                      Sold For{" "}
                                      {props.domainView.data.highest_bid}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : null}
                            {props.domainView.data.domain_status ===
                              "REVEAL" && (
                              <div className="single-search-form-box-1">
                                <div className="single-search-form-card">
                                  <h3>Reveal period</h3>
                                  <p>
                                    Bidding is over - the winner will be
                                    determined at the end of reveal period.
                                  </p>
                                </div>
                              </div>
                            )}
                            {props.domainView.data.domain_status ===
                              "REVOKED" && (
                              <div className="single-search-form-box-1">
                                <div className="single-search-form-card">
                                  <h3>Revoked</h3>
                                  <p>
                                    Bidding is over - the winner will be
                                    determined at the end of reveal period.
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {props.bidsList.data.bids &&
                        props.bidsList.data.bids.length > 0 && (
                          <div className="bid-history-sec">
                            <h3>Bid History</h3>
                            <div className="bid-history-table-sec">
                              {/* <div className="custom-table-list">
                            <div className="custom-table-header">
                              <div className="custom-table-header-card">
                                <h5>Bid</h5>
                                <h5>Amount</h5>
                                <h5>Service Fee</h5>
                                <h5>Submitted</h5>
                                <h5></h5>
                              </div>
                            </div>
                            <InfiniteScroll
                              dataLength={props.bidsList.data.bids.length}
                              next={fetchMoreBids}
                              hasMore={props.bidsList.data.bids.length < props.bidsList.data.total}
                              loader={<CommonCenterLoader />}
                            >
                              <div className="custom-table-body">
                                {props.bidsList.data.bids.map((bid, i) =>
                                  <div className="custom-table-card" key={i}>
                                    <p>
                                      {i + 1}
                                      {props.profile.data.user_id === bid.user_id ? " (you)" : null}
                                    </p>
                                    <p>
                                      {bid.amount_formatted}
                                    </p>
                                    <p>
                                      {bid.mining_fee_formatted}
                                    </p>
                                    <p>
                                      {bid.submitted_at_formatted}
                                    </p>
                                    <p>
                                      {!bid.bid_transaction_hash ||
                                        bid.bid_transaction_hash === "" ?
                                        "-"
                                        :
                                        <CopyToClipboard text={bid.bid_transaction_hash}
                                          onCopy={() => onCopy()}>
                                          <span>{bid.bid_transaction_hash}</span>
                                        </CopyToClipboard>
                                      }
                                    </p>
                                  </div>
                                )}
                              </div>
                            </InfiniteScroll>
                          </div> */}
                              <div className="new-table-sec table-list-border">
                                <InfiniteScroll
                                  dataLength={props.bidsList.data.bids.length}
                                  next={fetchMoreBids}
                                  hasMore={
                                    props.bidsList.data.bids.length <
                                    props.bidsList.data.total
                                  }
                                  loader={<CommonCenterLoader />}
                                >
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    size="sm"
                                    responsive="md"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Bid</th>
                                        <th>Amount</th>
                                        <th>Service Fee</th>
                                        <th>Submitted</th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody className="custom-table-content">
                                      {props.bidsList.data.bids.map(
                                        (bid, i) => (
                                          <tr key={i}>
                                            <td>
                                              {i + 1}
                                              {props.profile.data.user_id ===
                                              bid.user_id
                                                ? " (you)"
                                                : null}
                                            </td>
                                            <td>{bid.amount_formatted}</td>
                                            <td>{bid.mining_fee_formatted}</td>
                                            <td>
                                              {bid.submitted_at_formatted}
                                            </td>
                                            <td>
                                              {!bid.bid_transaction_hash ||
                                              bid.bid_transaction_hash ===
                                                "" ? (
                                                "-"
                                              ) : (
                                                <CopyToClipboard
                                                  text={
                                                    bid.bid_transaction_hash
                                                  }
                                                  onCopy={() => onCopy()}
                                                >
                                                  <span>
                                                    {bid.bid_transaction_hash}
                                                  </span>
                                                </CopyToClipboard>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </InfiniteScroll>
                              </div>
                            </div>
                            {/* <div className="pagination-sec">
											<ReactPaginate
												breakLabel="..."
												nextLabel="next >"
												onPageChange={handlePageClick}
												pageRangeDisplayed={5}
												pageCount={page}
												previousLabel="< previous"
												renderOnZeroPageCount={null}
												containerClassName={"pagination"}
												subContainerClassName={"pages pagination"}
												activeClassName={"active"}
											/>
										</div> */}
                          </div>
                        )}
                      <div className="auction-details-sec">
                        <h3>Purchase Details</h3>
                        <div className="auction-details-box">
                          <div className="auction-details-card">
                            <Image
                              className="auction-details-img"
                              src={
                                // props.domainView.data.domain &&
                                //   props.domainView.data.domain.auction_status === 2 ?
                                //   window.location.origin + "/images/single-search/sold.png"
                                //   : window.location.origin + "/images/single-search/available.png"
                                props.domainView.data.domain_status ===
                                "BIDDING"
                                  ? window.location.origin +
                                    "/images/single-search/available.png"
                                  : props.domainView.data.domain_status ===
                                      "CLOSED" ||
                                    props.domainView.data.domain_status ===
                                      "TRANSFER"
                                  ? window.location.origin +
                                    "/images/single-search/sold.png"
                                  : props.domainView.data.domain_status ===
                                    "REVEAL"
                                  ? window.location.origin +
                                    "/images/single-search/reveal.png"
                                  : props.domainView.data.domain_status ===
                                    "REVOKED"
                                  ? window.location.origin +
                                    "/images/single-search/revoked.png"
                                  : window.location.origin +
                                    "/images/single-search/available.png"
                              }
                            />
                          </div>
                          <div className="auction-details-card">
                            <div className="auction-details-item">
                              <h4>Release Date</h4>
                              <p>
                                {props.domainView.data.domain &&
                                props.domainView.data.domain
                                  .release_at_formatted
                                  ? props.domainView.data.domain
                                      .release_at_formatted
                                  : new Date().toLocaleDateString()}
                              </p>
                            </div>
                            {/* <div className="auction-details-item">
													<h4>Last block to send bid</h4>
													<p>06/25/22 · Block # 125995</p>
												</div> */}
                            {/* <div className="auction-details-item">
													<h4>Current Block Height</h4>
													<p>Block # 125715</p>
												</div> */}
                            <div className="auction-details-item">
                              <h4>Purchase close date</h4>
                              <p>
                                {props.domainView.data.domain &&
                                props.domainView.data.domain
                                  .auction_end_formatted
                                  ? props.domainView.data.domain
                                      .auction_end_formatted
                                  : "n/a"}
                              </p>
                            </div>
                          </div>
                          <div className="auction-details-card">
                            <div className="auction-details-item">
                              <h4>Purchase start date</h4>
                              <p>
                                {props.domainView.data.domain &&
                                props.domainView.data.domain
                                  .auction_start_formatted
                                  ? props.domainView.data.domain
                                      .auction_start_formatted
                                  : "n/a"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="mt-5 text-center">
                      <h3>Reserved</h3>
                      <p>
                        To ensure that Handshake is compatible with legacy DNS,
                        existing top-level domains as well as the top 100,000
                        Alexa domains are reserved.
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            )
            // : <NoDataFound />
          }
        </Container>
      </div>
      {reviewYourBid ? (
        <ReviewYourBidModal
          reviewYourBid={reviewYourBid}
          closeReviewYourBidModal={closeReviewYourBidModal}
          setReviewYourBid={setReviewYourBid}
          bidAmount={bidAmount}
          placeBid={placeBid}
          createBid={props.createBid}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  domainView: state.bid.domainView,
  bidsList: state.bid.bidsList,
  createBid: state.bid.createBid,
  profile: state.users.profile,
  updateWatchlist: state.domain.updateWatchlist,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleSearchIndex);
