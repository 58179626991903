import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PurchaseModal from "./PurchaseModal";
import { connect } from "react-redux";

const MarketPlaceRow = (props) => {
  const [purchase, setPurchase] = useState(false);

  const closePurchaseModal = () => {
    setPurchase(false);
  };

  const { domain, i } = props;

  return (
    <>
      <tr key={i}>
        <td>{domain.domain_name}/</td>
        <td>{domain.amount_formatted}</td>
        <td className="table-btn">
          {domain.owner_id !== props.profile.data.user_id ? (
            <Button
              onClick={() => setPurchase(true)}
              className="custom-black-btn"
            >
              {purchase ? "Processing" : "Buy Now"}
            </Button>
          ) : (
            "-"
          )}
        </td>
      </tr>

      {purchase ? (
        <PurchaseModal
          purchase={purchase}
          closePurchaseModal={closePurchaseModal}
          domain={domain}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  buyDomainMarketPlaces: state.domain.buyDomainMarketPlaces,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MarketPlaceRow);
