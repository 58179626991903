import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";
import { addUserCardStart } from "../store/actions/WalletAction";
import { connect } from "react-redux";
import configuration from "react-global-configuration";


const CardSection = (props) => {
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const submitCard = async () => {
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });


    if (payload.error) {
      props.dispatch(getErrorNotificationMessage(payload.error))
    } else {
      console.log(payload);
      props.dispatch(addUserCardStart({
        card_token: payload.paymentMethod.id,
        card_holder_name: cardHolderName,
      }));
    }
  }

  return <Form className="add-card-form">
    <Form.Group className="mb-3">
      <Form.Label>Card Number</Form.Label>
      <CardNumberElement options={{ showIcon: true }} className="form-control" onChange={e => {
        // setError(e.error);
        setCardComplete(e.complete);
      }} />
    </Form.Group>
    <Row>
      <Col md={6} xl={6} lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>Expiry Date</Form.Label>
          {/* <Form.Control type="text" placeholder="MM/YY" /> */}
          <CardExpiryElement className="form-control" onChange={e => {
            // setError(e.error);
            setCardComplete(e.complete);
          }} />
        </Form.Group>
      </Col>
      <Col md={6} xl={6} lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>CVC/CVV</Form.Label>
          {/* <Form.Control type="number" placeholder="..." /> */}
          <CardCvcElement className="form-control" onChange={e => {
            // setError(e.error);
            setCardComplete(e.complete);
          }} />
        </Form.Group>
      </Col>
    </Row>
    <Form.Group className="mb-3">
      <Form.Label>Card Holders Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Ener Card Holders Full Name"
        value={cardHolderName}
        onChange={e => setCardHolderName(e.target.value)} />
    </Form.Group>
    <div className="add-card-btn-sec">
      <Button className="add-money-btn"
        disabled={!cardComplete || props.addUserCard.buttonDisable}
        onClick={() => submitCard()}>
        {props.addUserCard.loadingButtonContent ?
          props.addUserCard.loadingButtonContent
          : "Add Card"
        }
      </Button>
    </div>
  </Form>
}

const AddCardModal = (props) => {
  const stripePromise = loadStripe(configuration.get("configData.stripe_publishable_key"));

  return (
    <>
      <Modal
        className="modal-dialog-center add-card-modal"
        size="md"
        centered
        show={props.addCard}
        onHide={props.closeAddCardModal}
      >
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <h4>Add New Card</h4>
            <Button className="modal-close" onClick={() => props.closeAddCardModal()}>
              <Image
                className="close-icon"
                src={
                  window.location.origin + "/images/dashboard/modal-close.svg"
                }
              />
            </Button>
            <CardSection dispatch={props.dispatch} addUserCard={props.addUserCard} />
          </Elements>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  addUserCard: state.wallet.addUserCard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AddCardModal);