import React from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const FooterIndex = () => {
	return (
		<>
			<footer className="footer-sec">
				<Container>
					<Row>
						<Col md={12}>
							<div className="footer-header-sec">
								<Image
									className="footer-logo"
									src={
										window.location.origin + "/images/footer-logo.png"
									}
								/>
								<div className="footer-copyright-text">
									<p>{configuration.get("configData.copyright_content")}</p>
								</div>
							</div>
						</Col>
					</Row>
					<div className="footer-widget">
						<Row>
							<Col md={12} xl={5} lg={5}>
								<div className="footer-about-sec">
									<p> We offer fast and unlimited UK based Web Hosting, VPS, Dedicated, Reseller Hosting, Premium reseller hosting and bespoke hosting. So regardless of your needs and the size of your business, we have your hosting covered!</p>
									<p> We offer a full selection of popular and unique domains, along with fully featured hosting packages, SSL security certificates and WhoisGuard privacy protection. </p>
								</div>
								<div className="footer-social-link-sec">
									<ul className="list-unstyled footer-social-link">
										{configuration.get("configData.facebook_link") ? (
											<li>
												<a href={configuration.get("configData.facebook_link")} target="_blank">
													<Image
														className="footer-socail-icon"
														src={
															window.location.origin + "/images/facebook.svg"
														}
													/>
												</a>
											</li>
										) : null}
										{configuration.get("configData.twitter_link") ? (
											<li>
												<a href={configuration.get("configData.twitter_link")} target="_blank">
													<Image
														className="footer-socail-icon"
														src={
															window.location.origin + "/images/twitter.svg"
														}
													/>
												</a>
											</li>
										) : null}
										{configuration.get("configData.instagram_link") ? (
											<li>
												<a href={configuration.get("configData.instagram_link")} target="_blank">
													<Image
														className="footer-socail-icon"
														src={
															window.location.origin + "/images/instagram.svg"
														}
													/>
												</a>
											</li>
										) : null}
										{configuration.get("configData.pinterest_link") ? (
											<li>
												<a href={configuration.get("configData.pinterest_link")} target="_blank">
													<Image
														className="footer-socail-icon"
														src={
															window.location.origin + "/images/pinterest.svg"
														}
													/>
												</a>
											</li>
										) : null}
										{configuration.get("configData.linkedin_link") ? (
											<li>
												<a href={configuration.get("configData.linkedin_link")} target="_blank">
													<Image
														className="footer-socail-icon"
														src={
															window.location.origin + "/images/linkedin.svg"
														}
													/>
												</a>
											</li>
										) : null}
										{configuration.get("configData.youtube_link") ? (
											<li>
												<a href={configuration.get("configData.youtube_link")} target="_blank">
													<Image
														className="footer-socail-icon"
														src={
															window.location.origin + "/images/youtube.svg"
														}
													/>
												</a>
											</li>
										) : null}
									</ul>
								</div>
							</Col>
							<Col md={12} xl={3} lg={3}>
								<ul className="list-unstyled footer-link-sec">
									{configuration
										.get("configData.footer_pages1")
										.map((static_page, index) => (
											<li>
												<Link to={`/page/${static_page.static_page_unique_id}`}>
													<span>
														<Image
															className="footer-list-icon"
															src={
																window.location.origin + "/images/dot.svg"
															}
														/>
													</span>
													{static_page.title}
												</Link>
											</li>
										))}
								</ul>
							</Col>
							<Col md={12} xl={4} lg={4}>
								<div className="footer-address-sec">
									{configuration.get("configData.contact_address") ? (
										<div className="footer-address-card">
											<div className="footer-address-icon-sec">
												<Image
													className="footer-address-icon"
													src={
														window.location.origin + "/images/map-icon.svg"
													}
												/>
											</div>
											<div className="footer-address-info">
												{configuration.get("configData.contact_address")}
											</div>
										</div>
									) : null}
									{configuration.get("configData.contact_mobile") ? (
										<div className="footer-address-card">
											<div className="footer-address-icon-sec">
												<Image
													className="footer-address-icon"
													src={
														window.location.origin + "/images/phone-call.svg"
													}
												/>
											</div>
											<div className="footer-address-info">
												<p>Phone:{configuration.get("configData.contact_mobile")}</p>
											</div>
										</div>
									) : null}
									{configuration.get("configData.contact_email") ? (
										<div className="footer-address-card">
											<div className="footer-address-icon-sec">
												<Image
													className="footer-address-icon"
													src={
														window.location.origin + "/images/email-icon.svg"
													}
												/>
											</div>

											<div className="footer-address-info">
												<p>{configuration.get("configData.contact_email")}</p>
											</div>

										</div>
									) : null}
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</footer>
		</>
	);
};

export default FooterIndex;
