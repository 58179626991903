import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import { connect } from "react-redux";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../Helper/NoDataFound";
import {
  fetchMoreWatchlistListStart,
  fetchWatchlistListStart,
  updateWatchlistStart,
} from "../store/actions/DomainAction";

const WishlistIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchWatchlistListStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreDomains = () => {
    props.dispatch(
      fetchMoreWatchlistListStart({
        skip: props.watchlists.data.domains.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="domain-manager-sec">
        <div className="dashboard-sec">
          <DomainSearchSec />
          <div className="dashboard-body-sec">
            <div className="main-wrapper-box">
              <div className="mobile-sidebar-sec">
                <DashboardSidebar />
              </div>
              <div className="main-wrapper-right ">
                <div className="title-sec">
                  <h2>Your Watchlist</h2>
                  <p>
                    Good to See you here
                    <Image
                      className="wave-hand-icon"
                      src={
                        window.location.origin +
                        "/images/dashboard/waving-hand.png"
                      }
                    />
                  </p>
                </div>
                {/* <div className="domain-sort-sec">
                  <p>Sort By</p>
                  <Image
                    className="wave-hand-icon"
                    src={window.location.origin + "/images/filter.svg"}
                  />
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Newest
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <div className="domain-box">
                  <div className="your-active-auction-box">
                    <div className="your-active-auction-table-sec">
                      {props.watchlists.loading ? (
                        <CommonCenterLoader />
                      ) : Object.keys(props.watchlists.data).length > 0 &&
                        props.watchlists.data.domains.length > 0 ? (
                        // <div className="custom-table-list">
                        //   <div className="custom-table-header">
                        //     <div className="custom-table-header-card">
                        //       <h5>Domain</h5>
                        //       <h5>Total Watchlist</h5>
                        //       <h5>Release Date</h5>
                        //     </div>
                        //   </div>
                        //   <InfiniteScroll
                        //     dataLength={props.watchlists.data.domains.length}
                        //     next={fetchMoreDomains}
                        //     hasMore={props.watchlists.data.domains.length < props.watchlists.data.total}
                        //     loader={<CommonCenterLoader />}
                        //   >
                        //     <div className="custom-table-body">
                        //       {props.watchlists.data.domains.map((domain) => (
                        //         <div className="custom-table-card">
                        //           <p><Link to={`/domains/${domain.name}`}>{domain.name}/</Link></p>
                        //           <p>{domain.total_watching}</p>
                        //           <p>{domain.auction_start_formatted}</p>
                        //  <Link to={`/receipt/${domain.name}`} className="custom-black-btn">Manage</Link>
                        //           <Button onClick={() => {
                        //             props.dispatch(updateWatchlistStart({ domain: domain.name }));
                        //           }}
                        //             className="custom-black-btn"
                        //             disable={props.updateWatchlist.buttonDisable &&
                        //               props.updateWatchlist.data.domain === domain.name}
                        //           >
                        //             {props.updateWatchlist.loadinButtonContent &&
                        //               props.updateWatchlist.data.domain === domain.name ?
                        //               props.updateWatchlist.loadinButtonContent
                        //               : "Remove"
                        //             }
                        //           </Button>
                        //         </div>
                        //       ))
                        //       }
                        //     </div>
                        //   </InfiniteScroll>
                        // </div>

                        <div className="new-table-sec">
                          <InfiniteScroll
                            dataLength={props.watchlists.data.domains.length}
                            next={fetchMoreDomains}
                            hasMore={
                              props.watchlists.data.domains.length <
                              props.watchlists.data.total
                            }
                            loader={<CommonCenterLoader />}
                          >
                            <Table
                              striped
                              bordered
                              hover
                              size="sm"
                              responsive="md"
                            >
                              <thead>
                                <tr>
                                  <th>Domain</th>
                                  <th>Total Watchlist</th>
                                  <th>Release Date</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody className="custom-table-content">
                                {props.watchlists.data.domains.map((domain) => (
                                  <tr>
                                    <td>
                                      <Link to={`/domains/${domain.name}`}>
                                        {domain.name}/
                                      </Link>
                                    </td>
                                    <td>{domain.total_watching}</td>
                                    <td>{domain.auction_start_formatted}</td>
                                    <td className="table-btn">
                                      <Button
                                        onClick={() => {
                                          props.dispatch(
                                            updateWatchlistStart({
                                              domain: domain.name,
                                            })
                                          );
                                        }}
                                        className="custom-black-btn"
                                        disable={
                                          props.updateWatchlist.buttonDisable &&
                                          props.updateWatchlist.data.domain ===
                                            domain.name
                                        }
                                      >
                                        {props.updateWatchlist
                                          .loadinButtonContent &&
                                        props.updateWatchlist.data.domain ===
                                          domain.name
                                          ? props.updateWatchlist
                                              .loadinButtonContent
                                          : "Remove"}
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        </div>
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  watchlists: state.domain.watchlists,
  updateWatchlist: state.domain.updateWatchlist,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WishlistIndex);
