import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./About.css";

const AboutIndex = () => {
  return (
    <>
      <div className="about-us-sec">
        <div className="dashboard-header-sec">
          <InputGroup className="mb-0">
            <Form.Control placeholder="Search For Your Personal TLD" />
            <InputGroup.Text id="basic-addon2">
              <Image
                className="dashboard-icon"
                src={
                  window.location.origin +
                  "/images/single-search/search-icon.svg"
                }
              />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <div className="about-us-banner-sec">
            <Image
                className="about-us-banner-img"
                src={
                    window.location.origin +
                    "/images/about/about-banner-bg.png"
                }
            />
            <h2>About Us</h2>
        </div>
        <Container>
          <div className="about-us-box">
            <div className="about-us-title">
              <h2>Our Works</h2>
              <p>
                These Terms of Use (“TOU”) apply to your access and use of
                Momentive's products, services, websites, and apps that you
                purchase or sign up for on Momentive's websites and which are
                branded as “Momentive”, “SurveyMonkey”, “Wufoo” or “GetFeedback”
                (collectively the “Service(s)”). These TOU do not apply to
                Services which are available solely through our enterprise sales
                channel.
              </p>
              <p>
                Additional service-specific terms apply to some Services
                (“Service-Specific Terms”). Certain country-specific terms may
                also apply to you if you are located outside the United States
                (“Country-Specific Terms”). We refer to the Service-Specific
                Terms and Country-Specific Terms collectively as “Additional
                Terms” and the combination of these TOU and any applicable
                Additional Terms collectively as these “Terms.”
              </p>
              <p>
                You agree to these Terms by clicking to accept these Terms,
                executing a document that references them, or using the
                Services.
              </p>
              <p>
                If you will be using the Services on behalf of an organization,
                you agree to these Terms on behalf of that organization and you
                represent that you have the authority to do so. In such case,
                “you” and “your” will refer to that organization.
              </p>
            </div>
            <div className="about-us-title">
              <h2>Fees and Payments</h2>
              <p>
                These Terms of Use (“TOU”) apply to your access and use of
                Momentive's products, services, websites, and apps that you
                purchase or sign up for on Momentive's websites and which are
                branded as “Momentive”, “SurveyMonkey”, “Wufoo” or “GetFeedback”
                (collectively the “Service(s)”). These TOU do not apply to
                Services which are available solely through our enterprise sales
                channel.
              </p>
              <p>
                {" "}
                Additional service-specific terms apply to some Services
                (“Service-Specific Terms”). Certain country-specific terms may
                also apply to you if you are located outside the United States
                (“Country-Specific Terms”). We refer to the Service-Specific
                Terms and Country-Specific Terms collectively as “Additional
                Terms” and the combination of these TOU and any applicable
                Additional Terms collectively as these “Terms.”
              </p>
              <p>
                {" "}
                You agree to these Terms by clicking to accept these Terms,
                executing a document that references them, or using the
                Services.
              </p>
              <p>
                {" "}
                If you will be using the Services on behalf of an organization,
                you agree to these Terms on behalf of that organization and you
                represent that you have the authority to do so. In such case,
                “you” and “your” will refer to that organization.
              </p>
            </div>
            <div className="about-us-title">
              <h2>Fees and Payments</h2>
              <p>
                These Terms of Use (“TOU”) apply to your access and use of
                Momentive's products, services, websites, and apps that you
                purchase or sign up for on Momentive's websites and which are
                branded as “Momentive”, “SurveyMonkey”, “Wufoo” or “GetFeedback”
                (collectively the “Service(s)”). These TOU do not apply to
                Services which are available solely through our enterprise sales
                channel.
              </p>
              <p>
                {" "}
                Additional service-specific terms apply to some Services
                (“Service-Specific Terms”). Certain country-specific terms may
                also apply to you if you are located outside the United States
                (“Country-Specific Terms”). We refer to the Service-Specific
                Terms and Country-Specific Terms collectively as “Additional
                Terms” and the combination of these TOU and any applicable
                Additional Terms collectively as these “Terms.”
              </p>
              <p>
                {" "}
                You agree to these Terms by clicking to accept these Terms,
                executing a document that references them, or using the
                Services.
              </p>
              <p>
                {" "}
                If you will be using the Services on behalf of an organization,
                you agree to these Terms on behalf of that organization and you
                represent that you have the authority to do so. In such case,
                “you” and “your” will refer to that organization.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutIndex;
