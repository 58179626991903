import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import "./TransactionHistory.css";
import { connect } from "react-redux";
import { fetchBidsTransactionHistoryStart, fetchMoreBidsTransactionHistoryStart } from "../store/actions/BidAction";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";

const TransactionHistoryIndex = (props) => {

  useEffect(() => {
    props.dispatch(fetchBidsTransactionHistoryStart({
      skip: 0,
      take: 12,
    }))
  }, []);

  const fetchMoreTransactions = () => {
    props.dispatch(fetchMoreBidsTransactionHistoryStart({
      skip: props.transactionHistory.data.bid_notifications.length,
      take: 12,
    }))
  }

  return (
    <>
      <div className="transaction-history-sec">
        <div className="dashboard-sec">
          <DomainSearchSec />
          <div className="dashboard-body-sec">
            <div className="main-wrapper-box">
              <div className="mobile-sidebar-sec">
                <DashboardSidebar />
              </div>
              <div className="main-wrapper-right">
                <div className="title-sec">
                  <h2>Transaction History</h2>
                  <p>
                    Good to See you here
                    <Image
                      className="wave-hand-icon"
                      src={
                        window.location.origin +
                        "/images/dashboard/waving-hand.png"
                      }
                    />
                  </p>
                </div>
                <div className="transaction-activity-sec mt-4">
                  {props.transactionHistory.loading ?
                    <CommonCenterLoader /> :
                    Object.keys(props.transactionHistory.data).length > 0 &&
                      props.transactionHistory.data.bid_notifications.length > 0 ?
                      <>
                        <div className="transaction-activity-box">Activity</div>

                        <InfiniteScroll
                          dataLength={props.transactionHistory.data.bid_notifications.length}
                          next={fetchMoreTransactions}
                          hasMore={props.transactionHistory.data.bid_notifications.length < props.transactionHistory.data.total}
                          loader={<CommonCenterLoader />}
                        >
                          {props.transactionHistory.data.bid_notifications.map((notification) => (
                            <Link to={`/receipt/${notification.domain_name}`}>
                              <div className="transaction-history-card">
                                <h5>{notification.created_formatted}</h5>
                                <h5>
                                  {notification.domain_name} /<span>{notification.notification}</span>
                                </h5>
                                <h5>{notification.amount_formatted}</h5>
                              </div>
                            </Link>
                          ))}
                        </InfiniteScroll>
                      </> :
                      <NoDataFound />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  transactionHistory: state.bid.bidsTransactionHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(TransactionHistoryIndex);
