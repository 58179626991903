import {
  CREATE_BID_FAILURE,
  CREATE_BID_START,
  CREATE_BID_SUCCESS,
  FETCH_BIDS_LIST_FAILURE,
  FETCH_BIDS_LIST_START,
  FETCH_BIDS_LIST_SUCCESS,
  FETCH_BIDS_TRANSACTION_HISTORY_START,
  FETCH_BIDS_TRANSACTION_HISTORY_SUCCESS,
  FETCH_BIDS_TRANSACTION_HISTORY_FAILURE,
  UPDATE_BID_STATUS_FAILURE,
  UPDATE_BID_STATUS_START,
  UPDATE_BID_STATUS_SUCCESS,
  FETCH_BIDS_RECIEPT_START,
  FETCH_BIDS_RECIEPT_SUCCESS,
  FETCH_BIDS_RECIEPT_FAILURE,
  FETCH_OWNER_BIDS_LIST_START,
  FETCH_OWNER_BIDS_LIST_SUCCESS,
  FETCH_OWNER_BIDS_LIST_FAILURE,
  VIEW_DOMAIN_START,
  VIEW_DOMAIN_SUCCESS,
  VIEW_DOMAIN_FAILURE,
  FETCH_MORE_OWNER_BIDS_LIST_START,
  FETCH_MORE_BIDS_TRANSACTION_HISTORY_START,
  FETCH_MORE_BIDS_LIST_START,

} from "../actions/ActionConstant";


const initialState = {
  createBid: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  domainView: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  bidsList: {
    data: {
      bids: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateBidStatus: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  bidsTransactionHistory: {
    data: {
      bid_notifications: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  bidsReciept: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  ownerBidsList: {
    data: {
      bids: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const bidReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BID_START:
      return {
        ...state,
        createBid: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case CREATE_BID_SUCCESS:
      return {
        ...state,
        createBid: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATE_BID_FAILURE:
      return {
        ...state,
        createBid: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VIEW_DOMAIN_START:
      return {
        ...state,
        domainView: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
        bidsList: {
          data: {
            bids: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case VIEW_DOMAIN_SUCCESS:
      return {
        ...state,
        domainView: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        },
        // bidsList: {
        //   data: {
        //     bids: action.data.bids,
        //     total: action.data.total_bids,
        //   },
        //   loading: false,
        //   error: false,
        //   buttonDisable: false,
        //   loadingButtonContent: null
        // }
      };
    case VIEW_DOMAIN_FAILURE:
      return {
        ...state,
        domainView: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_BIDS_LIST_START:
      return {
        ...state,
        bidsList: {
          data: {
            bids: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_BIDS_LIST_START:
      return state;
    case FETCH_BIDS_LIST_SUCCESS:
      return {
        ...state,
        bidsList: {
          data: {
            bids: [...state.bidsList.data.bids, ...action.data.bids],
            total: action.data.total_bids,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_BIDS_LIST_FAILURE:
      return {
        ...state,
        bidsList: {
          data: state.bidsList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_BID_STATUS_START:
      return {
        ...state,
        updateBidStatus: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case UPDATE_BID_STATUS_SUCCESS:
      return {
        ...state,
        updateBidStatus: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_BID_STATUS_FAILURE:
      return {
        ...state,
        updateBidStatus: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_BIDS_TRANSACTION_HISTORY_START:
      return {
        ...state,
        bidsTransactionHistory: {
          data: {
            bid_notifications: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_BIDS_TRANSACTION_HISTORY_START:
      return state;
    case FETCH_BIDS_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        bidsTransactionHistory: {
          data: {
            bid_notifications: [...state.bidsTransactionHistory.data.bid_notifications, ...action.data.bid_notifications],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_BIDS_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        bidsTransactionHistory: {
          data: state.bidsTransactionHistory.data,
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_BIDS_RECIEPT_START:
      return {
        ...state,
        bidsReciept: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_BIDS_RECIEPT_SUCCESS:
      return {
        ...state,
        bidsReciept: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_BIDS_RECIEPT_FAILURE:
      return {
        ...state,
        bidsReciept: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_OWNER_BIDS_LIST_START:
      return {
        ...state,
        ownerBidsList: {
          data: {
            bids: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_OWNER_BIDS_LIST_START:
      return state;
    case FETCH_OWNER_BIDS_LIST_SUCCESS:
      return {
        ...state,
        ownerBidsList: {
          data: {
            bids: [...state.ownerBidsList.data.bids, ...action.data.bids],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_OWNER_BIDS_LIST_FAILURE:
      return {
        ...state,
        ownerBidsList: {
          data: state.ownerBidsList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    default:
      return state;
  }
}

export default bidReducer;