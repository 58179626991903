import React from "react";
import {
    Container,
    Row,
    Col,
    Image,
    InputGroup,
    Form,
    Button
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Support.css";

const SupportIndex = () => {
    return (
        <>
            <div className="support-sec">
                <div className="dashboard-header-sec">
                    <InputGroup className="mb-0">
                        <Form.Control placeholder="Search For Your Personal TLD" />
                        <InputGroup.Text id="basic-addon2">
                            <Image
                                className="dashboard-icon"
                                src={
                                    window.location.origin +
                                    "/images/single-search/search-icon.svg"
                                }
                            />
                        </InputGroup.Text>
                    </InputGroup>
                </div>
                <div className="support-box">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className="title-sec">
                                    <h2>Support</h2>
                                    <p>
                                        Reach out to us for any inquiry
                                    </p>
                                </div>
                                <div className="auth-form support-form">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control type="text" placeholder="Type Your Name" />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Your Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Your Message</Form.Label>
                                            <Form.Control as="textarea" rows={4} placeholder="Message" />
                                        </Form.Group>
                                        <div className="auth-btn-sec">
                                            <Button className="default-btn">
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                            <Col md={6}>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="support-contact-info">
                                    <div className="support-contact-box">
                                        <div className="support-contact-card">
                                            <div className="support-icon-sec">
                                                <Image
                                                    className="support-icon"
                                                    src={
                                                        window.location.origin + "/images/support/map.svg"
                                                    }
                                                />
                                            </div>
                                            <div className="support-info">
                                                <p>
                                                    121 King Street,Melbourne Victoria 3000 Australia..
                                                </p>
                                            </div>
                                        </div>
                                        <div className="support-contact-card">
                                            <div className="support-icon-sec">
                                                <Image
                                                    className="support-icon"
                                                    src={
                                                        window.location.origin + "/images/support/phone.svg"
                                                    }
                                                />
                                            </div>
                                            <div className="support-info">
                                                <p>Phone:(123) 145-454610</p>
                                                <p>Help:(123) 133-45655165</p>
                                            </div>
                                        </div>
                                        <div className="support-contact-card">
                                            <div className="support-icon-sec">
                                                <Image
                                                    className="support-icon"
                                                    src={
                                                        window.location.origin + "/images/support/email.svg"
                                                    }
                                                />
                                            </div>
                                            <div className="support-info">
                                                <p>Massive@Dynamics.com</p>
                                                <p>info@md.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="support-social-link-sec">
                                    <h4>Social Links</h4>
                                    <div className="support-social-link-sec-card">
                                        <ul className="list-unstyled support-social-link">
                                            <li>
                                                <Link to="#">
                                                    <Image
                                                        className="support-socail-icon"
                                                        src={
                                                            window.location.origin + "/images/support/facebook.svg"
                                                        }
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <Image
                                                        className="support-socail-icon"
                                                        src={
                                                            window.location.origin + "/images/support/twitter.svg"
                                                        }
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <Image
                                                        className="support-socail-icon"
                                                        src={
                                                            window.location.origin + "/images/support/instagram.svg"
                                                        }
                                                    />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default SupportIndex;
