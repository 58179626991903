import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Verification.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { twoStepAuthLoginStart, twoStepAuthResendCodeStart } from "../store/actions/UserAction";


const VerificationIndex = (props) => {
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleChange = (otp) => setOTP(otp);

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      let userId = localStorage.getItem("userId");
      let accessToken = localStorage.getItem("accessToken");
      let userLoginStatus = localStorage.getItem("userLoginStatus");
      let authentication = userId && accessToken && userLoginStatus == "true" ? true : false;
      if (authentication) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    }
  }, []);

  const verify = () => {
    props.dispatch(twoStepAuthLoginStart({
      email: email,
      code: otp,
    }));
  };

  const resend = () => {
    props.dispatch(twoStepAuthResendCodeStart({
      email: email,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.twoStepAuthLogin.loading && Object.keys(props.twoStepAuthLogin.data).length > 0) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    }
    setSkipRender(false);
  }, [props.twoStepAuthLogin]);

  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={5}>
              <Link to="/login">
                <div className="verification-logo-sec">
                  <Image
                    className="verification-logo"
                    src={
                      window.location.origin + "/images/logo.png"
                    }
                  />
                </div>
              </Link>
              <div className="verification-box">
                <h4>
                  Please enter the one time password to verify your account
                </h4>
                <p>We emailed a OTP to {email}
                </p>
                <div className="verification-item">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                  />
                </div>
                <div className="auth-btn-sec verification-btn-sec">
                  <Button
                    className="custom-black-btn"
                    onClick={() => verify()}
                    disabled={props.twoStepAuthLogin.buttonDisable}
                  >
                    {props.twoStepAuthLogin.loadingButtonContent ?
                      props.twoStepAuthLogin.loadingButtonContent
                      : "Validate"
                    }
                  </Button>
                  <Button
                    className="custom-black-outline-btn"
                    onClick={() => resend()}
                    disabled={props.twoStepAuthResedCode.buttonDisable}
                  >
                    {props.twoStepAuthResedCode.loadingButtonContent ?
                      props.twoStepAuthResedCode.loadingButtonContent
                      : "Resend"
                    }
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  twoStepAuthLogin: state.users.twoStepAuthLogin,
  twoStepAuthResedCode: state.users.twoStepAuthResedCode,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(VerificationIndex);