import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Button, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPasswordStart } from "../store/actions/UserAction";

const ResetPasswordIndex = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .test('len', 'password length should be minimum 6', val => val && val.length >= 6),
    password_confirmation: Yup.string().required("Required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  });

  const reset = (values) => {
    props.dispatch(resetPasswordStart({
      ...values,
      reset_token: params.token,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.resetPassword.loading && props.resetPassword.data.success) {
      navigate("/login");
    }
    setSkipRender(false);
  }, [props.resetPassword]);

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <div className="auth-left-sec">
            <div className="auth-card">
              <h2>Reset Password</h2>
              <p>Create your new password</p>
              <div className="auth-form">
                <Formik
                  initialValues={{
                    password: "",
                    password_confirmation: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => reset(values)}
                >
                  {({ errors, touched }) => (
                    <FORM>
                      <Form.Group className="form-group">
                        <Form.Label>New Password</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          placeholder="Enter Your New Password"
                          name="password"
                        />
                        <ErrorMessage component={"div"} name="password" className='text-danger text-right' />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Confirm Password</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          name="password_confirmation"
                        />
                        <ErrorMessage component={"div"} name="password_confirmation" className='text-danger text-right' />
                      </Form.Group>
                      <div className="auth-btn-sec">
                        <Button
                          type="submit"
                          className="default-btn"
                          disabled={props.resetPassword.buttonDisable}
                        >
                          {props.resetPassword.loadingButtonContent ?
                            props.resetPassword.loadingButtonContent
                            : "Submit"
                          }
                        </Button>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="auth-right-sec">
            <div className="auth-img-sec">
              <Image
                className="auth-img"
                src={
                  window.location.origin + "/images/login-img.png"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resetPassword: state.users.resetPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPasswordIndex);