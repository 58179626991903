import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";


const OurSalesReport = (props) => {

    return (
        <>
            <div className="our-sales-report-sec sm-padding">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <div className="section-title-sec">
                                <h6>SALES</h6>
                                <h2>
                                    OUR Sales Report
                                </h2>
                                <Image
                                    className="title-border-botom"
                                    src={
                                        window.location.origin + "/images/title-border-bottom.svg"
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="our-sales-report-box">
                                <div className="our-sales-report-card">
                                    <div className="our-sales-report-left-sec">
                                        <div className="our-sales-report-info-sec">
                                            <div className="our-sales-report-number-sec">
                                                1
                                            </div>
                                            <div className="our-sales-report-details">
                                                <h4>okx/</h4>
                                                <h4><span>Sold for</span> 21,134.02 {configuration.get("configData.currency_code")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="our-sales-report-right-sec">
                                        <h5><span>Gain</span> + 20,824%</h5>
                                        <p>Jun 24</p>
                                    </div>
                                </div>
                                <div className="our-sales-report-card">
                                    <div className="our-sales-report-left-sec">
                                        <div className="our-sales-report-info-sec">
                                            <div className="our-sales-report-number-sec">
                                                2
                                            </div>
                                            <div className="our-sales-report-details">
                                                <h4>okx/</h4>
                                                <h4><span>Sold for</span> 21,134.02 {configuration.get("configData.currency_code")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="our-sales-report-right-sec">
                                        <h5><span>Gain</span> + 20,824%</h5>
                                        <p>Jun 24</p>
                                    </div>
                                </div>
                                <div className="our-sales-report-card">
                                    <div className="our-sales-report-left-sec">
                                        <div className="our-sales-report-info-sec">
                                            <div className="our-sales-report-number-sec">
                                                3
                                            </div>
                                            <div className="our-sales-report-details">
                                                <h4>okx/</h4>
                                                <h4><span>Sold for</span> 21,134.02 {configuration.get("configData.currency_code")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="our-sales-report-right-sec">
                                        <h5><span>Gain</span> + 20,824%</h5>
                                        <p>Jun 24</p>
                                    </div>
                                </div>
                                <div className="our-sales-report-card">
                                    <div className="our-sales-report-left-sec">
                                        <div className="our-sales-report-info-sec">
                                            <div className="our-sales-report-number-sec">
                                                4
                                            </div>
                                            <div className="our-sales-report-details">
                                                <h4>okx/</h4>
                                                <h4><span>Sold for</span> 21,134.02 {configuration.get("configData.currency_code")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="our-sales-report-right-sec">
                                        <h5><span>Gain</span> + 20,824%</h5>
                                        <p>Jun 24</p>
                                    </div>
                                </div>
                                <div className="our-sales-report-card">
                                    <div className="our-sales-report-left-sec">
                                        <div className="our-sales-report-info-sec">
                                            <div className="our-sales-report-number-sec">
                                                5
                                            </div>
                                            <div className="our-sales-report-details">
                                                <h4>okx/</h4>
                                                <h4><span>Sold for</span> 21,134.02 {configuration.get("configData.currency_code")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="our-sales-report-right-sec">
                                        <h5><span>Gain</span> + 20,824%</h5>
                                        <p>Jun 24</p>
                                    </div>
                                </div>
                                <div className="our-sales-report-card">
                                    <div className="our-sales-report-left-sec">
                                        <div className="our-sales-report-info-sec">
                                            <div className="our-sales-report-number-sec">
                                                6
                                            </div>
                                            <div className="our-sales-report-details">
                                                <h4>okx/</h4>
                                                <h4><span>Sold for</span> 21,134.02 {configuration.get("configData.currency_code")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="our-sales-report-right-sec">
                                        <h5><span>Gain</span> + 20,824%</h5>
                                        <p>Jun 24</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OurSalesReport;
