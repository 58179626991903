import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {Link} from "react-router-dom";



const OurCustomers = (props) => {


  return (
    <>
        <div className="our-customers-sec sm-padding">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <div className="section-title-sec">
                            <h6>CUSTOMERS</h6>
                            <h2>
                                Our Customers
                            </h2>
                            <Image
                                className="title-border-botom"
                                src={
                                    window.location.origin + "/images/title-border-bottom.svg"
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="client-logo-box">
                                <div>
                                    <Image
                                        className="client-logo-img"
                                        src={
                                            window.location.origin + "/images/client-1.png"
                                        }
                                    />
                                </div>
                                <div>
                                <Image
                                        className="client-logo-img"
                                        src={
                                            window.location.origin + "/images/client-2.png"
                                        }
                                    />
                                </div>
                                <div>
                                <Image
                                        className="client-logo-img"
                                        src={
                                            window.location.origin + "/images/client-3.png"
                                        }
                                    />
                                </div>
                                <div>
                                <Image
                                        className="client-logo-img"
                                        src={
                                            window.location.origin + "/images/client-4.png"
                                        }
                                    />
                                </div>
                                <div>
                                <Image
                                        className="client-logo-img"
                                        src={
                                            window.location.origin + "/images/client-5.png"
                                        }
                                    />
                                </div>
                                <div>
                                <Image
                                        className="client-logo-img"
                                        src={
                                            window.location.origin + "/images/client-6.png"
                                        }
                                    />
                                </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
  );
};

export default OurCustomers;
