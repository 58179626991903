import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDashboardStart } from "../store/actions/UserAction";
import DomainSearchSec from "./DomainSearchSec";
import DashboardSidebar from "./DashboardSidebar";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";

const DashBoardIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchDashboardStart());
  }, []);

  return (
    <>
      <div className="dashboard-sec">
        <DomainSearchSec />
        <div className="dashboard-body-sec">
          <div className="main-wrapper-box">
            {props.dashboard.loading ? (
              "Loading"
            ) : Object.keys(props.dashboard.data).length > 0 ? (
              <div className="main-wrapper-right mobile-visible-right-sec">
                <div className="title-sec">
                  <h2>Dashboard</h2>
                  <p>
                    Good to See you here
                    <Image
                      className="wave-hand-icon"
                      src={
                        window.location.origin +
                        "/images/dashboard/waving-hand.png"
                      }
                    />
                  </p>
                </div>
                <div className="dasboard-hns-box">
                  <div className="dashboard-hns-card">
                    <div className="dashboard-hns-header-sec">
                      <div className="dashboard-hns-info">
                        <h3>USD</h3>
                        <h6>Available balance</h6>
                        <h5>{props.dashboard.data.wallet_balance}</h5>
                        {/* <p>($0.63 USD)</p> */}
                      </div>
                      <div className="dashboard-hns-icon-sec">
                        <Image
                          className="dashboard-hns-icon"
                          src={
                            window.location.origin +
                            "/images/dashboard/dollar.svg"
                          }
                        />
                      </div>
                    </div>
                    <div className="dashboard-hns-btn-sec">
                      {/* <Link to="/add-money" className="hns-btn">
                          Add Money
                        </Link>
                        <Link to="/withdraw-money" className="hns-outline-btn">
                          Withdraw
                        </Link> */}
                      <Link to="#" className="hns-outline-btn">
                        Wallet Address
                      </Link>
                    </div>
                  </div>
                  <div className="dashboard-lockedup-card">
                    <div className="dashboard-lockedup-header-sec">
                      <div className="dashboard-lockedup-info">
                        <h6>Bids</h6>
                        <h5>
                          {props.dashboard.data.user_total_bid} across{" "}
                          {props.dashboard.data.total_bids} bids
                        </h5>
                      </div>
                      <div className="dashboard-lockedup-icon-sec">
                        <Image
                          className="dashboard-lockedup-icon"
                          src={
                            window.location.origin +
                            "/images/dashboard/locked-up.svg"
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="progress-bar-sec">
                        <p> <strong>Bids:</strong> 25.00 HNS</p>
                        <ProgressBar now={60} />
                      </div> */}
                  </div>
                </div>
                {/* <div className="dashboard-total-balance-box">
                    <div className="dashboard-total-balance-left">
                      <h6>Total Balance</h6>
                      <Link to="#" className="hns-outline-btn">
                        Wallet Address
                      </Link>
                    </div>
                    <div className="dashboard-total-balance-right">
                      <h5>{props.dashboard.data.wallet_balance}</h5>
                      <p>($0.63 USD)</p>
                      <small>There are restrictions on your cloud wallet until you verify</small>
                    </div>
                  </div> */}
                <div className="your-active-auction-box">
                  <div className="your-active-auction-header">
                    <div className="your-active-auction-info">
                      <h5>Your active auctions</h5>
                      {/* <p>You are the highest lockup for 3 of your 3 auctions</p> */}
                    </div>
                    <div className="your-active-auction-icon-sec">
                      <Image
                        className="your-active-auction-icon"
                        src={
                          window.location.origin +
                          "/images/dashboard/auction-icon.svg"
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="your-active-auction-table-sec">
                    <div className="custom-table-list">
                      <div className="custom-table-header">
                        <div className="custom-table-header-card">
                          <h5>Name</h5>
                          <h5>Your bid</h5>
                          <h5>Purchase end date</h5>
                        </div>
                      </div>
                      <div className="custom-table-body">
                        {props.dashboard.data.bids &&
                        props.dashboard.data.bids.length > 0 ? (
                          props.dashboard.data.bids.map((bid, i) => (
                            <div className="custom-table-card" key={i}>
                              <p>{bid.domain_name}/</p>
                              <p>{bid.total_formatted}</p>
                              <p>
                                <Image
                                  className="time-icon"
                                  src={
                                    window.location.origin +
                                    "/images/dashboard/time-icon.svg"
                                  }
                                />
                                {bid.auction_end_formatted}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <img
                              src={
                                window.location.origin +
                                "/images/no-data-found.png"
                              }
                              width="300"
                              height="300"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="new-table-sec">
                    {props.dashboard.loading ? (
                      <CommonCenterLoader />
                    ) : props.dashboard.data.bids &&
                      props.dashboard.data.bids.length > 0 ? (
                      <Table striped bordered hover size="sm" responsive="md">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Your bid</th>
                            <th>Purchase end date</th>
                          </tr>
                        </thead>

                        <tbody className="custom-table-content">
                          {props.dashboard.data.bids.map((bid, i) => (
                            <tr key={i}>
                              <td>
                                <Link to={`/domains/${bid.domain_name}`}>
                                  {bid.domain_name}/
                                </Link>
                              </td>
                              <td>{bid.total_formatted}</td>
                              <td>
                                <Image
                                  className="time-icon"
                                  src={
                                    window.location.origin +
                                    "/images/dashboard/time-icon.svg"
                                  }
                                />
                                {bid.auction_end_formatted}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  dashboard: state.users.dashboard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DashBoardIndex);
