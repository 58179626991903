import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { forgotPasswordStart } from "../store/actions/UserAction";

const ForgotPasswordModal = (props) => {

	const [step, setStep] = useState(1);

	const [skipRender, setSkipRender] = useState(true);

	const [email, setEmail] = useState("");

	useEffect(() => {
		if (!skipRender && 
			!props.sendForgotPassword.loading && 
			Object.keys(props.sendForgotPassword.data).length > 0 ) {
				console.log("Success")
				setStep(2)
		}
		setSkipRender(false);
	}, [props.sendForgotPassword]);

	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Invalid email").required("Required"),
	});

	const handleForgotPassword = (values) => {
		setEmail(values.email);
		props.dispatch(forgotPasswordStart(values));
	}

	return (
		<>
			<Modal
				className="modal-dialog-center add-card-modal"
				size="md"
				centered
				show={props.forgotPassword}
				onHide={props.closeForgotPasswordModal}
			>
				<Modal.Body>
					<h4>Forgot Password</h4>
					<Button className="modal-close" onClick={() => props.closeForgotPasswordModal()}>
						<Image
							className="close-icon"
							src={
								window.location.origin + "/images/dashboard/modal-close.svg"
							}
						/>
					</Button>
					{step == 1 && (
						<Formik
							initialValues={{
								email: "",
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => handleForgotPassword(values)}
						>
							{({ errors, touched }) => (
								<FORM className="add-card-form">
									<Form.Group className="mb-3">
										<Form.Label>Email Address</Form.Label>
										<Field
											type="email"
											className="form-control"
											name="email"
											placeholder="Enter Your Email Address..."
										/>
										{errors.email && touched.email && (
											<div className="text-danger">{errors.email}</div>
										)}
									</Form.Group>
									<div className="add-card-btn-sec">
										<Button
											type="submit"
											className="add-money-btn"
											disabled={props.sendForgotPassword.buttonDisable}
										>
											{props.sendForgotPassword.loadingButtonContent
												? props.sendForgotPassword.loadingButtonContent
												: "Submit"}
										</Button>
									</div>
								</FORM>
							)}
						</Formik>
					)}
					{step == 2 && (
						<div className="check-your-mail-card">
							<div className="check-your-mail-icon-sec">
								<Image
									className="wave-hand-icon"
									src={
										window.location.origin + "/images/email-icon.png"
									}
								/>
							</div>
							<h5>Chek Your Mail</h5>
							<p>We emailed a reset password link to <span>{email}</span> click the link to reset your password</p>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	sendForgotPassword: state.users.forgotPassword,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPasswordModal);