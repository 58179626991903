import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Image, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchMoreOwnerBidsListStart,
  fetchOwnerBidsListStart,
} from "../store/actions/BidAction";
import NoDataFound from "../Helper/NoDataFound";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

const YourBidsList = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(
      fetchOwnerBidsListStart({
        type: props.activeTab,
        skip: 0,
        take: 12,
      })
    );
  }, [props.activeTab]);

  const fetchMoreBidsList = () => {
    props.dispatch(
      fetchMoreOwnerBidsListStart({
        type: props.activeTab,
        skip: props.ownerBidsList.data.bids.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="open-bids-table-sec">
        {props.ownerBidsList.loading ? (
          <CommonCenterLoader />
        ) : props.ownerBidsList.data.bids &&
          props.ownerBidsList.data.bids.length > 0 ? (
          // <div className="custom-table-list">
          //   <div className="custom-table-header">
          //     <div className="custom-table-header-card">
          //       <h5>Domains</h5>
          //       <h5>Bid started</h5>
          //       <h5>Your bid</h5>
          //       {props.activeTab === "lost" ? (
          //         <h5>Highest bid</h5>
          //       ) : (
          //         <h5>Purchase end date</h5>
          //       )}
          //       <h5></h5>
          //     </div>
          //   </div>
          //   <InfiniteScroll
          //     dataLength={props.ownerBidsList.data.bids.length}
          //     next={fetchMoreBidsList}
          //     hasMore={
          //       props.ownerBidsList.data.bids.length <
          //       props.ownerBidsList.data.total
          //     }
          //     loader={<CommonCenterLoader />}
          //   >
          //     <div className="custom-table-body">
          //       {props.ownerBidsList.data.bids.map((bid, i) => (
          //         <div className="custom-table-card" key={i}>
          //           <p>
          //             <Link to={`/domains/${bid.domain_name}`}>
          //               {bid.domain_name}/
          //             </Link>
          //           </p>
          //           <p>{bid.auction_start_formatted}</p>
          //           <p>{bid.amount_formatted}</p>
          //           {props.activeTab === "lost" ? (
          //             <p>{bid.highest_bid_amount}</p>
          //           ) : (
          //             <p>
          //               <Image
          //                 className="time-icon"
          //                 src={
          //                   window.location.origin +
          //                   "/images/dashboard/time-icon.svg"
          //                 }
          //               />
          //               {bid.auction_end_formatted}
          //             </p>
          //           )}
          //           <Button
          //             onClick={() => navigate(`/receipt/${bid.domain_name}`)}
          //             className="custom-black-btn"
          //           >
          //             Receipt
          //           </Button>
          //         </div>
          //       ))}
          //     </div>
          //   </InfiniteScroll>
          // </div>

          <div className="new-table-sec">
            <InfiniteScroll
              dataLength={props.ownerBidsList.data.bids.length}
              next={fetchMoreBidsList}
              hasMore={
                props.ownerBidsList.data.bids.length <
                props.ownerBidsList.data.total
              }
              loader={<CommonCenterLoader />}
            >
              <Table striped bordered hover size="sm" responsive="md">
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Bid started</th>
                    <th>Your bid</th>
                    {props.activeTab === "lost" ? (
                      <th>Highest bid</th>
                    ) : (
                      <th>Purchase end date</th>
                    )}
                    <th></th>
                  </tr>
                </thead>

                <tbody className="custom-table-content">
                  {props.ownerBidsList.data.bids.map((bid, i) => (
                    <tr key={i}>
                      <td>
                        {" "}
                        <Link to={`/domains/${bid.domain_name}`}>
                          {bid.domain_name}/
                        </Link>
                      </td>
                      <td>{bid.auction_start_formatted}</td>
                      <td>{bid.amount_formatted}</td>

                      {props.activeTab === "lost" ? (
                        <td>{bid.highest_bid_amount}</td>
                      ) : (
                        <td>
                          <Image
                            className="time-icon"
                            src={
                              window.location.origin +
                              "/images/dashboard/time-icon.svg"
                            }
                          />
                          {bid.auction_end_formatted}
                        </td>
                      )}
                      <td className="table-btn">
                        <Button
                          onClick={() =>
                            navigate(`/receipt/${bid.domain_name}`)
                          }
                          className="custom-black-btn"
                        >
                          Receipt
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  ownerBidsList: state.bid.ownerBidsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(YourBidsList);
