import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";

const EmptyLayout = (props) => {

  return (
    <>
      <HeaderIndex/>
      <ToastContainer />
        <div className="main-wrapper">
          {props.children}
        </div>
      <FooterIndex/>
    </>
  );
};

export default EmptyLayout;
