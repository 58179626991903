import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Wallet.css";
import { connect } from "react-redux";
import {
  fetchMoreWithdrawlRequestStart,
  fetchWithdrawlRequestsStart,
} from "../store/actions/WalletAction";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import NoDataFound from "../Helper/NoDataFound";
import WalletWithdrawTableRow from "./WalletWithdrawTableRow";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const WalletWithdrawIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchWithdrawlRequestsStart({
        skip: 0,
        take: 0,
      })
    );
  }, []);

  const fetchMoreWithdraw = () => {
    props.dispatch(
      fetchMoreWithdrawlRequestStart({
        skip: props.withdrawRequestList.data.user_withdrawals.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="dashboard-sec">
        <DomainSearchSec />
        <div className="dashboard-body-sec">
          <div className="main-wrapper-box">
            <div className="mobile-sidebar-sec">
              <DashboardSidebar />
            </div>
            <div className="main-wrapper-right">
              <div className="title-sec">
                <h2>Withdraw</h2>
                <p>
                  Good to See you here
                  <Image
                    className="wave-hand-icon"
                    src={
                      window.location.origin +
                      "/images/dashboard/waving-hand.png"
                    }
                  />
                </p>
              </div>
              <div className="wallet-balance-box">
                <div className="account-balance-details">
                  <h4>
                    {!props.withdrawRequestList.loading &&
                    props.withdrawRequestList.data.user_wallet
                      ? props.withdrawRequestList.data.user_wallet
                          .remaining_formatted
                      : "0.00 USD"}
                  </h4>
                  <p>Current wallet balance</p>
                </div>
                <div className="wallet-balance-btn">
                  {/* <Link to="/add-money" className="wallet-btn-outline">
                    <Image
                      src={window.location.origin + "/images/wallet/wallet.png"}
                    />
                    Add Money
                  </Link> */}
                  <Link to="/withdraw-money" className="wallet-btn">
                    <Image
                      src={
                        window.location.origin + "/images/wallet/withdraw.png"
                      }
                    />
                    Withdraw
                  </Link>
                </div>
              </div>
              {/* {props.userWalletDetails.loading ? (
                'Loading'
              ) :
                props.userWalletDetails.data.user_wallet_payments &&
                  props.userWalletDetails.data.user_wallet_payments.length > 0 ?
                  props.userWalletDetails.data.user_wallet_payments.map((wallet_payment) => {
                    <> */}
              <div className="wallet-transaction-sec wallet-withdraw-sec">
                <div className="title-sec">
                  <h2>Withdraw History</h2>
                </div>
                <div className="your-active-auction-box">
                  <div className="your-active-auction-table-sec">
                    {props.withdrawRequestList.loading ? (
                      <CommonCenterLoader />
                    ) : props.withdrawRequestList.data.user_withdrawals &&
                      props.withdrawRequestList.data.user_withdrawals.length >
                        0 ? (
                      // <div className="custom-table-list wallet-withdraw-table-list">
                      //   <div className="custom-table-header">
                      //     <div className="custom-table-header-card">
                      //       <h5>Date</h5>
                      //       <h5>Trasaction ID</h5>
                      //       <h5>Account</h5>
                      //       <h5>Requested</h5>
                      //       <h5>Paid</h5>
                      //       {/* <h5>Deposit</h5> */}
                      //       <h5>Status</h5>
                      //       <h5>Action</h5>
                      //     </div>
                      //   </div>
                      //   <InfiniteScroll
                      //     dataLength={props.withdrawRequestList.data.user_withdrawals.length}
                      //     next={fetchMoreWithdraw}
                      //     hasMore={props.withdrawRequestList.data.user_withdrawals.length < props.withdrawRequestList.data.total}
                      //     loader={<CommonCenterLoader />}
                      //   >
                      //     <div className="custom-table-body">
                      //       {props.withdrawRequestList.data.user_withdrawals.map((withdraw, i) =>
                      //         <WalletWithdrawTableRow withdraw={withdraw} ky={i} />
                      //       )}
                      //     </div>
                      //   </InfiniteScroll>
                      // </div>

                      <div className="new-table-sec wallet-history-table-header">
                        <InfiniteScroll
                          dataLength={
                            props.withdrawRequestList.data.user_withdrawals
                              .length
                          }
                          next={fetchMoreWithdraw}
                          hasMore={
                            props.withdrawRequestList.data.user_withdrawals
                              .length < props.withdrawRequestList.data.total
                          }
                          loader={<CommonCenterLoader />}
                        >
                          <Table
                            striped
                            bordered
                            hover
                            size="sm"
                            responsive="md"
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Transaction ID</th>
                                <th>Account</th>
                                <th>Requested</th>
                                <th>Paid</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody className="custom-table-content">
                              {props.withdrawRequestList.data.user_withdrawals.map(
                                (withdraw, i) => (
                                  <WalletWithdrawTableRow
                                    withdraw={withdraw}
                                    ky={i}
                                  />
                                )
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      </div>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
              {/* </>
                  }) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  withdrawRequestList: state.wallet.withdrawRequestList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletWithdrawIndex);
