import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, InputGroup, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddCardModal from "./AddCardModal";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import { connect } from "react-redux";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import { fetchUserCardListStart } from "../store/actions/WalletAction";
import { walletPaymentByStripeStart } from "../store/actions/WalletAction";
import SingleCard from "./SingleCard";
import { useNavigate } from "react-router-dom";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";

const AddMoneyIndex = (props) => {
  const navigate = useNavigate();

  const [addCard, setAddCard] = useState(false);
  const [skipFirst, setSkipFirst] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [amount, setAmount] = useState("");

  const closeAddCardModal = () => {
    setAddCard(false);
  };

  useEffect(() => {
    props.dispatch(fetchUserCardListStart());
  }, []);

  useEffect(() => {
    if (!skipFirst && !props.userCardList.loading && Object.keys(props.userCardList.data).length > 0) {
      if (props.userCardList.data.user_cards && props.userCardList.data.user_cards.length > 0) {
        props.userCardList.data.user_cards.map((card, i) => {
          if (card.is_default === 1)
            setSelectedCard(card.user_card_id);
        })
      } else {
        setSelectedCard(null);
      }
    }
  }, [props.userCardList]);

  useEffect(() => {
    if (!skipFirst && !props.addUserCard.loading && Object.keys(props.addUserCard.data).length > 0) {
      closeAddCardModal();
      props.dispatch(fetchUserCardListStart());
    }
  }, [props.addUserCard]);

  const addMoney = e => {
    e.preventDefault();
    if (amount && selectedCard) {
      props.dispatch(walletPaymentByStripeStart({
        amount: amount,
        card_id: selectedCard,
      }));
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedCard) {
      props.dispatch(getErrorNotificationMessage("Please add or select a card"));
    }
  }

  useEffect(() => {
    if (!skipFirst && !props.walletPaymentByStripe.loading && Object.keys(props.walletPaymentByStripe.data).length > 0) {
      navigate("/wallet");
    }
    setSkipFirst(false);
  }, [props.walletPaymentByStripe]);

  return (
    <>
      <div className="add-money-sec">
        <DomainSearchSec />
        <div className="main-wrapper-box">
          <div className="mobile-sidebar-sec">
            <DashboardSidebar />
          </div>
          <div className="main-wrapper-right">
            <div className="title-sec">
              <h2>Add Money</h2>
            </div>
            <div className="add-money-header-sec">
              <div className="add-money-form">
                <InputGroup className="mb-0">
                  <Form.Control
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">$</InputGroup.Text>
                </InputGroup>
                <Button
                  type="submit"
                  className="add-money-btn"
                  onClick={addMoney}
                  disabled={props.walletPaymentByStripe.buttonDisable}
                >
                  {props.walletPaymentByStripe.loadingButtonContent ?
                    props.walletPaymentByStripe.loadingButtonContent
                    : "Add Money"
                  }
                </Button>
              </div>
            </div>
            <div className="select-card-sec">
              <h4>Select Card</h4>
              <div className="select-card-box">
                {props.userCardList.loading ?
                  <CommonCenterLoader />
                  :
                  <>{props.userCardList.data.user_cards &&
                    props.userCardList.data.user_cards.map((card, i) =>
                      <SingleCard
                        card={card}
                        key={i}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                      />
                    )}
                    <div className="add-card">
                      <Link to="#" onClick={() => setAddCard(true)}>
                        <div className="select-item">
                          <div className="select-item-icon-sec">
                            <Image
                              className="add-icon"
                              src={
                                window.location.origin + "/images/dashboard/plus-add.svg"
                              }
                            />
                          </div>
                          <div className="select-item-info">
                            <h6>Add Card</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCard ?
        <AddCardModal
          addCard={addCard}
          closeAddCardModal={closeAddCardModal}
          setAddCard={setAddCard}
        />
        : null}

    </>
  );
};

const mapStateToPros = (state) => ({
  addUserCard: state.wallet.addUserCard,
  userCardList: state.wallet.userCardList,
  walletPaymentByStripe: state.wallet.walletPaymentByStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AddMoneyIndex);