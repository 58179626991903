import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import "./YourBids.css";
import YourBidsList from "./YourBidsList";

const YourBidsIndex = (props) => {
  const [activeTab, setActiveTab] = useState("open-bids");

  return (
    <>
      <div className="your-bids-sec">
        <DomainSearchSec />
        <div className="main-wrapper-box">
          <div className="mobile-sidebar-sec">
            <DashboardSidebar />
          </div>
          <div className="main-wrapper-right">
            <div className="title-sec">
              <h2>Your bids</h2>
              <p>
                Good to See you here
                <Image
                  className="wave-hand-icon"
                  src={
                    window.location.origin + "/images/dashboard/waving-hand.png"
                  }
                />
              </p>
            </div>
            <div className="your-bids-tab-sec">
              <Tab.Container
                id="left-tabs-example"
              >
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => setActiveTab("open-bids")}
                          className={activeTab === "open-bids" ? "active" : ""}
                        >Open bids</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link
                          onClick={() => setActiveTab("in-reveal")}
                          className={activeTab === "in-reveal" ? "active" : ""}
                        >In reveal</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => setActiveTab("lost")}
                          className={activeTab === "lost" ? "active" : ""}
                        >Lost</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <YourBidsList
                      activeTab={activeTab}
                    />
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourBidsIndex;
