import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { useParams } from "react-router-dom";
import "./Receipt.css";
import { connect } from "react-redux";
import { fetchBidsRecieptStart } from "../store/actions/BidAction";

const ReceiptIndex = (props) => {

  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchBidsRecieptStart({ domain: params.domain }))
  }, [])
  return (
    <>
      <div className="profile-receipt-sec">
        <Container>
          <div className="receipt-sec">
            <div className="receipt-profile-title">
              <h2>{params.domain}/</h2>
            </div>
            {props.bidsReciept.loading ?
              "Loading" : (
                Object.keys(props.bidsReciept.data).length > 0 &&
                  props.bidsReciept.data.bids.length > 0 ?
                  props.bidsReciept.data.bids.map((receipt, index) => (
                    <div className="bill-receipt">
                      <div className="receipt-details">
                        <h4>Receipt #{index + 1} - Bid</h4>
                        <h4>{receipt.submitted_at_formatted}</h4>
                      </div>
                      <div className="receipt-details receipt-amount-details mt-3">
                        <h5>Bid Amount</h5>
                        <h5>{receipt.amount_formatted}</h5>
                      </div>
                      <div className="receipt-details receipt-amount-details">
                        <h5>Mining Fee</h5>
                        <h5>{receipt.mining_fee_formatted}</h5>
                      </div>
                      <div className="receipt-details receipt-amount-details total-amount">
                        <h5>Total</h5>
                        <h5>{receipt.total_formatted}</h5>
                      </div>
                      <div className="transaction-details">
                        <div className="transaction-receipt">
                          <h5>Bid Tx Hash</h5>
                          <h5>{receipt.bid_transaction_hash ? receipt.bid_transaction_hash : " [Pending]"}</h5>
                        </div>
                        <div className="transaction-receipt">
                          <h5>Reveal Tx Hash</h5>
                          <h5>{receipt.reveal_transaction_hash ? receipt.reveal_transaction_hash : " [Pending]"}</h5>
                        </div>
                        <div className="transaction-receipt">
                          <h5>Redeem Tx Hash</h5>
                          <h5>{receipt.redeem_transaction_hash ? receipt.redeem_transaction_hash : " [Pending]"}</h5>
                        </div>
                        <div className="transaction-receipt">
                          <h5>Register Tx Hash</h5>
                          <h5>{receipt.register_transaction_hash ? receipt.register_transaction_hash : " [Pending]"}</h5>
                        </div>
                      </div>
                    </div>
                  ))
                  :
                  <div className="text-center">
                    <img src={window.location.origin + "/images/no-data-found.png"} width="300" height="300" />
                  </div>
              )}

          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bidsReciept: state.bid.bidsReciept,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ReceiptIndex);
