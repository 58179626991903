import {
  FETCH_DOMAINS_FOR_OWNER_FAILURE,
  FETCH_DOMAINS_FOR_OWNER_START,
  FETCH_DOMAINS_FOR_OWNER_SUCCESS,
  FETCH_DOMAIN_MARKET_PLACES_FAILURE,
  FETCH_DOMAIN_MARKET_PLACES_START,
  FETCH_DOMAIN_MARKET_PLACES_SUCCESS,
  FETCH_MORE_DOMAINS_FOR_OWNER_START,
  FETCH_MORE_WATCHLIST_LIST_START,
  FETCH_RESET_WATCHLIST_LIST_SUCCESS,
  FETCH_WATCHLIST_LIST_FAILURE,
  FETCH_WATCHLIST_LIST_START,
  FETCH_WATCHLIST_LIST_SUCCESS,
  SELL_DOMAIN_MARKET_PLACES_START,
  SELL_DOMAIN_MARKET_PLACES_SUCCESS,
  SELL_DOMAIN_MARKET_PLACES_FAILURE,
  UPDATE_WATCHLIST_FAILURE,
  UPDATE_WATCHLIST_START,
  UPDATE_WATCHLIST_SUCCESS,
  BUY_DOMAIN_MARKET_PLACES_START,
  BUY_DOMAIN_MARKET_PLACES_SUCCESS,
  BUY_DOMAIN_MARKET_PLACES_FAILURE,
  DELETE_DOMAIN_MARKET_PLACES_START,
  DELETE_DOMAIN_MARKET_PLACES_SUCCESS,
  DELETE_DOMAIN_MARKET_PLACES_FAILURE,
  FETCH_MORE_DOMAIN_MARKET_PLACES_START,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_SUCCESS,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_FAILURE,
  FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
  DOMAINS_FOR_OWNER_SUCCESS,
} from "./ActionConstant";

// Domains for Owner
export function fetchDomainsForOwnerStart(data) {
  return {
    type: FETCH_DOMAINS_FOR_OWNER_START,
    data,
  };
}

export function fetchDomainsForOwnerSuccess(data) {
  return {
    type: FETCH_DOMAINS_FOR_OWNER_SUCCESS,
    data,
  };
}

export function domainsForOwnerSuccess(data) {
  return {
    type: DOMAINS_FOR_OWNER_SUCCESS,
    data,
  }
}

export function fetchDomainsForOwnerFailure(error) {
  return {
    type: FETCH_DOMAINS_FOR_OWNER_FAILURE,
    error,
  };
}

export function fetchMoreDomainsForOwnerStart(data) {
  return {
    type: FETCH_MORE_DOMAINS_FOR_OWNER_START,
    data,
  }
}

// WatchList List
export function fetchWatchlistListStart(data) {
  return {
    type: FETCH_WATCHLIST_LIST_START,
    data,
  };
}

export function fetchWatchlistListSuccess(data) {
  return {
    type: FETCH_WATCHLIST_LIST_SUCCESS,
    data,
  };
}

export function fetchResetWatchlistListSuccess(data) {
  return {
    type: FETCH_RESET_WATCHLIST_LIST_SUCCESS,
    data,
  };
}

export function fetchWatchlistListFailure(error) {
  return {
    type: FETCH_WATCHLIST_LIST_FAILURE,
    error,
  };
}

export function fetchMoreWatchlistListStart(data) {
  return {
    type: FETCH_MORE_WATCHLIST_LIST_START,
    data,
  };
}

//Update WatchList
export function updateWatchlistStart(data) {
  return {
    type: UPDATE_WATCHLIST_START,
    data,
  };
}

export function updateWatchlistSuccess(data) {
  return {
    type: UPDATE_WATCHLIST_SUCCESS,
    data,
  };
}

export function updateWatchlistFailure(error) {
  return {
    type: UPDATE_WATCHLIST_FAILURE,
    error,
  };
}

//Domain Market place list
export function fetchDomainMarketPlacesStart(data) {
  return {
    type: FETCH_DOMAIN_MARKET_PLACES_START,
    data,
  };
}

export function fetchDomainMarketPlaccesSuccess(data) {
  return {
    type: FETCH_DOMAIN_MARKET_PLACES_SUCCESS,
    data,
  };
}

export function fetchDomainMarketPlacesFailure(error) {
  return {
    type: FETCH_DOMAIN_MARKET_PLACES_FAILURE,
    error,
  };
}

export function fetchMoreDomainMarketPlacesStart(data) {
  return {
    type: FETCH_MORE_DOMAIN_MARKET_PLACES_START,
    data,
  }
}

//Sell Domain Market places
export function sellDomainMarketPlacesStart(data) {
  return {
    type: SELL_DOMAIN_MARKET_PLACES_START,
    data,
  };
}

export function sellDomainMarketPlacesSuccess(data) {
  return {
    type: SELL_DOMAIN_MARKET_PLACES_SUCCESS,
    data,
  };
}

export function sellDomainMarketPlacesFailure(error) {
  return {
    type: SELL_DOMAIN_MARKET_PLACES_FAILURE,
    error,
  };
}

//Buy Domain Market Place
export function buyDomainMarketPlacesStart(data) {
  return {
    type: BUY_DOMAIN_MARKET_PLACES_START,
    data,
  };
}

export function buyDomainMarketPlacesSuccess(data) {
  return {
    type: BUY_DOMAIN_MARKET_PLACES_SUCCESS,
    data,
  };
}

export function buyDomainMarketPlacesFailure(error) {
  return {
    type: BUY_DOMAIN_MARKET_PLACES_FAILURE,
    error,
  };
}

//Delete Domain Market Place
export function deleteDomainMarketPlacesStart(data) {
  return {
    type: DELETE_DOMAIN_MARKET_PLACES_START,
    data,
  };
}

export function deleteDomainMarketPlacesSuccess(data) {
  return {
    type: DELETE_DOMAIN_MARKET_PLACES_SUCCESS,
    data,
  };
}

export function deleteDomainMarketPlacesFailure(error) {
  return {
    type: DELETE_DOMAIN_MARKET_PLACES_FAILURE,
    error,
  }
}

// Domain Market place for owner
export function fetchDomainMarketPlacesForOwnerStart(data) {
  return {
    type: FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
    data,
  };
}

export function fetchDomainMarketPlacesForOwnerSuccess(data) {
  return {
    type: FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_SUCCESS,
    data,
  };
}

export function fetchDomainMarketPlacesForOwnerFailure(error) {
  return {
    type: FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_FAILURE,
    error,
  };
}

export function fetchMoreDomainMarketPlacesForOwnerStart(data) {
  return {
    type: FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
    data,
  };
}