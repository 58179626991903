import React, { useEffect, useState } from "react";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import "./CreateYourListing.css";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import configuration from "react-global-configuration";
import { sellDomainMarketPlacesStart } from "../store/actions/DomainAction";
import { connect } from "react-redux";

const CreateYourListingIndex = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const [amount, setAmount] = useState({
    actual: 0,
    percentage: "",
    final: "",
  });
  const [permission, setPermission] = useState(false);
  const [commission, setCommission] = useState(
    configuration.get("configData.market_place_admin_commission") ?
      configuration.get("configData.market_place_admin_commission") : 0);
  const [description, setDescription] = useState("");

  const setBidAmount = (value) => {
    if (!isNaN(value) && value.trim() === value) {
      let percentage = commission > 0 ? (commission / 100) * value : 0;
      setAmount({
        actual: value,
        percentage: percentage.toFixed(2),
        final: (value - percentage).toFixed(2),
      });
    }
  }

  const placeForSale = e => {
    e.preventDefault();
    props.dispatch(sellDomainMarketPlacesStart({
      domain_name: params.domain,
      description: description,
      amount: amount.actual,
    }))
  }

  useEffect(() => {
    if (!skipRender && !props.sellDomainMarketPlaces.loading && Object.keys(props.sellDomainMarketPlaces.data).length > 0) {
      navigate("/domain-manager")
    }
    setSkipRender(false);
  }, [props.sellDomainMarketPlaces]);

  return (
    <div>
      <div className="marketplace-sec">
        <DomainSearchSec />
        <Container>
          <Row className="justify-content-md-center mt-5 mb-5">
            <Col md={9}>
              <div className="title-sec">
                <h2>Create your listing</h2>
              </div>
              <div class="single-search-name-sec create-list-title">
                <h2>{params.domain}/</h2>
              </div>
              <div className="create-list-sec">
                <Row>
                  <Col md={6}>
                    <div className="create-list-left-sec">
                      <Form>
                        <div className="create-list-heading">
                          <h4>Set your Buy Now price</h4>
                        </div>
                        <div className="your-bid-form mb-4">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Your Bid"
                              value={amount.actual}
                              onChange={e => setBidAmount(e.target.value)}
                            />
                            <InputGroup.Text id="basic-addon2">
                              {configuration.get("configData.currency_code")}
                            </InputGroup.Text>
                          </InputGroup>
                          {/* <p>
                            You originally paid 1.00 USD for this name on the
                            marketplace.
                          </p> */}
                        </div>
                        <Form.Group
                          className="create-list-checkbox"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Allow potential buyers to send you offers"
                            checked={permission}
                            onClick={() => setPermission(!permission)}
                          />
                          <p>
                            Every offer and counteroffer has a shell life of 7
                            days for other person to respond.
                          </p>
                          <p>
                            Once an agreement has been reached, sales will
                            complete automatically and this name will be
                            transferred instantly.
                          </p>
                        </Form.Group>
                        <div className="create-list-text-area">
                          <div className="create-list-heading mb-3">
                            <h4>
                              Would you like to say something about this domain?
                            </h4>
                          </div>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Write your best sales pitch or describe what this name would be great for. Totally optional."
                              rows={3}
                              value={description}
                              onChange={e => setDescription(e.target.value)}
                            />
                          </Form.Group>
                          <div className="create-list-btn">
                            <Button
                              className="search-btn"
                              disabled={!(amount.actual > 0) || !permission || props.sellDomainMarketPlaces.buttonDisable}
                              onClick={placeForSale}
                            >
                              {props.sellDomainMarketPlaces.loadingButtonContent ?
                                props.sellDomainMarketPlaces.loadingButtonContent
                                : "List for sale"
                              }
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="create-list-right-sec">
                      <div className="create-list-heading mb-3">
                        <h4>Your payout</h4>
                      </div>
                      <ul>
                        <li>You get</li>
                        <li>{amount.actual ? amount.final : 0} {configuration.get("configData.currency_code")}</li>
                        <li>If your domain sells.</li>
                      </ul>
                      <div className="create-list-sale-sec">
                        <div className="create-sale-price">
                          <h6>Your sale price</h6>
                          <p>{amount.actual ? amount.actual : 0} {configuration.get("configData.currency_code")}</p>
                        </div>
                        <div className="create-sale-price">
                          <h6>Namebase commission</h6>
                          <p>{amount.actual ? amount.percentage : 0} {configuration.get("configData.currency_code")}</p>
                        </div>
                        <div className="create-sale-price">
                          <h6>Payout</h6>
                          <p>{amount.actual ? amount.final : 0} {configuration.get("configData.currency_code")}</p>
                        </div>
                      </div>
                      <p className="price-commission">
                        We charge a {commission}% commission fee which we only collect if
                        your domain successfully sells.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </div >
  );
};

const mapStateToPros = (state) => ({
  sellDomainMarketPlaces: state.domain.sellDomainMarketPlaces,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CreateYourListingIndex);