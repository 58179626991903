import "./App.css";
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./components/store";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import configuration from "react-global-configuration";
import { useEffect, useState } from "react";
import { apiConstants } from "./components/Constant/constants";

function App() {

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      {!configLoading && (
        <Base />
      )}
    </Provider>
  );
}

export default App;
