import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  BUY_DOMAIN_MARKET_PLACES_START,
  DELETE_DOMAIN_MARKET_PLACES_START,
  FETCH_DOMAINS_FOR_OWNER_START,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
  FETCH_DOMAIN_MARKET_PLACES_START,
  FETCH_MORE_DOMAINS_FOR_OWNER_START,
  FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
  FETCH_MORE_DOMAIN_MARKET_PLACES_START,
  FETCH_MORE_WATCHLIST_LIST_START,
  FETCH_WATCHLIST_LIST_START,
  SELL_DOMAIN_MARKET_PLACES_START,
  UPDATE_WATCHLIST_START,
} from "../actions/ActionConstant";
import { fetchBidsListSuccess, viewDomainSuccess } from "../actions/BidAction";
import {
  buyDomainMarketPlacesFailure,
  buyDomainMarketPlacesSuccess,
  deleteDomainMarketPlacesFailure,
  deleteDomainMarketPlacesSuccess,
  domainsForOwnerSuccess,
  fetchDomainMarketPlaccesSuccess,
  fetchDomainMarketPlacesFailure,
  fetchDomainMarketPlacesForOwnerFailure,
  fetchDomainMarketPlacesForOwnerSuccess,
  fetchDomainsForOwnerFailure,
  fetchDomainsForOwnerSuccess,
  fetchResetWatchlistListSuccess,
  fetchWatchlistListFailure,
  fetchWatchlistListSuccess,
  sellDomainMarketPlacesFailure,
  sellDomainMarketPlacesSuccess,
  updateWatchlistFailure,
  updateWatchlistSuccess
} from "../actions/DomainAction";

function* fetchDomainsForOwnerAPI(action) {
  try {
    const response = yield api.postMethod("domains_for_owner", action.data);
    if (response.data.success) {
      yield put(fetchDomainsForOwnerSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchDomainsForOwnerFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDomainsForOwnerFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchWatchlistListAPI(action) {
  try {
    const response = yield api.postMethod("watchlists_list", action.data);
    if (response.data.success) {
      yield put(fetchWatchlistListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchWatchlistListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWatchlistListFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* updateWatchlistAPI(action) {
  try {
    const response = yield api.postMethod("watchlists_update", action.data);
    if (response.data.success) {
      let domainViewData = yield select((state) => state.bid.domainView.data);
      domainViewData = {
        ...domainViewData,
        domain: response.data.data,
      };
      let watchlistsData = yield select((state) => state.domain.watchlists.data);
      watchlistsData = {
        total: (watchlistsData.total - 1),
        domains: watchlistsData.domains.filter((domain) => domain.name !== action.data.domain)
      }
      yield put(updateWatchlistSuccess(response.data));
      yield put(viewDomainSuccess(domainViewData));
      yield put(fetchResetWatchlistListSuccess(watchlistsData));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(updateWatchlistFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateWatchlistFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchDomainMarketPlacesAPI(action) {
  try {
    const response = yield api.postMethod("domain_market_places", action.data);
    if (response.data.success) {
      yield put(fetchDomainMarketPlaccesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchDomainMarketPlacesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDomainMarketPlacesFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* sellDomainMarketPlaceAPI(action) {
  try {
    const response = yield api.postMethod("domain_market_places_sell", action.data);
    if (response.data.success) {
      yield put(sellDomainMarketPlacesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(sellDomainMarketPlacesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sellDomainMarketPlacesFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* buyDomainMarketPlacesAPI(action) {
  try {
    const response = yield api.postMethod("domain_market_places_buy", action.data);
    if (response.data.success) {
      yield put(buyDomainMarketPlacesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(buyDomainMarketPlacesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(buyDomainMarketPlacesFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* deleteDomainMarketPlacesAPI(action) {
  try {
    const response = yield api.postMethod("domain_market_places_delete", action.data);
    if (response.data.success) {
      yield put(deleteDomainMarketPlacesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      let domainForOwnersData = yield select((state) => state.domain.domainsForOwner.data);
      domainForOwnersData = {
        total: domainForOwnersData.total - 1,
        domains: domainForOwnersData.domains.filter((dom) => dom.domain_market_place.domain_market_place_unique_id !== action.data.domain_market_place_unique_id)
      };
      yield put(domainsForOwnerSuccess(domainForOwnersData));
    } else {
      yield put(deleteDomainMarketPlacesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteDomainMarketPlacesFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchDomainMarketPlacesForOwnerAPI(action) {
  try {
    const response = yield api.postMethod("domain_market_places_for_owner", action.data);
    if (response.data.success) {
      yield put(fetchDomainMarketPlacesForOwnerSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchDomainMarketPlacesForOwnerFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDomainMarketPlacesForOwnerFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_DOMAINS_FOR_OWNER_START, fetchDomainsForOwnerAPI),
    yield takeLatest(FETCH_MORE_DOMAINS_FOR_OWNER_START, fetchDomainsForOwnerAPI),
    yield takeLatest(FETCH_WATCHLIST_LIST_START, fetchWatchlistListAPI),
    yield takeLatest(FETCH_MORE_WATCHLIST_LIST_START, fetchWatchlistListAPI),
    yield takeLatest(UPDATE_WATCHLIST_START, updateWatchlistAPI),
    yield takeLatest(FETCH_DOMAIN_MARKET_PLACES_START, fetchDomainMarketPlacesAPI),
    yield takeLatest(FETCH_MORE_DOMAIN_MARKET_PLACES_START, fetchDomainMarketPlacesAPI),
    yield takeLatest(SELL_DOMAIN_MARKET_PLACES_START, sellDomainMarketPlaceAPI),
    yield takeLatest(BUY_DOMAIN_MARKET_PLACES_START, buyDomainMarketPlacesAPI),
    yield takeLatest(DELETE_DOMAIN_MARKET_PLACES_START, deleteDomainMarketPlacesAPI),
    yield takeLatest(FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START, fetchDomainMarketPlacesForOwnerAPI),
    yield takeLatest(FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START, fetchDomainMarketPlacesForOwnerAPI),
  ]);
}