import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, InputGroup, Form, Button, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import "./Settings.css";
import { connect } from "react-redux";
import EditProfileModal from "./EditProfileModal";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { changePasswordStart, twoStepAuthUpdateStart } from "../store/actions/UserAction";
import { useNavigate } from "react-router-dom";


const SettingsIndex = (props) => {
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Required"),
    password: Yup.string().required("Required").when("old_password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().notOneOf(
        [Yup.ref("old_password")],
        "Should not be old password"
      )
    }),
    password_confirmation: Yup.string().required("Required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  });

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setInitialTwoStep(props.profile.data.is_two_step_auth_enabled === 0 ? false : true);
      setTwoStepAuth(props.profile.data.is_two_step_auth_enabled === 0 ? false : true);
    }
  }, [props.profile.data]);


  useEffect(() => {
    if (!skipRender && !props.changePassword.loading && Object.keys(props.changePassword.data).length > 0) {
      setTimeout(() => {
        navigate("/logout");
      }, 2000);
    }
    setSkipRender(false);
  }, [props.changePassword]);

  const changePassword = values => {
    props.dispatch(changePasswordStart(values));
  }

  const changeTwoStepAuth = values => {
    props.dispatch(twoStepAuthUpdateStart(values));
  }


  const closeEditProfile = () => {
    setEditProfile(false);
  }

  return (
    <>
      <div className="settings-sec">
        <DomainSearchSec />
        <div className="main-wrapper-box">
          <div className="mobile-sidebar-sec">
            <DashboardSidebar />
          </div>
          <div className="main-wrapper-right">
            {/* <Form> */}
            <div className="title-sec">
              <h2>Settings</h2>
              <p>
                Good to See you here
                <Image
                  className="wave-hand-icon"
                  src={
                    window.location.origin + "/images/dashboard/waving-hand.png"
                  }
                />
              </p>
            </div>
            {props.profile.loading ? "Loading" : (
              props.profile.data ?
                <div className="setting-card">
                  <div className="setting-email-edit-sec">
                    <h4>Account</h4>
                    <Button className="edit-btn" onClick={() => setEditProfile(true)}>
                      <Image
                        className="wave-hand-icon"
                        src={
                          window.location.origin + "/images/settings/edit-icon.svg"
                        }
                      />
                      <span>Edit</span>
                    </Button>
                  </div>
                  <div className="setting-item">
                    <h5>Name</h5>
                    <div className="setting-email-edit-sec">
                      <p>{props.profile.data.name}</p>
                    </div>
                  </div>
                  <div className="setting-item">
                    <h5>Email</h5>
                    <div className="setting-email-edit-sec">
                      <p>{props.profile.data.email}</p>
                    </div>
                  </div>
                  <div className="setting-item">
                    <h5>Password</h5>
                    <div className="setting-password-change-sec">
                      <Button className="edit-btn" onClick={() => setShowChangePassword(!showChangePassword)}>
                        <span>{showChangePassword ? "Close" : "Change Password"}</span>
                      </Button>
                      {showChangePassword &&
                        <Formik
                          initialValues={{
                            old_password: "",
                            password: "",
                            password_confirmation: "",
                          }}
                          validationSchema={validationSchema}
                          onSubmit={(values) => changePassword(values)}
                        >
                          {({ errors, touched }) => (
                            <FORM>
                              <div className="auth-form setting-change-password-form">
                                <Form.Group className="form-group">
                                  <Form.Label>Current Password</Form.Label>
                                  <Field
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter Your Current Password"
                                    name="old_password"
                                  />
                                  <ErrorMessage component={"div"} name="old_password" className='text-danger text-right' />
                                </Form.Group>
                                <Form.Group className="form-group">
                                  <Form.Label>New Password</Form.Label>
                                  <Field
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter Your New Password"
                                    name="password"
                                  />
                                  <ErrorMessage component={"div"} name="password" className='text-danger text-right' />
                                </Form.Group>
                                <Form.Group className="form-group">
                                  <Form.Label>Confirm Password</Form.Label>
                                  <Field
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm Your New Password"
                                    name="password_confirmation"
                                  />
                                  <ErrorMessage component={"div"} name="password_confirmation" className='text-danger text-right' />
                                </Form.Group>
                                <div className="auth-btn-sec">
                                  <Button
                                    className="default-btn"
                                    type="submit"
                                    disabled={props.changePassword.buttonDisable}
                                  >
                                    {props.changePassword.loadingButtonContent ?
                                      props.changePassword.loadingButtonContent
                                      : "Submit"
                                    }
                                  </Button>
                                </div>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      }
                    </div>
                  </div>
                </div>
                : null
            )}

            <div className="setting-card">
              <Row>
                <Col md={6}>
                  <h4>Security</h4>
                  <div className="two-step-authetication-card">
                    <h5>Two-factor authetication</h5>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={twoStepAuth}
                      onClick={() => setTwoStepAuth(!twoStepAuth)}
                    />
                  </div>
                  <p>Two-factor authentication adds an additional layer of security to your account. Authenticator app required.</p>
                  {initialTwoStep != twoStepAuth ?
                    <div className="auth-form setting-change-password-form">
                      <Formik
                        initialValues={{
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required("Required"),
                        })}
                        onSubmit={(values) => changeTwoStepAuth(values)}
                      >
                        {({ errors, touched }) => (
                          <FORM>
                            <Form.Group className="form-group">
                              <Form.Label>Your Password</Form.Label>
                              <Field
                                type="password"
                                className="form-control"
                                placeholder="Enter Your Password"
                                name="password"
                              />
                              <ErrorMessage component={"div"} name="password" className='text-danger text-right' />
                            </Form.Group>
                            <div className="auth-btn-sec">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={props.twoStepAuthUpdate.buttonDisable}
                              >
                                {props.twoStepAuthUpdate.loadingButtonContent ?
                                  props.twoStepAuthUpdate.loadingButtonContent
                                  : "Submit"
                                }
                              </Button>
                            </div>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                    : null
                  }
                </Col>
              </Row>
            </div>
            {/* </Form> */}
          </div>
        </div>
      </div>
      {editProfile ?
        <EditProfileModal
          editProfile={editProfile}
          closeEditProfile={closeEditProfile}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  changePassword: state.users.changePassword,
  twoStepAuthUpdate: state.users.twoStepAuthUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SettingsIndex);
