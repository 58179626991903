import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import domainReducer from "./DomainReducer";
import bidReducer from "./BidReducer";
import walletReducer from "./WalletReducer";
import PageReducer from "./PageReducer";

export default combineReducers({
  users: UserReducer,
  domain: domainReducer,
  bid: bidReducer,
  wallet: walletReducer,
  page: PageReducer,
});
