import React, { useState, useEffect } from "react";
import {
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import { buyDomainMarketPlacesStart } from "../store/actions/DomainAction";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const PurchaseModal = (props) => {
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);

  const onPurchase = () => {
    props.dispatch(buyDomainMarketPlacesStart({
      domain_market_place_unique_id: props.domain.domain_market_place_unique_id
    }))
  }

  useEffect(() => {
    if (!skipRender && !props.buyDomainMarketPlaces.loading && Object.keys(props.buyDomainMarketPlaces.data).length > 0) {
      navigate("/domain-manager");
    }
    setSkipRender(false);
  }, [props.buyDomainMarketPlaces]);

  return (
    <>
      <Modal
        className="modal-dialog-center purchas-modal-sec"
        size="md"
        centered
        show={props.purchase}
        onHide={props.closePurchaseModal}
      >
        <Modal.Body>
          <h4 className="text-center">Review your purchase</h4>
          <Button className="modal-close" onClick={() => props.closePurchaseModal()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/images/dashboard/modal-close.svg"
              }
            />
          </Button>
          <div className="purchase-card">
            <div className="purchase-header-sec">
              <h4>
                {props.domain.domain_name}/
              </h4>
            </div>
            <div className="purchase-nft-card">
              <h5>
                Buy now price
              </h5>
              <h5>
                {props.domain.amount_formatted}
              </h5>
            </div>
            <div className="purchase-safe-note">
              Guaranteed safe & secure checkout
            </div>
            <div className="add-card-btn-sec">
              <Button
                className="add-money-btn"
                disabled={props.buyDomainMarketPlaces.buttonDisable}
                onClick={() => onPurchase()}
              >
                {props.buyDomainMarketPlaces.loadingButtonContent ?
                  props.buyDomainMarketPlaces.loadingButtonContent
                  : "Purchase"
                }
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  buyDomainMarketPlaces: state.domain.buyDomainMarketPlaces,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(PurchaseModal);