import React, { useContext, useEffect, useRef, useState } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPage from "../landingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import RegisterIndex from "../Auth/RegisterIndex";
import LoginIndex from "../Auth/LoginIndex";
import ResetPasswordIndex from "../Auth/ResetPasswordIndex";
import LandingPageIndex from "../landingPage/LandingPageIndex";
import SingleSearchIndex from "../SingleSearch/SingleSearchIndex";
import DashBoardIndex from "../DashBoard/DashBoardIndex";
import AddMoneyIndex from "../Wallet/AddMoneyIndex";
import WithDrawIndex from "../Wallet/WithDrawIndex";
import WalletIndex from "../Wallet/WalletIndex";
import ReceiptIndex from "../Receipt/ReceiptIndex";
import TransactionHistoryIndex from "../Transaction/TransactionHistoryIndex";
import DomainManagerIndex from "../Domain/DomainManagerIndex";
import YourBidsIndex from "../YourBids/YourBidsIndex";
import Logout from "../Auth/Logout";
import SettingsIndex from "../Settings/SettingsIndex";
import StaticIndex from "../Static/StaticIndex";
import VerificationIndex from "../Verification/VerificationIndex";
import AboutIndex from "../About/AboutIndex";
import SupportIndex from "../Support/SupportIndex";
import MobileDashboard from "../DashBoard/MobileDashboardIndex";
import WalletWithdrawIndex from "../Wallet/WalletWithdrawIndex";
import RegisterVerificationIndex from "../Verification/RegisterVerificationIndex";
import WishlistIndex from "../Wishlist/WishlistIndex";
import MarketPlaceIndex from "../MarketPlace/MarketPlaceIndex";
import CreateYourListingIndex from "../Listing/CreateYourListingIndex";
import DomainIndex from "../Domain/DomainIndex";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  // let userId = localStorage.getItem("userId");
  // let accessToken = localStorage.getItem("accessToken");
  // let userLoginStatus = localStorage.getItem("userLoginStatus");

  const [loading, setLoading] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  // const [authentication, setAuthntication] = useState(
  //   userId && accessToken && userLoginStatus == "true" ? true : false
  // );

  // history.listen((location, action) => {
  //   userId = localStorage.getItem("userId");
  //   accessToken = localStorage.getItem("accessToken");
  //   userLoginStatus = localStorage.getItem("userLoginStatus");
  //   setLoading(true);
  //   setAuthntication(
  //     userId && accessToken && userLoginStatus == "true" ? true : false
  //   );
  //   document.body.scrollTop = 0;
  // });

  return (
    <>
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={LandingPageIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute component={RegisterIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/logout"}
            element={<AppRoute component={Logout} layout={EmptyLayout} />}
          />
          <Route
            path={"/reset-password/:token"}
            element={
              <AppRoute component={ResetPasswordIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/domains/:search"}
            element={
              <AppRoute component={SingleSearchIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/dashboard"}
            element={
              <PrivateRoute component={DashBoardIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/add-money"}
            element={
              <PrivateRoute component={AddMoneyIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/transaction-history"}
            element={
              <PrivateRoute
                component={TransactionHistoryIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/receipt/:domain"}
            element={
              <PrivateRoute component={ReceiptIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/wallet"}
            element={
              <PrivateRoute component={WalletIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/wallet-withdraw"}
            element={
              <PrivateRoute
                component={WalletWithdrawIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/withdraw-money"}
            element={
              <PrivateRoute component={WithDrawIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/domain-manager"}
            element={
              <PrivateRoute
                component={DomainManagerIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/your-bids"}
            element={
              <PrivateRoute component={YourBidsIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/watchlist"}
            element={
              <PrivateRoute component={WishlistIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/settings"}
            element={
              <PrivateRoute component={SettingsIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/page/:static_page_unique_id"}
            element={<AppRoute component={StaticIndex} layout={MainLayout} />}
          />
          <Route
            path={"/verification"}
            element={
              <AppRoute component={VerificationIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/register-verification"}
            element={
              <AppRoute
                component={RegisterVerificationIndex}
                layout={EmptyLayout}
              />
            }
          />
          <Route
            path={"/about"}
            element={<AppRoute component={AboutIndex} layout={MainLayout} />}
          />
          <Route
            path={"/support"}
            element={<AppRoute component={SupportIndex} layout={MainLayout} />}
          />
          <Route
            path={"/mobile-dashboard"}
            element={
              <AppRoute component={MobileDashboard} layout={MainLayout} />
            }
          />
          <Route
            path={"/marketplace"}
            element={
              <AppRoute component={MarketPlaceIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/create-your-list/:domain"}
            element={
              <AppRoute
                component={CreateYourListingIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/dns/:domain"}
            element={<AppRoute component={DomainIndex} layout={MainLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
