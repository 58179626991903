import {
  CREATE_BID_FAILURE,
  CREATE_BID_START,
  CREATE_BID_SUCCESS,
  FETCH_BIDS_LIST_FAILURE,
  FETCH_BIDS_LIST_START,
  FETCH_BIDS_LIST_SUCCESS,
  FETCH_BIDS_RECIEPT_FAILURE,
  FETCH_BIDS_RECIEPT_START,
  FETCH_BIDS_RECIEPT_SUCCESS,
  FETCH_BIDS_TRANSACTION_HISTORY_FAILURE,
  FETCH_BIDS_TRANSACTION_HISTORY_START,
  FETCH_BIDS_TRANSACTION_HISTORY_SUCCESS,
  FETCH_MORE_BIDS_LIST_START,
  FETCH_MORE_BIDS_TRANSACTION_HISTORY_START,
  FETCH_MORE_OWNER_BIDS_LIST_START,
  FETCH_OWNER_BIDS_LIST_FAILURE,
  FETCH_OWNER_BIDS_LIST_START,
  FETCH_OWNER_BIDS_LIST_SUCCESS,
  UPDATE_BID_STATUS_FAILURE,
  UPDATE_BID_STATUS_START,
  UPDATE_BID_STATUS_SUCCESS,
  VIEW_DOMAIN_FAILURE,
  VIEW_DOMAIN_START,
  VIEW_DOMAIN_SUCCESS
} from "./ActionConstant";

//Create Bids
export function createBidStart(data) {
  return {
    type: CREATE_BID_START,
    data,
  };
}

export function createBidSuccess(data) {
  return {
    type: CREATE_BID_SUCCESS,
    data,
  };
}

export function createBidFailure(error) {
  return {
    type: CREATE_BID_FAILURE,
    error,
  };
}

// Domain View
export function viewDoaminStart(data) {
  return {
    type: VIEW_DOMAIN_START,
    data,
  };
}

export function viewDomainSuccess(data) {
  return {
    type: VIEW_DOMAIN_SUCCESS,
    data,
  };
}

export function viewDomainFailure(error) {
  return {
    type: VIEW_DOMAIN_FAILURE,
    error,
  }
}

//Bids List
export function fetchBidsListStart(data) {
  return {
    type: FETCH_BIDS_LIST_START,
    data,
  };
}

export function fetchBidsListSuccess(data) {
  return {
    type: FETCH_BIDS_LIST_SUCCESS,
    data,
  };
}

export function fetchBidsListFailure(error) {
  return {
    type: FETCH_BIDS_LIST_FAILURE,
    error,
  };
}

export function fetchMoreBidsListStart(data) {
  return {
    type: FETCH_MORE_BIDS_LIST_START,
    data,
  }
}

//Update Bid Status
export function updateBidStatusStart(data) {
  return {
    type: UPDATE_BID_STATUS_START,
    data,
  };
}

export function updateBidStatusSuccess(data) {
  return {
    type: UPDATE_BID_STATUS_SUCCESS,
    data,
  };
}

export function updateBidStatusFailure(error) {
  return {
    type: UPDATE_BID_STATUS_FAILURE,
    error,
  };
}

//Bids Transaction History
export function fetchBidsTransactionHistoryStart(data) {
  return {
    type: FETCH_BIDS_TRANSACTION_HISTORY_START,
    data,
  };
}

export function fetchBidsTransactionHistorySuccess(data) {
  return {
    type: FETCH_BIDS_TRANSACTION_HISTORY_SUCCESS,
    data,
  };
}

export function fetchBidsTransactionHistoryFailure(error) {
  return {
    type: FETCH_BIDS_TRANSACTION_HISTORY_FAILURE,
    error,
  };
}

export function fetchMoreBidsTransactionHistoryStart(data) {
  return {
    type: FETCH_MORE_BIDS_TRANSACTION_HISTORY_START,
    data,
  }
}

//Bids Receipt
export function fetchBidsRecieptStart(data) {
  return {
    type: FETCH_BIDS_RECIEPT_START,
    data,
  };
}

export function fetchBidsRecieptSuccess(data) {
  return {
    type: FETCH_BIDS_RECIEPT_SUCCESS,
    data,
  };
}

export function fetchBidsRecieptFailure(error) {
  return {
    type: FETCH_BIDS_RECIEPT_FAILURE,
    error,
  };
}

//Owner Bids List
export function fetchOwnerBidsListStart(data) {
  return {
    type: FETCH_OWNER_BIDS_LIST_START,
    data,
  };
}

export function fetchOwnerBidsListSuccess(data) {
  return {
    type: FETCH_OWNER_BIDS_LIST_SUCCESS,
    data,
  };
}

export function fetchOwnerBidsListFailure(error) {
  return {
    type: FETCH_OWNER_BIDS_LIST_FAILURE,
    error,
  };
}

export function fetchMoreOwnerBidsListStart(data) {
  return {
    type: FETCH_MORE_OWNER_BIDS_LIST_START,
    data,
  }
}