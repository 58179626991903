import React, { useState } from "react";
import { Container, Row, Col, Image, InputGroup, Form, Button, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";

const DomainSearchSec = (props) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const onSearch = (e) => {
    e.preventDefault();
    let formatted = search.replaceAll(/[&\/\\#,+()$~%.'":*?!^|;@<>{}]/g, '');
    formatted = formatted.replaceAll(" ", "");
    if (formatted.length > 0)
      navigate(`/domains/${formatted}`);
    else
      props.dispatch(getErrorNotificationMessage("Empty or Invalid Search query"));
  }

  return (
    <Form noValidate onSubmit={onSearch}>
      <div className="dashboard-header-sec">
        <InputGroup className="mb-0">
          <Form.Control
            placeholder="Search For Your Personal TLD"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <InputGroup.Text id="basic-addon2" onClick={e => onSearch(e)}>
            <Image
              className="dashboard-icon"
              src={
                window.location.origin + "/images/single-search/search-icon.svg"
              }
            />
          </InputGroup.Text>
        </InputGroup>
      </div>
    </Form>
  )

}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  null,
  mapDispatchToProps
)(DomainSearchSec);
