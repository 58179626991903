import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteDomainMarketPlacesStart,
  fetchDomainsForOwnerStart,
  fetchMoreDomainsForOwnerStart,
} from "../store/actions/DomainAction";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Dropdown,
  Button,
  Tab,
  Table,
  Nav,
} from "react-bootstrap";

const YourDomain = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchDomainsForOwnerStart({
        skip: 0,
        take: 12,
        for_sale: props.activeTab === "for-sale" ? 1 : "",
      })
    );
  }, [props.activeTab]);

  const fetchMoreDomains = () => {
    props.dispatch(
      fetchMoreDomainsForOwnerStart({
        skip: props.domains.data.domains.length,
        take: 12,
        for_sale: props.activeTab === "for-sale" ? 1 : "",
      })
    );
  };

  const removeFromMarketPlace = (domain) => {
    props.dispatch(
      deleteDomainMarketPlacesStart({
        domain_market_place_unique_id:
          domain.domain_market_place.domain_market_place_unique_id,
      })
    );
  };

  return (
    <>
      <div className="your-domain-sec">
        {props.domains.loading ? (
          <CommonCenterLoader />
        ) : Object.keys(props.domains.data).length > 0 &&
          props.domains.data.domains.length > 0 ? (
          // <div className="custom-table-list">
          //   <div className="custom-table-header">
          //     <div className="custom-table-header-card">
          //       <h5>Domain</h5>
          //       <h5>Status</h5>
          //       <h5>Renews on</h5>
          //     </div>
          //   </div>
          //   <InfiniteScroll
          //     dataLength={props.domains.data.domains.length}
          //     next={fetchMoreDomains}
          //     hasMore={
          //       props.domains.data.domains.length < props.domains.data.total
          //     }
          //     loader={<CommonCenterLoader />}
          //   >
          //     <div className="custom-table-body">
          //       {props.domains.data.domains.map((domain) => (
          //         <div className="custom-table-card">
          //           <p>
          //             <Link to={`/domains/${domain.name}`}>{domain.name}/</Link>
          //           </p>
          //           <p>
          //             {domain.is_expired ? (
          //               <>
          //                 <Image
          //                   src={
          //                     window.location.origin +
          //                     "/images/error-circle.svg"
          //                   }
          //                 />
          //                 Expired
          //               </>
          //             ) : (
          //               <>
          //                 <Image
          //                   src={
          //                     window.location.origin +
          //                     "/images/success-circle.svg"
          //                   }
          //                 />
          //                 Up-to-date
          //               </>
          //             )}
          //           </p>
          //           <p>{domain.expire_at_formatted}</p>
          //           {props.activeTab === "your-domains" ? (
          //             <div className="d-flex align-items-center">
          //               <Link
          //                 to={`/dns/${domain.name}`}
          //                 className="custom-black-btn margin-right-5"
          //                 // target="_blank"
          //               >
          //                 Manage
          //               </Link>
          //               {domain.for_sale !== 1 ? (
          //                 <Link
          //                   to={`/create-your-list/${domain.name}`}
          //                   className="custom-black-btn"
          //                 >
          //                   Sell
          //                 </Link>
          //               ) : null}
          //             </div>
          //           ) : (
          //             <Button
          //               className="custom-black-btn"
          //               onClick={() => removeFromMarketPlace(domain)}
          //               disabled={
          //                 props.deleteDomainMarketPlaces.buttonDisable &&
          //                 props.deleteDomainMarketPlaces.data
          //                   .domain_market_place_unique_id ===
          //                   domain.domain_market_place
          //                     .domain_market_place_unique_id
          //               }
          //             >
          //               {props.deleteDomainMarketPlaces.loadingButtonContent &&
          //               props.deleteDomainMarketPlaces.data
          //                 .domain_market_place_unique_id ===
          //                 domain.domain_market_place
          //                   .domain_market_place_unique_id
          //                 ? props.deleteDomainMarketPlaces.loadingButtonContent
          //                 : "Cancel"}
          //             </Button>
          //           )}
          //         </div>
          //       ))}
          //     </div>
          //   </InfiniteScroll>
          // </div>

          <div className="new-table-sec table-list-border">
            <InfiniteScroll
              dataLength={props.domains.data.domains.length}
              next={fetchMoreDomains}
              hasMore={
                props.domains.data.domains.length < props.domains.data.total
              }
              loader={<CommonCenterLoader />}
            >
              <Table striped bordered hover size="sm" responsive="md">
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>Renews on</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="custom-table-content">
                  {props.domains.data.domains.map((domain) => (
                    <tr>
                      <td>
                        <Link to={`/domains/${domain.name}`}>
                          {domain.name}/
                        </Link>
                      </td>
                      <td>
                        {domain.is_expired ? (
                          <>
                            <Image
                              src={
                                window.location.origin +
                                "/images/error-circle.svg"
                              }
                            />
                            Expired
                          </>
                        ) : (
                          <>
                            <Image
                              src={
                                window.location.origin +
                                "/images/success-circle.svg"
                              }
                            />
                            Up-to-date
                          </>
                        )}
                      </td>
                      <td>{domain.expire_at_formatted}</td>
                      {props.activeTab === "your-domains" ? (
                        <td className="table-btn">
                          <div className="d-flex align-items-center justify-content-center">
                            <Link
                              to={`/dns/${domain.name}`}
                              className="custom-black-btn margin-right-5"
                              // target="_blank"
                            >
                              Manage
                            </Link>
                            {domain.for_sale !== 1 ? (
                              <Link
                                to={`/create-your-list/${domain.name}`}
                                className="custom-black-btn"
                              >
                                Sell
                              </Link>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td className="table-btn">
                          <Button
                            className="custom-black-btn"
                            onClick={() => removeFromMarketPlace(domain)}
                            disabled={
                              props.deleteDomainMarketPlaces.buttonDisable &&
                              props.deleteDomainMarketPlaces.data
                                .domain_market_place_unique_id ===
                                domain.domain_market_place
                                  .domain_market_place_unique_id
                            }
                          >
                            {props.deleteDomainMarketPlaces
                              .loadingButtonContent &&
                            props.deleteDomainMarketPlaces.data
                              .domain_market_place_unique_id ===
                              domain.domain_market_place
                                .domain_market_place_unique_id
                              ? props.deleteDomainMarketPlaces
                                  .loadingButtonContent
                              : "Cancel"}
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  domains: state.domain.domainsForOwner,
  deleteDomainMarketPlaces: state.domain.deleteDomainMarketPlaces,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(YourDomain);
