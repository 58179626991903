import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../../Helper/ToastNotification";

const HeaderIndex = (props) => {

  const navigate = useNavigate();
  
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    let formatted = search.replaceAll(/[&\/\\#,+()$~%.'":*?!^|;@<>{}]/g, '');
    formatted = formatted.replaceAll(" ", "");
    if (formatted.length > 0)
      navigate(`/domains/${formatted}`);
    else
      props.dispatch(getErrorNotificationMessage("Empty or Invalid Search query"));
  }

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <picture>
                <source
                  className="lazyload logo"
                  type="image/png"
                  srcset={window.location.origin + "/images/logo.png"}
                />
                <Image
                  className="lazyload logo"
                  src={window.location.origin + "/images/logo.png"}
                  type="image/png"
                />
              </picture>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/images/hamburger-menu.png"}
                type="image/png"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" className="new-header-sec">
              <Nav className="new-navbar-sec">
                {localStorage.getItem("userId") &&
                localStorage.getItem("accessToken") ? (
                  <Link to="/dashboard" className="nav-link">
                  Dashboard
                </Link>
                ) : null}
                {/* <Link className="nav-link" to="/marketplace">Marketplace</Link> */}
                <Nav.Link href="/page/about" target="_blank">
                  About
                </Nav.Link>
                <Nav.Link href="/page/support" target="_blank">
                  Support
                </Nav.Link>
              </Nav>
              <div className="header-profile-sec">
                <div className="new-header-search">
                <Form noValidate onSubmit={onSearch}>
                  <InputGroup className="mb-0">
                    <Form.Control 
                      placeholder="Search Your Domain"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2" onClick={e => onSearch(e)}>
                      <Image
                        className="dashboard-icon"
                        src={
                          window.location.origin +
                          "/images/single-search/search-icon.svg"
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  </Form>
                </div>
                {!localStorage.getItem("userId") ||
                !localStorage.getItem("accessToken") ? (
                  <>
                    <Link to="/login" className="nav-link auth-btn">
                      Login
                    </Link>
                    {/* <Link to="/register" className="nav-link auth-btn">Sign Up</Link> */}
                  </>
                ) : (
                  // : <Link to="/logout" className="nav-link auth-btn">Logout</Link>
                  <div className="user-dropdown-sec">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <Image
                          className="header-user-icon"
                          src={window.location.origin + "/images/user.png"}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/settings">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M14 21h-4l-.551-2.48a6.991 6.991 0 0 1-1.819-1.05l-2.424.763-2-3.464 1.872-1.718a7.055 7.055 0 0 1 0-2.1L3.206 9.232l2-3.464 2.424.763A6.992 6.992 0 0 1 9.45 5.48L10 3h4l.551 2.48a6.992 6.992 0 0 1 1.819 1.05l2.424-.763 2 3.464-1.872 1.718a7.05 7.05 0 0 1 0 2.1l1.872 1.718-2 3.464-2.424-.763a6.99 6.99 0 0 1-1.819 1.052L14 21z"
                              />
                              <circle
                                cx="12"
                                cy="12"
                                r="3"
                                stroke="currentColor"
                                stroke-width="2"
                              />
                            </svg>
                          </span>
                          Settings
                        </Link>
                        <Link to="/logout">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-log-out"
                            >
                              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                              <polyline points="16 17 21 12 16 7"></polyline>
                              <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                          </span>
                          Logout
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HeaderIndex);
