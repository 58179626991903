export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REGENERATE_EMAIL_VERIFICATION_CODE_START = "REGENERATE_EMAIL_VERIFICATION_CODE_START";
export const REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS = "REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS";
export const REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE = "REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE";

export const VERIFY_EMAIL_START = "VERIFY_EMAIL_START";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const TWO_STEP_AUTH_LOGIN_START = "TWO_STEP_AUTH_LOGIN_START";
export const TWO_STEP_AUTH_LOGIN_SUCCESS = "TWO_STEP_AUTH_LOGIN_SUCCESS";
export const TWO_STEP_AUTH_LOGIN_FAILURE = "TWO_STEP_AUTH_LOGIN_FAILURE";

export const TWO_STEP_AUTH_RESEND_CODE_START = "TWO_STEP_AUTH_RESEND_CODE_START";
export const TWO_STEP_AUTH_RESEND_CODE_SUCCESS = "TWO_STEP_AUTH_RESEND_CODE_SUCCESS";
export const TWO_STEP_AUTH_RESEND_CODE_FAILURE = "TWO_STEP_AUTH_RESEND_CODE_FAILURE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const TWO_STEP_AUTH_UPDATE_START = "TWO_STEP_AUTH_UPDATE_START";
export const TWO_STEP_AUTH_UPDATE_SUCCESS = "TWO_STEP_AUTH_UPDATE_SUCCESS";
export const TWO_STEP_AUTH_UPDATE_FAILURE = "TWO_STEP_AUTH_UPDATE_FAILURE";

export const FETCH_DOMAINS_FOR_OWNER_START = "FETCH_DOMAINS_FOR_OWNER_START";
export const FETCH_DOMAINS_FOR_OWNER_SUCCESS = "FETCH_DOMAINS_FOR_OWNER_SUCCESS";
export const FETCH_DOMAINS_FOR_OWNER_FAILURE = "FETCH_DOMAINS_FOR_OWNER_FAILURE";
export const FETCH_MORE_DOMAINS_FOR_OWNER_START = "FETCH_MORE_DOMAINS_FOR_OWNER_START";
export const DOMAINS_FOR_OWNER_SUCCESS = "DOMAINS_FOR_OWNER_SUCCESS";

export const FETCH_WATCHLIST_LIST_START = "FETCH_WATCHLIST_LIST_START";
export const FETCH_WATCHLIST_LIST_SUCCESS = "FETCH_WATCHLIST_LIST_SUCCESS";
export const FETCH_RESET_WATCHLIST_LIST_SUCCESS = "FETCH_RESET_WATCHLIST_LIST_SUCCESS";
export const FETCH_WATCHLIST_LIST_FAILURE = "FETCH_WATCHLIST_LIST_FAILURE";
export const FETCH_MORE_WATCHLIST_LIST_START = "FETCH_MORE_WATCHLIST_LIST_START";

export const UPDATE_WATCHLIST_START = "UPDATE_WATCHLIST_START";
export const UPDATE_WATCHLIST_SUCCESS = "UPDATE_WATCHLIST_SUCCESS";
export const UPDATE_WATCHLIST_FAILURE = "UPDATE_WATCHLIST_FAILURE";

export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

export const CREATE_BID_START = "CREATE_BID_START";
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS";
export const CREATE_BID_FAILURE = "CREATE_BID_FAILURE";

export const FETCH_BIDS_LIST_START = "FETCH_BIDS_LIST_START";
export const FETCH_BIDS_LIST_SUCCESS = "FETCH_BIDS_LIST_SUCCESS";
export const FETCH_BIDS_LIST_FAILURE = "FETCH_BIDS_LIST_FAILURE";
export const FETCH_MORE_BIDS_LIST_START = "FETCH_MORe_BIDS_LIST_START";

export const UPDATE_BID_STATUS_START = "UPDATE_BID_STATUS_START";
export const UPDATE_BID_STATUS_SUCCESS = "UPDATE_BID_STATUS_SUCCESS";
export const UPDATE_BID_STATUS_FAILURE = "UPDATE_BID_STATUS_FAILURE";

export const FETCH_BIDS_TRANSACTION_HISTORY_START = "FETCH_BIDS_TRANSACTION_HISTORY_START";
export const FETCH_BIDS_TRANSACTION_HISTORY_SUCCESS = "FETCH_BIDS_TRANSACTION_HISTORY_SUCCESS";
export const FETCH_BIDS_TRANSACTION_HISTORY_FAILURE = "FETCH_BIDS_TRANSACTION_HISTORY_FAILURE";
export const FETCH_MORE_BIDS_TRANSACTION_HISTORY_START = "FETCH_MORE_BIDS_TRANSACTION_HISTORY_START";

export const ADD_USER_CARD_START = "ADD_USER_CARD_START";
export const ADD_USER_CARD_SUCCESS = "ADD_USER_CARD_SUCCESS";
export const ADD_USER_CARD_FAILURE = "ADD_USER_CARD_FAILURE";

export const FETCH_USER_CARD_LIST_START = "FETCH_USER_CARD_LIST_START";
export const FETCH_USER_CARD_LIST_SUCCESS = "FETCH_USER_CARD_LIST_SUCCESS";
export const FETCH_USER_CARD_LIST_FAILURE = "FETCH_USER_CARD_LIST_FAILURE";

export const DELETE_USER_CARD_START = "DELETE_USER_CARD_START";
export const DELETE_USER_CARD_SUCCESS = "DELETE_USER_CARD_SUCCESS";
export const DELETE_USER_CARD_FAILURE = "DELETE_USER_CARD_FAILURE";

export const SET_DEFAULT_USER_CARD_START = "SET_DEFAULT_USER_CARD_START";
export const SET_DEFAULT_USER_CARD_SUCCESS = "SET_DEFAULT_USER_CARD_SUCCESS";
export const SET_DEFAULT_USER_CARD_FAILURE = "SET_DEFAULT_USER_CARD_FAILURE";

export const FETCH_BIDS_RECIEPT_START = "FETCH_BIDS_RECIEPT_START";
export const FETCH_BIDS_RECIEPT_SUCCESS = "FETCH_BIDS_RECIEPT_SUCCESS";
export const FETCH_BIDS_RECIEPT_FAILURE = "FETCH_BIDS_RECIEPT_FAILURE";

export const ADD_USER_BILLING_ACCOUNTS_START = "ADD_USER_BILLING_ACCOUNTS_START";
export const ADD_USER_BILLING_ACCOUNTS_SUCCESS = "ADD_USER_BILLING_ACCOUNTS_SUCCESS";
export const ADD_USER_BILLING_ACCOUNTS_FAILURE = "ADD_USER_BILLING_ACCOUNTS_FAILURE";

export const FETCH_USER_BILLING_ACCOUNTS_START = "FETCH_USER_BILLING_ACCOUNTS_START";
export const FETCH_USER_BILLING_ACCOUNTS_SUCCESS = "FETCH_USER_BILLING_ACCOUNTS_SUCCESS";
export const FETCH_USER_BILLING_ACCOUNTS_FAILURE = "FETCH_USER_BILLING_ACCOUNTS_FAILURE";

export const DELETE_USER_BILLING_ACCOUNTS_START = "DELETE_USER_BILLING_ACCOUNTS_START";
export const DELETE_USER_BILLING_ACCOUNTS_SUCCESS = "DELETE_USER_BILLING_ACCOUNTS_SUCCESS";
export const DELETE_USER_BILLING_ACCOUNTS_FAILURE = "DELETE_USER_BILLING_ACCOUNTS_FAILURE";

export const SET_DEFAULT_USER_BILLING_ACCOUNTS_START = "SET_DEFAULT_USER_BILLING_ACCOUNTS_START";
export const SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS = "SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS";
export const SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE = "SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE";

export const FETCH_USER_WALLET_DETAILS_START = "FETCH_USER_WALLET_DETAILS_START";
export const FETCH_USER_WALLET_DETAILS_SUCCESS = "FETCH_USER_WALLET_DETAILS_SUCCESS";
export const FETCH_USER_WALLET_DETAILS_FAILURE = "FETCH_USER_WALLET_DETAILS_FAILURE";
export const FETCH_MORE_USER_WALLET_DETAILS_START = "FETCH_MORE_USER_WALLET_DETAILS_START";

export const WALLET_PAYMENT_BY_STRIPE_START = "WALLET_PAYMENT_BY_STRIPE_START";
export const WALLET_PAYMENT_BY_STRIPE_SUCCESS = "WALLET_PAYMENT_BY_STRIPE_SUCCESS";
export const WALLET_PAYMENT_BY_STRIPE_FAILURE = "WALLET_PAYMENT_BY_STRIPE_FAILURE";

export const FETCH_OWNER_BIDS_LIST_START = "FETCH_OWNER_BIDS_LIST_START";
export const FETCH_OWNER_BIDS_LIST_SUCCESS = "FETCH_OWNER_BIDS_LIST_SUCCESS";
export const FETCH_OWNER_BIDS_LIST_FAILURE = "FETCH_OWNER_BIDS_LIST_FAILURE";
export const FETCH_MORE_OWNER_BIDS_LIST_START = "FETCH_MORE_OWNER_BIDS_LIST_START";

export const WITHDRAWAL_REQUEST_START = "WITHDRAWAL_REQUEST_START";
export const WITHDRAWAL_REQUEST_SUCCESS = "WITHDRAWAL_REQUEST_SUCCESS";
export const WITHDRAWAL_REQUEST_FAILURE = "WITHDRAWAL_REQUEST_FAILURE";

export const CANCEL_WITHDRAWAL_REQUEST_START = "CANCEL_WITHDRAWAL_REQUEST_START";
export const CANCEL_WITHDRAWAL_REQUEST_SUCCESS = "CANCEL_WITHDRAWAL_REQUEST_SUCCESS";
export const CANCEL_WITHDRAWAL_REQUEST_FAILURE = "CANCEL_WITHDRAWAL_REQUEST_FAILURE";

export const FETCH_WITHDRAWL_REQUESTS_START = "FETCH_WITHDRAWL_REQUESTS_START";
export const FETCH_WITHDRAWL_REQUESTS_SUCCESS = "FETCH_WITHDRAWL_REQUESTS_SUCCESS";
export const FETCH_WITHDRAWL_REQUESTS_FAILURE = "FETCH_WITHDRAWL_REQUESTS_FAILURE";
export const FETCH_MORE_WITHDRAWAL_REQUESTS_START = "FETCH_MORE_WITHDRAWAL_REQUESTS_START";

export const VIEW_SINGLE_WITHDRAWAL_REQUEST_START = "VIEW_SINGLE_WITHDRAWAL_REQUEST_START";
export const VIEW_SINGLE_WITHDRAWAL_REQUEST_SUCCESS = "VIEW_SINGLE_WITHDRAWAL_REQUEST_SUCCESS";
export const VIEW_SINGLE_WITHDRAWAL_REQUEST_FAILURE = "VIEW_SINGLE_WITHDRAWAL_REQUEST_FAILURE";

export const VIEW_DOMAIN_START = "VIEW_DOMAIN_START";
export const VIEW_DOMAIN_SUCCESS = "VIEW_DOMAIN_SUCCESS";
export const VIEW_DOMAIN_FAILURE = "VIEW_DOMAIN_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";

export const FETCH_USER_WALLET_ADDRESS_START = "FETCH_USER_WALLET_ADDRESS_START";
export const FETCH_USER_WALLET_ADDRESS_SUCCESS = "FETCH_USER_WALLET_ADDRESS_SUCCESS";
export const FETCH_USER_WALLET_ADDRESS_FAILURE = "FETCH_USER_WALLET_ADDRESS_FAILURE";

export const FETCH_SALES_REPORT_START = "FETCH_SALES_REPORT_START";
export const FETCH_SALES_REPORT_SUCCESS = "FETCH_SALES_REPORT_SUCCESS";
export const FETCH_SALES_REPORT_FAILURE = "FETCH_SALES_REPORT_FAILURE";

export const FETCH_DOMAIN_MARKET_PLACES_START = "FETCH_DOMAIN_MARKET_PLACES_START";
export const FETCH_DOMAIN_MARKET_PLACES_SUCCESS = "FETCH_DOMAIN_MARKET_PLACES_SUCCESS";
export const FETCH_DOMAIN_MARKET_PLACES_FAILURE = "FETCH_DOMAIN_MARKET_PLACES_FAILURE";
export const FETCH_MORE_DOMAIN_MARKET_PLACES_START = "FETCH_MORE_DOMAIN_MARKET_PLACES_START";

export const SELL_DOMAIN_MARKET_PLACES_START = "SELL_DOMAIN_MARKET_PLACES_START";
export const SELL_DOMAIN_MARKET_PLACES_SUCCESS = "SELL_DOMAIN_MARKET_PLACES_SUCCESS";
export const SELL_DOMAIN_MARKET_PLACES_FAILURE = "SELL_DOMAIN_MARKET_PLACES_FAILURE";

export const BUY_DOMAIN_MARKET_PLACES_START = "BUY_DOMAIN_MARKET_PLACES_START";
export const BUY_DOMAIN_MARKET_PLACES_SUCCESS = "BUY_DOMAIN_MARKET_PLACES_SUCCESS";
export const BUY_DOMAIN_MARKET_PLACES_FAILURE = "BUY_DOMAIN_MARKET_PLACES_FAILURE";

export const DELETE_DOMAIN_MARKET_PLACES_START = "DELETE_DOMAIN_MARKET_PLACES_START";
export const DELETE_DOMAIN_MARKET_PLACES_SUCCESS = "DELETE_DOMAIN_MARKET_PLACES_SUCCESS";
export const DELETE_DOMAIN_MARKET_PLACES_FAILURE = "DELETE_DOMAIN_MARKET_PLACES_FAILURE";

export const FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START = "FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START";
export const FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_SUCCESS = "FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_SUCCESS";
export const FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_FAILURE = "FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_FAILURE";
export const FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START = "FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START";