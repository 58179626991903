import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DomainManager.css";
import { useParams } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import DomainSearchSec from "../DashBoard/DomainSearchSec";

const DomainIndex = () => {
  const params = useParams();

  return (
    <>
      <div className="domain-sec">
        <DomainSearchSec />
        <Container>
          <div className="domain-details-sec">
            <div className="domain-title text-center">
              <h2>{params.domain}/</h2>
              <p>
                <Image src={window.location.origin + "/images/circle.png"} />
                up-to-date
                {/* up-to-date as of Block #125830 */}
              </p>
            </div>
            <div className="text-center">
              <img src={window.location.origin + "/images/coming-soon.png"} />
            </div>
            {/* <div className="sell-domain-sec">
              <div className="sell-domain-switch domain-sec-title">
                <h4>Sell this domain</h4>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="This domain is for sale"
                  />
                </Form>
              </div>
              <p>List this domain in our marketplace at a price you choose.</p>
            </div>
            <div className="gift-domain-sec">
              <div className="gift-domain-card">
                <div className="gift-domain">
                  <h4>Gift the domain aravinth/</h4>
                  <p>
                    Transfer this domain to a friend instantly. Gifts will also
                    count as referrals. Learn more.
                  </p>
                </div>
                <div className="gift-domain-btn">
                  <Link to="" className="hns-btn">
                    <Image src={window.location.origin + "/images/gift.svg"} />
                    Gift this domain
                  </Link>
                </div>
              </div>
            </div>
            <div className="set-dns-sec domain-sec-title">
              <h4>Set your DNS records</h4>
              <p>
                To set A/CNAME records, you can either host your own nameserver
                in the blockchain section or use Namebase's.
              </p>
            </div>
            <div className="dns-record-sec domain-sec-title">
              <h4>DNS Records</h4>
              <p>
                Handshake allows a limited set of DNS record types on chain.
              </p>
              <div className="your-active-auction-box">
                <div className="your-active-auction-table-sec">
                  <div className="custom-table-list">
                    <div className="custom-table-header">
                      <div className="custom-table-header-card">
                        <h5>Type</h5>
                        <h5>Name</h5>
                        <h5>Value / Data</h5>
                      </div>
                    </div>
                    <div className="custom-table-body">
                      <div className="custom-table-card">
                        <p>NS</p>
                        <p>
                          <Form>
                            <Form.Group className="input-placeholder-color">
                              <Form.Control type="text" placeholder="ns1" />
                            </Form.Group>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder="44.231.6.183"
                              />
                            </Form.Group>
                          </Form>
                        </p>
                        <p>
                          <Link to="">
                            <Image
                              src={window.location.origin + "/images/edit.svg"}
                            />
                            Edit
                          </Link>
                        </p>
                      </div>
                      <div className="custom-table-card">
                        <p>NS</p>
                        <p>
                          <Form>
                            <Form.Group className="input-placeholder-color">
                              <Form.Control type="text" placeholder="ns2" />
                            </Form.Group>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder="44.231.6.183"
                              />
                            </Form.Group>
                          </Form>
                        </p>
                        <p>
                          <Link to="">
                            <Image
                              src={window.location.origin + "/images/edit.svg"}
                            />
                            Edit
                          </Link>
                        </p>
                      </div>
                      <div className="custom-table-card">
                        <p>NS</p>
                        <p>
                          <Form>
                            <Form.Group className="input-placeholder-color">
                              <Form.Control type="text" placeholder="ns3" />
                            </Form.Group>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder="44.231.6.183"
                              />
                            </Form.Group>
                          </Form>
                        </p>
                        <p>
                          <Link to="">
                            <Image
                              src={window.location.origin + "/images/edit.svg"}
                            />
                            Edit
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default DomainIndex;
