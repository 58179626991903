import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, InputGroup, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardSidebar from "../DashBoard/DashboardSidebar";
import DomainSearchSec from "../DashBoard/DomainSearchSec";
import AddNewBillingAccountModal from "./AddNewBiilingAccountModal";
import { fetchUserBillingAccountsListStart, withdrawalRequestStart } from "../store/actions/WalletAction";
import { connect } from "react-redux";
import SingleAccount from "./SingleAccount";
import { useNavigate } from "react-router-dom";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";

const WithDrawIndex = (props) => {
  const navigate = useNavigate();

  const [addNewBillingAccount, setAddNewBillingAccount] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    props.dispatch(fetchUserBillingAccountsListStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !props.addUserBillingAccounts.loading && Object.keys(props.addUserBillingAccounts.data).length > 0) {
      closeAddNewBillingAccountModal();
      props.dispatch(fetchUserBillingAccountsListStart());
    }
  }, [props.addUserBillingAccounts]);

  useEffect(() => {
    if (!skipRender && !props.userBillingAccounts.loading && Object.keys(props.userBillingAccounts.data).length > 0) {
      if (props.userBillingAccounts.data.user_billing_accounts && props.userBillingAccounts.data.user_billing_accounts.length > 0) {
        props.userBillingAccounts.data.user_billing_accounts.map((account, i) => {
          if (account.is_default === 1)
            setSelectedAccount(account.user_billing_account_id);
        })
      } else {
        setSelectedAccount(null);
      }
    }
  }, [props.userBillingAccounts]);

  const closeAddNewBillingAccountModal = () => {
    setAddNewBillingAccount(false);
  };

  const withdrawMoney = e => {
    e.preventDefault();
    if (amount && selectedAccount) {
      props.dispatch(withdrawalRequestStart({
        amount: amount,
        user_billing_account_id: selectedAccount,
      }));
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedAccount) {
      props.dispatch(getErrorNotificationMessage("Please add or select an account"));
    }
  }

  useEffect(() => {
    if (!skipRender && !props.withdrawRequest.loading && Object.keys(props.withdrawRequest.data).length > 0) {
      navigate("/wallet-withdraw");
    }
    setSkipRender(false);
  }, [props.withdrawRequest]);

  return (
    <>
      <div className="withdraw-money-sec">
        <DomainSearchSec />
        <div className="main-wrapper-box">
          <div className="mobile-sidebar-sec">
            <DashboardSidebar />
          </div>
          <div className="main-wrapper-right">
            <div className="title-sec">
              <h2>Withdraw Money</h2>
            </div>
            <div className="withdraw-money-header-sec">
              <div className="withdraw-money-form">
                <InputGroup className="mb-0">
                  <Form.Control
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">$</InputGroup.Text>
                </InputGroup>
                <div className="withdraw-balance-sec">
                  <h5><span>Balance</span> {
                    !props.userBillingAccounts.loading && props.userBillingAccounts.data.user_wallet ?
                      props.userBillingAccounts.data.user_wallet.remaining_formatted
                      : "0 USD"
                  }
                  </h5>
                  <Button className="withdraw-money-btn" type="submit" onClick={withdrawMoney}>
                    Withdraw
                  </Button>
                </div>
              </div>
            </div>
            <div className="select-card-sec">
              <h4>Choose Billing Account</h4>
              <div className="select-card-box">
                {props.userBillingAccounts.loading ?
                  <CommonCenterLoader />
                  : <>
                    {props.userBillingAccounts.data.user_billing_accounts &&
                      props.userBillingAccounts.data.user_billing_accounts.map((account, i) =>
                        <SingleAccount
                          account={account}
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                        />
                      )}
                    <div className="add-card">
                      <Link to="#" onClick={() => setAddNewBillingAccount(true)}>
                        <div className="select-item">
                          <div className="select-item-icon-sec">
                            <Image
                              className="add-icon"
                              src={
                                window.location.origin + "/images/dashboard/plus-add.svg"
                              }
                            />
                          </div>
                          <div className="select-item-info">
                            <h6>Add New Billing Account</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {addNewBillingAccount ?
        <AddNewBillingAccountModal
          addNewBillingAccount={addNewBillingAccount}
          closeAddNewBillingAccountModal={closeAddNewBillingAccountModal}
          setAddNewBillingAccount={setAddNewBillingAccount}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  userBillingAccounts: state.wallet.userBillingAccounts,
  addUserBillingAccounts: state.wallet.addUserBillingAccounts,
  withdrawRequest: state.wallet.withdrawRequest,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WithDrawIndex);
