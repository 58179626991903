import React, { useEffect } from "react";
import { connect } from "react-redux";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import {
  Image,
  Button
} from "react-bootstrap";
import NoDataFound from "../Helper/NoDataFound";
import { fetchDomainMarketPlacesForOwnerStart } from "../store/actions/DomainAction";

const SoldDomain = (props) => {

  useEffect(() => {
    props.dispatch(fetchDomainMarketPlacesForOwnerStart({
      sale_status: 1,
    }))
  }, []);

  const fetchMoreSoldDomain = () => {

  }

  return (
    <>
      <div className="your-domain-sec">
        {props.domainMarketPlacesForOwner.loading ?
          <CommonCenterLoader /> :
          props.domainMarketPlacesForOwner.data.domain_market_places &&
            props.domainMarketPlacesForOwner.data.domain_market_places.length > 0 ?
            <div className="custom-table-list">
              <div className="custom-table-header">
                <div className="custom-table-header-card">
                  <h5>Domain</h5>
                  <h5>Amount</h5>
                  <h5>Updated on</h5>
                  {/* <h5>Watchlist</h5> */}
                </div>
              </div>
              <InfiniteScroll
                dataLength={props.domainMarketPlacesForOwner.data.domain_market_places.length}
                next={fetchMoreSoldDomain}
                hasMore={props.domainMarketPlacesForOwner.data.domain_market_places.length < props.domainMarketPlacesForOwner.data.total}
                loader={<CommonCenterLoader />}
              >
                <div className="custom-table-body">
                  {props.domainMarketPlacesForOwner.data.domain_market_places.map((domain) => (
                    <div className="custom-table-card">
                      <p><Link to={`/domains/${domain.domain_name}`}>{domain.domain_name}/</Link></p>
                      <p>{domain.amount_formatted}</p>
                      <p>{domain.updated_formatted}</p>
                      {/* {domain.is_watchlisted === 0 ?
                            <Button
                              className="custom-black-btn margin-right-5"
                            >Add</Button>
                            : <Button
                              className="custom-black-btn margin-right-5">Remove</Button>
                          } */}
                    </div>
                  ))
                  }
                </div>
              </InfiniteScroll>
            </div>
            : <NoDataFound />
        }
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  domainMarketPlacesForOwner: state.domain.domainMarketPlacesForOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SoldDomain);