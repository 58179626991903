import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  CREATE_BID_START, FETCH_BIDS_LIST_START, FETCH_BIDS_RECIEPT_START, FETCH_BIDS_TRANSACTION_HISTORY_START, FETCH_MORE_BIDS_LIST_START, FETCH_MORE_BIDS_TRANSACTION_HISTORY_START, FETCH_MORE_OWNER_BIDS_LIST_START, FETCH_OWNER_BIDS_LIST_START, UPDATE_BID_STATUS_START, VIEW_DOMAIN_START
} from "../actions/ActionConstant";
import {
  createBidFailure,
  createBidSuccess,
  fetchBidsListFailure,
  fetchBidsListSuccess,
  fetchBidsRecieptFailure,
  fetchBidsRecieptSuccess,
  fetchBidsTransactionHistoryFailure,
  fetchBidsTransactionHistorySuccess,
  fetchOwnerBidsListFailure,
  fetchOwnerBidsListSuccess,
  updateBidStatusFailure,
  updateBidStatusSuccess,
  viewDomainFailure,
  viewDomainSuccess
} from "../actions/BidAction";


function* createBidAPI(action) {
  try {
    const response = yield api.postMethod("create_bid", action.data);
    if (response.data.success) {
      let domainViewData = yield select((state) => state.bid.domainView.data);
      domainViewData = {
        ...domainViewData,
        highest_bid: response.data.data.highest_bid,
        wallet_balance: response.data.data.wallet_balance,
        domain: response.data.data.domain,
      };
      yield put(createBidSuccess(response.data));
      yield put(viewDomainSuccess(domainViewData));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(createBidFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(createBidFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* viewDomainAPI(action) {
  try {
    const response = yield api.postMethod("domain_view", action.data);
    if (response.data.success) {
      yield put(viewDomainSuccess(response.data.data));
      if (response.data.data.bids)
        yield put(fetchBidsListSuccess(response.data.data))
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(viewDomainFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(viewDomainFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchBidsListAPI(action) {
  try {
    const response = yield api.postMethod("bids_list", action.data);
    if (response.data.success) {
      yield put(fetchBidsListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchBidsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchBidsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateBidStatusAPI(action) {
  try {
    const response = yield api.postMethod("bid_status_update", action.data);
    if (response.data.success) {
      yield put(updateBidStatusSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(updateBidStatusFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateBidStatusFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchBidsTransactionHistoryAPI(action) {
  try {
    const response = yield api.postMethod("bids_transaction_history", action.data);
    if (response.data.success) {
      yield put(fetchBidsTransactionHistorySuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchBidsTransactionHistoryFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchBidsTransactionHistoryFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchBidsRecieptAPI(action) {
  try {
    const response = yield api.postMethod("bids_reciept", action.data);
    if (response.data.success) {
      yield put(fetchBidsRecieptSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchBidsRecieptFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchBidsRecieptFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchOwnerBidsListAPI(action) {
  try {
    const response = yield api.postMethod("bids_list_for_owner", action.data);
    if (response.data.success) {
      yield put(fetchOwnerBidsListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchOwnerBidsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchOwnerBidsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(CREATE_BID_START, createBidAPI),
    yield takeLatest(VIEW_DOMAIN_START, viewDomainAPI),
    yield takeLatest(FETCH_BIDS_LIST_START, fetchBidsListAPI),
    yield takeLatest(FETCH_MORE_BIDS_LIST_START, fetchBidsListAPI),
    yield takeLatest(UPDATE_BID_STATUS_START, updateBidStatusAPI),
    yield takeLatest(FETCH_BIDS_TRANSACTION_HISTORY_START, fetchBidsTransactionHistoryAPI),
    yield takeLatest(FETCH_MORE_BIDS_TRANSACTION_HISTORY_START, fetchBidsTransactionHistoryAPI),
    yield takeLatest(FETCH_BIDS_RECIEPT_START, fetchBidsRecieptAPI),
    yield takeLatest(FETCH_OWNER_BIDS_LIST_START, fetchOwnerBidsListAPI),
    yield takeLatest(FETCH_MORE_OWNER_BIDS_LIST_START, fetchOwnerBidsListAPI),
  ]);
}