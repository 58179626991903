import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { apiConstants } from "../Constant/constants";
import { userLoginStart } from "../store/actions/UserAction";
import { useNavigate } from "react-router-dom";
import configuration from "react-global-configuration";
import ForgotPasswordModal from "./ForgotPasswordModal";

const LoginIndex = (props) => {

  const [forgotPassword, setForgotPassword] = useState(false);

  const closeForgotPasswordModal = () => {
    setForgotPassword(false);
  };

  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const [loginInputData, setLoginInputData] = useState({
    login_by: apiConstants.LOGIN_BY,
    device_token: apiConstants.DEVICE_TYPE,
    device_type: apiConstants.DEVICE_TOKEN,
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const login = (values) => {
    props.dispatch(userLoginStart({ ...values, ...loginInputData }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.loginInputData.loading &&
      Object.keys(props.loginInputData.data).length > 0
    ) {
      setTimeout(() => {
        if (props.loginInputData.data.is_two_step_auth_enabled == 1) {
          navigate("/verification");
        } else if (props.loginInputData.data.is_email_verified == 0) {
          navigate("/register-verification");
        } else {
          navigate("/dashboard");
        }
      }, 1000);
    }
    setSkipRender(false);
  }, [props.loginInputData]);

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <div className="auth-left-sec">
            <div className="auth-card">
              <div className="login-logo text-center mb-5">
                <Link to="/">
                  <Image src={window.location.origin + "/images/logo.png"} />
                </Link>
              </div>
              <h2>Welcome Back</h2>
              <p>Enter Your Credentials to access your account</p>
              <div className="auth-form">
                <Formik
                  initialValues={{
                    email: configuration.get("configData.demo_user_email"),
                    password: configuration.get(
                      "configData.demo_user_password"
                    ),
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => login(values)}
                >
                  {({ errors, touched }) => (
                    <FORM>
                      <Form.Group className="form-group">
                        <Form.Label>Email Address</Form.Label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter Your Email Address..."
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter Your Password"
                        />
                        {errors.password && touched.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                        <div className="forgot-link">
                          <Link to="#" onClick={() => setForgotPassword(true)}>Forgot Password ?</Link>
                        </div>
                      </Form.Group>
                      <div className="auth-btn-sec">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={props.loginInputData.buttonDisable}
                        >
                          {props.loginInputData.loadingButtonContent
                            ? props.loginInputData.loadingButtonContent
                            : "Login"}
                        </Button>
                      </div>
                      <div className="terms-agree-sec">
                        <p>
                          Create a new account{" "}
                          <Link to="/register">Click here</Link>
                        </p>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="auth-right-sec">
            <div className="auth-img-sec">
              <Image
                className="auth-img"
                src={window.location.origin + "/images/login-img.png"}
              />
            </div>
          </div>
        </div>
      </div>
      {forgotPassword ?
        <ForgotPasswordModal
          forgotPassword={forgotPassword}
          closeForgotPasswordModal={closeForgotPasswordModal}
          setForgotPassword={setForgotPassword}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  loginInputData: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);
