import {
  BUY_DOMAIN_MARKET_PLACES_FAILURE,
  BUY_DOMAIN_MARKET_PLACES_START,
  BUY_DOMAIN_MARKET_PLACES_SUCCESS,
  DELETE_DOMAIN_MARKET_PLACES_FAILURE,
  DELETE_DOMAIN_MARKET_PLACES_START,
  DELETE_DOMAIN_MARKET_PLACES_SUCCESS,
  DOMAINS_FOR_OWNER_SUCCESS,
  FETCH_DOMAINS_FOR_OWNER_FAILURE,
  FETCH_DOMAINS_FOR_OWNER_START,
  FETCH_DOMAINS_FOR_OWNER_SUCCESS,
  FETCH_DOMAIN_MARKET_PLACES_FAILURE,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_FAILURE,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
  FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_SUCCESS,
  FETCH_DOMAIN_MARKET_PLACES_START,
  FETCH_DOMAIN_MARKET_PLACES_SUCCESS,
  FETCH_MORE_DOMAINS_FOR_OWNER_START,
  FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START,
  FETCH_MORE_DOMAIN_MARKET_PLACES_START,
  FETCH_MORE_WATCHLIST_LIST_START,
  FETCH_RESET_WATCHLIST_LIST_SUCCESS,
  FETCH_WATCHLIST_LIST_FAILURE,
  FETCH_WATCHLIST_LIST_START,
  FETCH_WATCHLIST_LIST_SUCCESS,
  SELL_DOMAIN_MARKET_PLACES_FAILURE,
  SELL_DOMAIN_MARKET_PLACES_START,
  SELL_DOMAIN_MARKET_PLACES_SUCCESS,
  UPDATE_WATCHLIST_FAILURE,
  UPDATE_WATCHLIST_START,
  UPDATE_WATCHLIST_SUCCESS
} from "../actions/ActionConstant";


const initialState = {
  domainsForOwner: {
    data: {
      domains: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  watchlists: {
    data: {
      domains: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateWatchlist: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  domainMarketplaceList: {
    data: {
      domain_market_places: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  sellDomainMarketPlaces: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  buyDomainMarketPlaces: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteDomainMarketPlaces: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  domainMarketPlacesForOwner: {
    data: {
      domain_market_places: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOMAINS_FOR_OWNER_START:
      return {
        ...state,
        domainsForOwner: {
          data: {
            domains: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case FETCH_MORE_DOMAINS_FOR_OWNER_START:
      return state;
    case FETCH_DOMAINS_FOR_OWNER_SUCCESS:
      return {
        ...state,
        domainsForOwner: {
          data: {
            domains: [...state.domainsForOwner.data.domains, ...action.data.domains],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DOMAINS_FOR_OWNER_SUCCESS:
      return {
        ...state,
        domainsForOwner: {
          data: {
            domains: action.data.domains,
            total: action.data.total,
          },
          laoding: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_DOMAINS_FOR_OWNER_FAILURE:
      return {
        ...state,
        domainsForOwner: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_WATCHLIST_LIST_START:
      return {
        ...state,
        watchlists: {
          data: {
            domains: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case FETCH_MORE_WATCHLIST_LIST_START:
      return state;
    case FETCH_WATCHLIST_LIST_SUCCESS:
      return {
        ...state,
        watchlists: {
          data: {
            domains: [...state.watchlists.data.domains, ...action.data.domains],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_RESET_WATCHLIST_LIST_SUCCESS:
      return {
        ...state,
        watchlists: {
          data: {
            domains: action.data.domains,
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_WATCHLIST_LIST_FAILURE:
      return {
        ...state,
        watchlists: {
          data: state.watchlists.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_WATCHLIST_START:
      return {
        ...state,
        updateWatchlist: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case UPDATE_WATCHLIST_SUCCESS:
      return {
        ...state,
        updateWatchlist: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_WATCHLIST_FAILURE:
      return {
        ...state,
        updateWatchlist: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_DOMAIN_MARKET_PLACES_START:
      return {
        ...state,
        domainMarketplaceList: {
          data: {
            domain_market_places: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_DOMAIN_MARKET_PLACES_START:
      return state;
    case FETCH_DOMAIN_MARKET_PLACES_SUCCESS:
      return {
        ...state,
        domainMarketplaceList: {
          data: {
            domain_market_places: [...state.domainMarketplaceList.data.domain_market_places, ...action.data.domain_market_places],
            total: action.data.total_domain_market_places,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_DOMAIN_MARKET_PLACES_FAILURE:
      return {
        ...state,
        domainMarketplaceList: {
          data: state.domainMarketplaceList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };

    case SELL_DOMAIN_MARKET_PLACES_START:
      return {
        ...state,
        sellDomainMarketPlaces: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case SELL_DOMAIN_MARKET_PLACES_SUCCESS:
      return {
        ...state,
        sellDomainMarketPlaces: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SELL_DOMAIN_MARKET_PLACES_FAILURE:
      return {
        ...state,
        sellDomainMarketPlaces: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case BUY_DOMAIN_MARKET_PLACES_START:
      return {
        ...state,
        buyDomainMarketPlaces: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case BUY_DOMAIN_MARKET_PLACES_SUCCESS:
      return {
        ...state,
        buyDomainMarketPlaces: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case BUY_DOMAIN_MARKET_PLACES_FAILURE:
      return {
        ...state,
        buyDomainMarketPlaces: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_DOMAIN_MARKET_PLACES_START:
      return {
        ...state,
        deleteDomainMarketPlaces: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case DELETE_DOMAIN_MARKET_PLACES_SUCCESS:
      return {
        ...state,
        deleteDomainMarketPlaces: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_DOMAIN_MARKET_PLACES_FAILURE:
      return {
        ...state,
        deleteDomainMarketPlaces: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_START:
      return {
        ...state,
        domainMarketPlacesForOwner: {
          data: {
            domain_market_places: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_DOMAIN_MARKET_PLACES_FOR_OWNER_START:
      return state;
    case FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_SUCCESS:
      return {
        ...state,
        domainMarketPlacesForOwner: {
          data: {
            domain_market_places: [...state.domainMarketPlacesForOwner.data.domain_market_places, ...action.data.domain_market_places],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_DOMAIN_MARKET_PLACES_FOR_OWNER_FAILURE:
      return {
        ...state,
        domainMarketPlacesForOwner: {
          data: state.domainMarketPlacesForOwner.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    default:
      return state;
  }
}
export default domainReducer;